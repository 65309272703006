import React, { Component } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import "./login.css";
import { API_URL } from "../../config";
import $ from "jquery";
import Hubspot from "../../Hubspot";
import { refresh } from "aos";
var Cookies = require("cookies");

class Login extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      showpwd: false,
      rememberMe: false,  // Initialize the state for the checkbox
    };

    this.togglePwd = this.togglePwd.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  togglePwd() {
    this.setState({ showpwd: !this.state.showpwd });
  }

  handleCheckboxChange(event) {
    this.setState({ rememberMe: event.target.checked });
  }

  componentDidMount() {
    let url1 = window.location.href;
    if (url1 === "https://app.symvisionlab.com/login") {
      window.location.href = "https://app.symcarelab.com/login";
    }
    let cname = "loggedin";
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        console.log(c.substring(name.length, c.length));
        if (c.substring(name.length, c.length) === "1") {
          window.location.href = "/Dashboard";
        }
      }
    }
    if (localStorage.getItem("doc") == null) localStorage.setItem("doc", "[]");
    if (localStorage.getItem("eye") == null) localStorage.setItem("eye", "[]");
    if (localStorage.getItem("OPTICIAN") == null)
      localStorage.setItem("OPTICIAN", "[]");
    if (localStorage.getItem("PATIENT") == null)
      localStorage.setItem("PATIENT", "[]");
    if (localStorage.getItem("POINTOFSALES") == null)
      localStorage.setItem("POINTOFSALES", "[]");
    if (localStorage.getItem("BACKOFFICE") == null)
      localStorage.setItem("BACKOFFICE", "[]");
  }

  submit() {
    const content = document.createElement("div");
    const input = document.createElement("img");
    input.src = "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
    input.width = "250";
    content.appendChild(input);

    swal({
      text: window.$language.PleaseWait,
      allowOutsideClick: false,
      buttons: false,
      content,
    });

    let pwd = document.getElementById("login-pwd").value;
    let email = document.getElementById("login-email").value;

    if (email === "" || pwd === "") {
      swal({
        title: "OOPS!",
        text: window.$language.Pleaseenteryouremailandpassword,
        icon: "warning",
      });
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: pwd,
      }),
    };

    $(".loading").removeClass("d-none");

    let logourl = API_URL + "/api/token";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        $(".loading").addClass("d-none");

        if (json.response === "SUCCESS") {
          localStorage.setItem("token", "Bearer " + json.token);
          localStorage.setItem("type1", json.type);
          localStorage.setItem("type", json.type);

          if (this.state.rememberMe) {
            const d = new Date();
            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = "loggedin=1;" + expires + ";path=/";
          }

          window.location.href = "/dashboard";
        } else {
          swal({
            title: "OOPS!",
            text: window.$language.Pleaseentercorrectemailandpassword,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        $(".loading").addClass("d-none");

        swal({
          title: "OOPS!",
          text: window.$language.Pleaseentercorrectemailandpassword,
          icon: "warning",
        });

        console.log(e);
      });
  }

  render() {
    return (
      <div>
        <nav className="header-navbar navbar-expand-lg navbar navbar-fixed navbar-shadow navbar-brand-center navheight65" data-nav="brand-center">
          <div className="navber_Header8799 d-xl-block">
            <ul className="nav navbar-nav">
              <img
                className="img-fluid2"
                src="../../../app-assets/images/logo/logo.png"
                alt="Logo"
              />
              <li className="nav-item"></li>
            </ul>
          </div>
          <div className="navbar-container d-flex content"></div>
        </nav>
        <div className="app-content content">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper">
            <div className="content-body">
              <div className="auth-wrapper auth-v2">
                <div className="auth-inner row m-0">
                  <a className="brand-logo" href="javascript:void(0);"></a>
                  <div className="d-none d-lg-flex col-lg-8 align-items-center border-right bg-white">
                    <div className="w-100 d-lg-flex align-items-center justify-content-center px-0">
                      <img src="../../../app-assets/images/pages/login-v2.png" alt="Loginbg" className="imagesize_898" />
                    </div>
                  </div>
                  <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5 bg-light">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                      <div className="text-jui876">
                        <h2 className="textcolr_8765"><b>Se connecter</b></h2>
                        <p className="card-text mb-2">Utilisez les identifiants<br />communiqués à votre praticien</p>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="login-email">
                          <b>{window.$language.Email}</b>
                        </label>
                        <input
                          className="form-control"
                          id="login-email"
                          type="text"
                          name="login-email"
                          placeholder="john@example.com"
                          aria-describedby="login-email"
                          autoFocus=""
                          tabIndex="1"
                          required
                        />
                        <div className="valid-feedback">Looks good!</div>
                        <div className="invalid-feedback">Please enter a valid email</div>
                      </div>
                      <div className="form-group">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="login-password">{window.$language.Password}</label>
                        </div>
                        <div className="input-group input-group-merge form-password-toggle">
                          <input
                            className="form-control form-control-merge login-pwd"
                            id="login-pwd"
                            type={this.state.showpwd ? "text" : "password"}
                            name="login-password"
                            placeholder="············"
                            aria-describedby="login-password"
                            tabIndex="2"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text view-password cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-eye"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                onClick={this.togglePwd}
                                strokeLinejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <circle cx="12" cy="12" r="2"></circle>
                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group d-none">
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="remember-me"
                            type="checkbox"
                            checked={this.state.rememberMe}
                            onChange={this.handleCheckboxChange}
                            tabIndex="3"
                          />
                          <label className="custom-control-label" htmlFor="remember-me">
                            {window.$language.RememberMe}
                          </label>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary btn-block btn-form-block-spinner btn_color454"
                        onClick={this.submit}
                        tabIndex="4"
                      >
                        {window.$language.Signin}
                      </button>
                      <div className="loading d-none">
                        <i className="fas fa-spinner fa-spin position-absolute text-white spin-login"></i>
                      </div>
                      <p className="text-center mt-2">
                        <span>
                          <a href="/forgotpassword">{window.$language.ForgotPassword} ?&nbsp;</a>
                        </span>
                        <a href="/Registration" className="d-none">
                          <span>&nbsp;{window.$language.Createanaccount}</span>
                        </a>
                      </p>
                    </div>
                    <p className="ptext-234">contact@symvisionlab.fr</p>
                    <p className="ptext-235">
                      Sym Tech
                      <br />
                      Tous droits réservés
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
