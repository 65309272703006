export const Typedevision = [
  { value: "", label: "" },
  { value: "VL", label: "VL" },
  { value: "VP", label: "VP" },
  { value: "Progressif", label: "Progressif" },
  { value: "Degressif", label: "Degressif" },
  { value: "ZenView", label: "ZenView" },
  { value: "Bifocal", label: "Bifocal" },
];
export const Typedepanier = [
  { value: "", label: "" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "Mixte Monture A", label: "Mixte Monture A" },
  { value: "Mixte Verre A", label: "Mixte Verre A" },
  { value: "Sans Panier", label: "Sans Panier" },
];
export const TiersPayant = [
  { value: "", label: "" },
  { value: "TP", label: "TP" },
  { value: "Sans TP", label: "Sans TP" },
  { value: "Sans Mutuelle", label: "Sans Mutuelle" },
];
export const Typeofpayment = [
  { value: "", label: "" },
  { value: "CB", label: "CB" },
  { value: "Cheque", label: "Cheque" },
  { value: "ESP", label: "ESP" },
  { value: "Envoi Sum up", label: "Envoi Sum up" },
];
/////////////////////////////Colour////////////////////////
export const colorA1 = [
  { value: "", label: "" },
  { value: "Teinte Unie Grey 85% - 0.00 €", label: "Teinte Unie Grey 85% - N/A", isDisabled: true},
  { value: "Teinte Unie Grey 75% - 0.00 €", label: "Teinte Unie Grey 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Grey 50% - 0.00 €", label: "Teinte Unie Grey 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Grey 25% - 0.00 €", label: "Teinte Unie Grey 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Grey 10% - 0.00 €", label: "Teinte Unie Grey 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Brown 85% - 0.00 €", label: "Teinte Unie Brown 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Brown 75% - 0.00 €", label: "Teinte Unie Brown 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Brown 50% - 0.00 €", label: "Teinte Unie Brown 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Brown 25% - 0.00 €", label: "Teinte Unie Brown 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Brown 10% - 0.00 €", label: "Teinte Unie Brown 10% - N/A", isDisabled: true },
  { value: "Teinte Unie G15 85% - 0.00 €", label: "Teinte Unie G15 85% - N/A", isDisabled: true },
  { value: "Teinte Unie G15 75% - 0.00 €", label: "Teinte Unie G15 75% - N/A", isDisabled: true },
  { value: "Teinte Unie G15 50% - 0.00 €", label: "Teinte Unie G15 50% - N/A", isDisabled: true },
  { value: "Teinte Unie G15 25% - 0.00 €", label: "Teinte Unie G15 25% - N/A", isDisabled: true },
  { value: "Teinte Unie G15 10% - 0.00 €", label: "Teinte Unie G15 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Blue 85% - 0.00 €", label: "Teinte Unie Blue 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Blue 75% - 0.00 €", label: "Teinte Unie Blue 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Blue 50% - 0.00 €", label: "Teinte Unie Blue 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Blue 25% - 0.00 €", label: "Teinte Unie Blue 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Blue 10% - 0.00 €", label: "Teinte Unie Blue 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Yellow 85% - 0.00 €", label: "Teinte Unie Yellow 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Yellow 75% - 0.00 €", label: "Teinte Unie Yellow 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Yellow 50% - 0.00 €", label: "Teinte Unie Yellow 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Yellow 25% - 0.00 €", label: "Teinte Unie Yellow 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Yellow 10% - 0.00 €", label: "Teinte Unie Yellow 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Pink 85% - 0.00 €", label: "Teinte Unie Pink 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Pink 75% - 0.00 €", label: "Teinte Unie Pink 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Pink 50% - 0.00 €", label: "Teinte Unie Pink 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Pink 25% - 0.00 €", label: "Teinte Unie Pink 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Pink 10% - 0.00 €", label: "Teinte Unie Pink 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Purple 85% - 0.00 €", label: "Teinte Unie Purple 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Purple 75% - 0.00 €", label: "Teinte Unie Purple 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Purple 50% - 0.00 €", label: "Teinte Unie Purple 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Purple 25% - 0.00 €", label: "Teinte Unie Purple 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Purple 10% - 0.00 €", label: "Teinte Unie Purple 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Black 85% - 0.00 €", label: "Teinte Unie Black 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Black 75% - 0.00 €", label: "Teinte Unie Black 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Black 50% - 0.00 €", label: "Teinte Unie Black 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Black 25% - 0.00 €", label: "Teinte Unie Black 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Black 10% - 0.00 €", label: "Teinte Unie Black 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Amber 85% - 0.00 €", label: "Teinte Unie Amber 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Amber 75% - 0.00 €", label: "Teinte Unie Amber 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Amber 50% - 0.00 €", label: "Teinte Unie Amber 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Amber 25% - 0.00 €", label: "Teinte Unie Amber 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Amber 10% - 0.00 €", label: "Teinte Unie Amber 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Rose 85% - 0.00 €", label: "Teinte Unie Rose 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Rose 75% - 0.00 €", label: "Teinte Unie Rose 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Rose 50% - 0.00 €", label: "Teinte Unie Rose 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Rose 25% - 0.00 €", label: "Teinte Unie Rose 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Rose 10% - 0.00 €", label: "Teinte Unie Rose 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Champagne 85% - 0.00 €", label: "Teinte Unie Champagne 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Champagne 75% - 0.00 €", label: "Teinte Unie Champagne 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Champagne 50% - 0.00 €", label: "Teinte Unie Champagne 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Champagne 25% - 0.00 €", label: "Teinte Unie Champagne 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Champagne 10% - 0.00 €", label: "Teinte Unie Champagne 10% - N/A", isDisabled: true },
  { value: "Teinte Unie Orange 85% - 0.00 €", label: "Teinte Unie Orange 85% - N/A", isDisabled: true },
  { value: "Teinte Unie Orange 75% - 0.00 €", label: "Teinte Unie Orange 75% - N/A", isDisabled: true },
  { value: "Teinte Unie Orange 50% - 0.00 €", label: "Teinte Unie Orange 50% - N/A", isDisabled: true },
  { value: "Teinte Unie Orange 25% - 0.00 €", label: "Teinte Unie Orange 25% - N/A", isDisabled: true },
  { value: "Teinte Unie Orange 10% - 0.00 €", label: "Teinte Unie Orange 10% - N/A", isDisabled: true },
  { value: "Teinte Deg Grey 85%-0% - 0.00 €", label: "Teinte Deg Grey 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Grey 50%-0% - 0.00 €", label: "Teinte Deg Grey 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Grey 25%-0% - 0.00 €", label: "Teinte Deg Grey 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Grey 85%-25% - 0.00 €", label: "Teinte Deg Grey 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Grey 50%-25% - 0.00 €", label: "Teinte Deg Grey 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Grey 85%-50% - 0.00 €", label: "Teinte Deg Grey 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Brown 85%-0% - 0.00 €", label: "Teinte Deg Brown 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Brown 50%-0% - 0.00 €", label: "Teinte Deg Brown 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Brown 25%-0% - 0.00 €", label: "Teinte Deg Brown 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Brown 85%-25% - 0.00 €", label: "Teinte Deg Brown 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Brown 50%-25% - 0.00 €", label: "Teinte Deg Brown 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Brown 85%-50% - 0.00 €", label: "Teinte Deg Brown 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg G15 85%-0% - 0.00 €", label: "Teinte Deg G15 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg G15 50%-0% - 0.00 €", label: "Teinte Deg G15 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg G15 25%-0% - 0.00 €", label: "Teinte Deg G15 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg G15 85%-25% - 0.00 €", label: "Teinte Deg G15 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg G15 50%-25% - 0.00 €", label: "Teinte Deg G15 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg G15 85%-50% - 0.00 €", label: "Teinte Deg G15 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Blue 85%-0% - 0.00 €", label: "Teinte Deg Blue 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Blue 50%-0% - 0.00 €", label: "Teinte Deg Blue 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Blue 25%-0% - 0.00 €", label: "Teinte Deg Blue 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Blue 85%-25% - 0.00 €", label: "Teinte Deg Blue 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Blue 50%-25% - 0.00 €", label: "Teinte Deg Blue 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Blue 85%-50% - 0.00 €", label: "Teinte Deg Blue 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Yellow 85%-0% - 0.00 €", label: "Teinte Deg Yellow 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Yellow 50%-0% - 0.00 €", label: "Teinte Deg Yellow 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Yellow 25%-0% - 0.00 €", label: "Teinte Deg Yellow 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Yellow 85%-25% - 0.00 €", label: "Teinte Deg Yellow 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Yellow 50%-25% - 0.00 €", label: "Teinte Deg Yellow 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Yellow 85%-50% - 0.00 €", label: "Teinte Deg Yellow 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Pink 85%-0% - 0.00 €", label: "Teinte Deg Pink 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Pink 50%-0% - 0.00 €", label: "Teinte Deg Pink 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Pink 25%-0% - 0.00 €", label: "Teinte Deg Pink 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Pink 85%-25% - 0.00 €", label: "Teinte Deg Pink 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Pink 50%-25% - 0.00 €", label: "Teinte Deg Pink 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Pink 85%-50% - 0.00 €", label: "Teinte Deg Pink 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Purple 85%-0% - 0.00 €", label: "Teinte Deg Purple 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Purple 50%-0% - 0.00 €", label: "Teinte Deg Purple 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Purple 25%-0% - 0.00 €", label: "Teinte Deg Purple 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Purple 85%-25% - 0.00 €", label: "Teinte Deg Purple 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Purple 50%-25% - 0.00 €", label: "Teinte Deg Purple 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Purple 85%-50% - 0.00 €", label: "Teinte Deg Purple 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Black 85%-0% - 0.00 €", label: "Teinte Deg Black 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Black 50%-0% - 0.00 €", label: "Teinte Deg Black 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Black 25%-0% - 0.00 €", label: "Teinte Deg Black 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Black 85%-25% - 0.00 €", label: "Teinte Deg Black 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Black 50%-25% - 0.00 €", label: "Teinte Deg Black 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Black 85%-50% - 0.00 €", label: "Teinte Deg Black 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Amber 85%-0% - 0.00 €", label: "Teinte Deg Amber 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Amber 50%-0% - 0.00 €", label: "Teinte Deg Amber 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Amber 25%-0% - 0.00 €", label: "Teinte Deg Amber 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Amber 85%-25% - 0.00 €", label: "Teinte Deg Amber 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Amber 50%-25% - 0.00 €", label: "Teinte Deg Amber 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Amber 85%-50% - 0.00 €", label: "Teinte Deg Amber 85%-50% - N/A", isDisabled: true },
  { value: "Teinte Deg Rose 85%-0% - 0.00 €", label: "Teinte Deg Rose 85%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Rose 50%-0% - 0.00 €", label: "Teinte Deg Rose 50%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Rose 25%-0% - 0.00 €", label: "Teinte Deg Rose 25%-0% - N/A", isDisabled: true },
  { value: "Teinte Deg Rose 85%-25% - 0.00 €", label: "Teinte Deg Rose 85%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Rose 50%-25% - 0.00 €", label: "Teinte Deg Rose 50%-25% - N/A", isDisabled: true },
  { value: "Teinte Deg Rose 85%-50% - 0.00 €", label: "Teinte Deg Rose 85%-50% - N/A", isDisabled: true },
  {
    value: "Teinte Deg Champagne 85%-0% - 0.00 €",
    label: "Teinte Deg Champagne 85%-0% - N/A", isDisabled: true },
  {
    value: "Teinte Deg Champagne 50%-0% - 0.00 €",
    label: "Teinte Deg Champagne 50%-0% - N/A",isDisabled: true
},
  {
    value: "Teinte Deg Champagne 25%-0% - 0.00 €",
    label: "Teinte Deg Champagne 25%-0% - N/A",isDisabled: true
},
  {
    value: "Teinte Deg Champagne 85%-25% - 0.00 €",
    label: "Teinte Deg Champagne 85%-25% - N/A",isDisabled: true
},
  {
    value: "Teinte Deg Champagne 50%-25% - 0.00 €",
    label: "Teinte Deg Champagne 50%-25% - N/A",isDisabled: true
 },
  {value: "Teinte Deg Champagne 85%-50% - 0.00 €", label: "Teinte Deg Champagne 85%-50% - N/A",isDisabled: true},
  { value: "Teinte Deg Orange 85%-0% - 0.00 €", label: "Teinte Deg Orange 85%-0% - N/A",isDisabled: true },
  { value: "Teinte Deg Orange 50%-0% - 0.00 €", label: "Teinte Deg Orange 50%-0% - N/A",isDisabled: true },
  { value: "Teinte Deg Orange 25%-0% - 0.00 €", label: "Teinte Deg Orange 25%-0% - N/A",isDisabled: true },
  { value: "Teinte Deg Orange 85%-25% - 0.00 €", label: "Teinte Deg Orange 85%-25% - N/A",isDisabled: true },
  { value: "Teinte Deg Orange 50%-25% - 0.00 €", label: "Teinte Deg Orange 50%-25% - N/A",isDisabled: true },
  { value: "Teinte Deg Orange 85%-50% - 0.00 €", label: "Teinte Deg Orange 85%-50% - N/A",isDisabled: true },
];
export const colorA2 = [
  { value: "", label: "" },
  { value: "Teinte Unie Grey 85% - 15.00 €", label: "Teinte Unie Grey 85% - 15.00 €" },
  { value: "Teinte Unie Grey 75% - 15.00 €", label: "Teinte Unie Grey 75% - 15.00 €" },
  { value: "Teinte Unie Grey 50% - 15.00 €", label: "Teinte Unie Grey 50% - 15.00 €" },
  { value: "Teinte Unie Grey 25% - 15.00 €", label: "Teinte Unie Grey 25% - 15.00 €" },
  { value: "Teinte Unie Grey 10% - 15.00 €", label: "Teinte Unie Grey 10% - 15.00 €" },
  { value: "Teinte Unie Brown 85% - 15.00 €", label: "Teinte Unie Brown 85% - 15.00 €" },
  { value: "Teinte Unie Brown 75% - 15.00 €", label: "Teinte Unie Brown 75% - 15.00 €" },
  { value: "Teinte Unie Brown 50% - 15.00 €", label: "Teinte Unie Brown 50% - 15.00 €" },
  { value: "Teinte Unie Brown 25% - 15.00 €", label: "Teinte Unie Brown 25% - 15.00 €" },
  { value: "Teinte Unie Brown 10% - 15.00 €", label: "Teinte Unie Brown 10% - 15.00 €" },
  { value: "Teinte Unie G15 85% - 15.00 €", label: "Teinte Unie G15 85% - 15.00 €" },
  { value: "Teinte Unie G15 75% - 15.00 €", label: "Teinte Unie G15 75% - 15.00 €" },
  { value: "Teinte Unie G15 50% - 15.00 €", label: "Teinte Unie G15 50% - 15.00 €" },
  { value: "Teinte Unie G15 25% - 15.00 €", label: "Teinte Unie G15 25% - 15.00 €" },
  { value: "Teinte Unie G15 10% - 15.00 €", label: "Teinte Unie G15 10% - 15.00 €" },
  { value: "Teinte Unie Blue 85% - 15.00 €", label: "Teinte Unie Blue 85% - 15.00 €" },
  { value: "Teinte Unie Blue 75% - 15.00 €", label: "Teinte Unie Blue 75% - 15.00 €" },
  { value: "Teinte Unie Blue 50% - 15.00 €", label: "Teinte Unie Blue 50% - 15.00 €" },
  { value: "Teinte Unie Blue 25% - 15.00 €", label: "Teinte Unie Blue 25% - 15.00 €" },
  { value: "Teinte Unie Blue 10% - 15.00 €", label: "Teinte Unie Blue 10% - 15.00 €" },
  { value: "Teinte Unie Yellow 85% - 15.00 €", label: "Teinte Unie Yellow 85% - 15.00 €" },
  { value: "Teinte Unie Yellow 75% - 15.00 €", label: "Teinte Unie Yellow 75% - 15.00 €" },
  { value: "Teinte Unie Yellow 50% - 15.00 €", label: "Teinte Unie Yellow 50% - 15.00 €" },
  { value: "Teinte Unie Yellow 25% - 15.00 €", label: "Teinte Unie Yellow 25% - 15.00 €" },
  { value: "Teinte Unie Yellow 10% - 15.00 €", label: "Teinte Unie Yellow 10% - 15.00 €" },
  { value: "Teinte Unie Pink 85% - 15.00 €", label: "Teinte Unie Pink 85% - 15.00 €" },
  { value: "Teinte Unie Pink 75% - 15.00 €", label: "Teinte Unie Pink 75% - 15.00 €" },
  { value: "Teinte Unie Pink 50% - 15.00 €", label: "Teinte Unie Pink 50% - 15.00 €" },
  { value: "Teinte Unie Pink 25% - 15.00 €", label: "Teinte Unie Pink 25% - 15.00 €" },
  { value: "Teinte Unie Pink 10% - 15.00 €", label: "Teinte Unie Pink 10% - 15.00 €" },
  { value: "Teinte Unie Purple 85% - 15.00 €", label: "Teinte Unie Purple 85% - 15.00 €" },
  { value: "Teinte Unie Purple 75% - 15.00 €", label: "Teinte Unie Purple 75% - 15.00 €" },
  { value: "Teinte Unie Purple 50% - 15.00 €", label: "Teinte Unie Purple 50% - 15.00 €" },
  { value: "Teinte Unie Purple 25% - 15.00 €", label: "Teinte Unie Purple 25% - 15.00 €" },
  { value: "Teinte Unie Purple 10% - 15.00 €", label: "Teinte Unie Purple 10% - 15.00 €" },
  { value: "Teinte Unie Black 85% - 15.00 €", label: "Teinte Unie Black 85% - 15.00 €" },
  { value: "Teinte Unie Black 75% - 15.00 €", label: "Teinte Unie Black 75% - 15.00 €" },
  { value: "Teinte Unie Black 50% - 15.00 €", label: "Teinte Unie Black 50% - 15.00 €" },
  { value: "Teinte Unie Black 25% - 15.00 €", label: "Teinte Unie Black 25% - 15.00 €" },
  { value: "Teinte Unie Black 10% - 15.00 €", label: "Teinte Unie Black 10% - 15.00 €" },
  { value: "Teinte Unie Amber 85% - 15.00 €", label: "Teinte Unie Amber 85% - 15.00 €" },
  { value: "Teinte Unie Amber 75% - 15.00 €", label: "Teinte Unie Amber 75% - 15.00 €" },
  { value: "Teinte Unie Amber 50% - 15.00 €", label: "Teinte Unie Amber 50% - 15.00 €" },
  { value: "Teinte Unie Amber 25% - 15.00 €", label: "Teinte Unie Amber 25% - 15.00 €" },
  { value: "Teinte Unie Amber 10% - 15.00 €", label: "Teinte Unie Amber 10% - 15.00 €" },
  { value: "Teinte Unie Rose 85% - 15.00 €", label: "Teinte Unie Rose 85% - 15.00 €" },
  { value: "Teinte Unie Rose 75% - 15.00 €", label: "Teinte Unie Rose 75% - 15.00 €" },
  { value: "Teinte Unie Rose 50% - 15.00 €", label: "Teinte Unie Rose 50% - 15.00 €" },
  { value: "Teinte Unie Rose 25% - 15.00 €", label: "Teinte Unie Rose 25% - 15.00 €" },
  { value: "Teinte Unie Rose 10% - 15.00 €", label: "Teinte Unie Rose 10% - 15.00 €" },
  { value: "Teinte Unie Champagne 85% - 15.00 €", label: "Teinte Unie Champagne 85% - 15.00 €" },
  { value: "Teinte Unie Champagne 75% - 15.00 €", label: "Teinte Unie Champagne 75% - 15.00 €" },
  { value: "Teinte Unie Champagne 50% - 15.00 €", label: "Teinte Unie Champagne 50% - 15.00 €" },
  { value: "Teinte Unie Champagne 25% - 15.00 €", label: "Teinte Unie Champagne 25% - 15.00 €" },
  { value: "Teinte Unie Champagne 10% - 15.00 €", label: "Teinte Unie Champagne 10% - 15.00 €" },
  { value: "Teinte Unie Orange 85% - 15.00 €", label: "Teinte Unie Orange 85% - 15.00 €" },
  { value: "Teinte Unie Orange 75% - 15.00 €", label: "Teinte Unie Orange 75% - 15.00 €" },
  { value: "Teinte Unie Orange 50% - 15.00 €", label: "Teinte Unie Orange 50% - 15.00 €" },
  { value: "Teinte Unie Orange 25% - 15.00 €", label: "Teinte Unie Orange 25% - 15.00 €" },
  { value: "Teinte Unie Orange 10% - 15.00 €", label: "Teinte Unie Orange 10% - 15.00 €" },
  { value: "Teinte Deg Grey 85%-0% - 50.00 €", label: "Teinte Deg Grey 85%-0% - 50.00 €" },
  { value: "Teinte Deg Grey 50%-0% - 50.00 €", label: "Teinte Deg Grey 50%-0% - 50.00 €" },
  { value: "Teinte Deg Grey 25%-0% - 50.00 €", label: "Teinte Deg Grey 25%-0% - 50.00 €" },
  { value: "Teinte Deg Grey 85%-25% - 50.00 €", label: "Teinte Deg Grey 85%-25% - 50.00 €" },
  { value: "Teinte Deg Grey 50%-25% - 50.00 €", label: "Teinte Deg Grey 50%-25% - 50.00 €" },
  { value: "Teinte Deg Grey 85%-50% - 50.00 €", label: "Teinte Deg Grey 85%-50% - 50.00 €" },
  { value: "Teinte Deg Brown 85%-0% - 50.00 €", label: "Teinte Deg Brown 85%-0% - 50.00 €" },
  { value: "Teinte Deg Brown 50%-0% - 50.00 €", label: "Teinte Deg Brown 50%-0% - 50.00 €" },
  { value: "Teinte Deg Brown 25%-0% - 50.00 €", label: "Teinte Deg Brown 25%-0% - 50.00 €" },
  { value: "Teinte Deg Brown 85%-25% - 50.00 €", label: "Teinte Deg Brown 85%-25% - 50.00 €" },
  { value: "Teinte Deg Brown 50%-25% - 50.00 €", label: "Teinte Deg Brown 50%-25% - 50.00 €" },
  { value: "Teinte Deg Brown 85%-50% - 50.00 €", label: "Teinte Deg Brown 85%-50% - 50.00 €" },
  { value: "Teinte Deg G15 85%-0% - 50.00 €", label: "Teinte Deg G15 85%-0% - 50.00 €" },
  { value: "Teinte Deg G15 50%-0% - 50.00 €", label: "Teinte Deg G15 50%-0% - 50.00 €" },
  { value: "Teinte Deg G15 25%-0% - 50.00 €", label: "Teinte Deg G15 25%-0% - 50.00 €" },
  { value: "Teinte Deg G15 85%-25% - 50.00 €", label: "Teinte Deg G15 85%-25% - 50.00 €" },
  { value: "Teinte Deg G15 50%-25% - 50.00 €", label: "Teinte Deg G15 50%-25% - 50.00 €" },
  { value: "Teinte Deg G15 85%-50% - 50.00 €", label: "Teinte Deg G15 85%-50% - 50.00 €" },
  { value: "Teinte Deg Blue 85%-0% - 50.00 €", label: "Teinte Deg Blue 85%-0% - 50.00 €" },
  { value: "Teinte Deg Blue 50%-0% - 50.00 €", label: "Teinte Deg Blue 50%-0% - 50.00 €" },
  { value: "Teinte Deg Blue 25%-0% - 50.00 €", label: "Teinte Deg Blue 25%-0% - 50.00 €" },
  { value: "Teinte Deg Blue 85%-25% - 50.00 €", label: "Teinte Deg Blue 85%-25% - 50.00 €" },
  { value: "Teinte Deg Blue 50%-25% - 50.00 €", label: "Teinte Deg Blue 50%-25% - 50.00 €" },
  { value: "Teinte Deg Blue 85%-50% - 50.00 €", label: "Teinte Deg Blue 85%-50% - 50.00 €" },
  { value: "Teinte Deg Yellow 85%-0% - 50.00 €", label: "Teinte Deg Yellow 85%-0% - 50.00 €" },
  { value: "Teinte Deg Yellow 50%-0% - 50.00 €", label: "Teinte Deg Yellow 50%-0% - 50.00 €" },
  { value: "Teinte Deg Yellow 25%-0% - 50.00 €", label: "Teinte Deg Yellow 25%-0% - 50.00 €" },
  { value: "Teinte Deg Yellow 85%-25% - 50.00 €", label: "Teinte Deg Yellow 85%-25% - 50.00 €" },
  { value: "Teinte Deg Yellow 50%-25% - 50.00 €", label: "Teinte Deg Yellow 50%-25% - 50.00 €" },
  { value: "Teinte Deg Yellow 85%-50% - 50.00 €", label: "Teinte Deg Yellow 85%-50% - 50.00 €" },
  { value: "Teinte Deg Pink 85%-0% - 50.00 €", label: "Teinte Deg Pink 85%-0% - 50.00 €" },
  { value: "Teinte Deg Pink 50%-0% - 50.00 €", label: "Teinte Deg Pink 50%-0% - 50.00 €" },
  { value: "Teinte Deg Pink 25%-0% - 50.00 €", label: "Teinte Deg Pink 25%-0% - 50.00 €" },
  { value: "Teinte Deg Pink 85%-25% - 50.00 €", label: "Teinte Deg Pink 85%-25% - 50.00 €" },
  { value: "Teinte Deg Pink 50%-25% - 50.00 €", label: "Teinte Deg Pink 50%-25% - 50.00 €" },
  { value: "Teinte Deg Pink 85%-50% - 50.00 €", label: "Teinte Deg Pink 85%-50% - 50.00 €" },
  { value: "Teinte Deg Purple 85%-0% - 50.00 €", label: "Teinte Deg Purple 85%-0% - 50.00 €" },
  { value: "Teinte Deg Purple 50%-0% - 50.00 €", label: "Teinte Deg Purple 50%-0% - 50.00 €" },
  { value: "Teinte Deg Purple 25%-0% - 50.00 €", label: "Teinte Deg Purple 25%-0% - 50.00 €" },
  { value: "Teinte Deg Purple 85%-25% - 50.00 €", label: "Teinte Deg Purple 85%-25% - 50.00 €" },
  { value: "Teinte Deg Purple 50%-25% - 50.00 €", label: "Teinte Deg Purple 50%-25% - 50.00 €" },
  { value: "Teinte Deg Purple 85%-50% - 50.00 €", label: "Teinte Deg Purple 85%-50% - 50.00 €" },
  { value: "Teinte Deg Black 85%-0% - 50.00 €", label: "Teinte Deg Black 85%-0% - 50.00 €" },
  { value: "Teinte Deg Black 50%-0% - 50.00 €", label: "Teinte Deg Black 50%-0% - 50.00 €" },
  { value: "Teinte Deg Black 25%-0% - 50.00 €", label: "Teinte Deg Black 25%-0% - 50.00 €" },
  { value: "Teinte Deg Black 85%-25% - 50.00 €", label: "Teinte Deg Black 85%-25% - 50.00 €" },
  { value: "Teinte Deg Black 50%-25% - 50.00 €", label: "Teinte Deg Black 50%-25% - 50.00 €" },
  { value: "Teinte Deg Black 85%-50% - 50.00 €", label: "Teinte Deg Black 85%-50% - 50.00 €" },
  { value: "Teinte Deg Amber 85%-0% - 50.00 €", label: "Teinte Deg Amber 85%-0% - 50.00 €" },
  { value: "Teinte Deg Amber 50%-0% - 50.00 €", label: "Teinte Deg Amber 50%-0% - 50.00 €" },
  { value: "Teinte Deg Amber 25%-0% - 50.00 €", label: "Teinte Deg Amber 25%-0% - 50.00 €" },
  { value: "Teinte Deg Amber 85%-25% - 50.00 €", label: "Teinte Deg Amber 85%-25% - 50.00 €" },
  { value: "Teinte Deg Amber 50%-25% - 50.00 €", label: "Teinte Deg Amber 50%-25% - 50.00 €" },
  { value: "Teinte Deg Amber 85%-50% - 50.00 €", label: "Teinte Deg Amber 85%-50% - 50.00 €" },
  { value: "Teinte Deg Rose 85%-0% - 50.00 €", label: "Teinte Deg Rose 85%-0% - 50.00 €" },
  { value: "Teinte Deg Rose 50%-0% - 50.00 €", label: "Teinte Deg Rose 50%-0% - 50.00 €" },
  { value: "Teinte Deg Rose 25%-0% - 50.00 €", label: "Teinte Deg Rose 25%-0% - 50.00 €" },
  { value: "Teinte Deg Rose 85%-25% - 50.00 €", label: "Teinte Deg Rose 85%-25% - 50.00 €" },
  { value: "Teinte Deg Rose 50%-25% - 50.00 €", label: "Teinte Deg Rose 50%-25% - 50.00 €" },
  { value: "Teinte Deg Rose 85%-50% - 50.00 €", label: "Teinte Deg Rose 85%-50% - 50.00 €" },
  {value: "Teinte Deg Champagne 85%-0% - 50.00 €", label: "Teinte Deg Champagne 85%-0% - 50.00 €"},
  {value: "Teinte Deg Champagne 50%-0% - 50.00 €", label: "Teinte Deg Champagne 50%-0% - 50.00 €"},
  {value: "Teinte Deg Champagne 25%-0% - 50.00 €", label: "Teinte Deg Champagne 25%-0% - 50.00 €"},
  {value: "Teinte Deg Champagne 85%-25% - 50.00 €", label: "Teinte Deg Champagne 85%-25% - 50.00 €"},
  {value: "Teinte Deg Champagne 50%-25% - 50.00 €", label: "Teinte Deg Champagne 50%-25% - 50.00 €"},
  {value: "Teinte Deg Champagne 85%-50% - 50.00 €", label: "Teinte Deg Champagne 85%-50% - 50.00 €"},
  { value: "Teinte Deg Orange 85%-0% - 50.00 €", label: "Teinte Deg Orange 85%-0% - 50.00 €" },
  { value: "Teinte Deg Orange 50%-0% - 50.00 €", label: "Teinte Deg Orange 50%-0% - 50.00 €" },
  { value: "Teinte Deg Orange 25%-0% - 50.00 €", label: "Teinte Deg Orange 25%-0% - 50.00 €" },
  { value: "Teinte Deg Orange 85%-25% - 50.00 €", label: "Teinte Deg Orange 85%-25% - 50.00 €" },
  { value: "Teinte Deg Orange 50%-25% - 50.00 €", label: "Teinte Deg Orange 50%-25% - 50.00 €" },
  { value: "Teinte Deg Orange 85%-50% - 50.00 €", label: "Teinte Deg Orange 85%-50% - 50.00 €" },
];
export const colorA34 = [
  { value: "", label: "" },
  { value: "Teinte Unie Grey 85% - 30.00 €", label: "Teinte Unie Grey 85% - 30.00 €" },
  { value: "Teinte Unie Grey 75% - 30.00 €", label: "Teinte Unie Grey 75% - 30.00 €" },
  { value: "Teinte Unie Grey 50% - 30.00 €", label: "Teinte Unie Grey 50% - 30.00 €" },
  { value: "Teinte Unie Grey 25% - 30.00 €", label: "Teinte Unie Grey 25% - 30.00 €" },
  { value: "Teinte Unie Grey 10% - 30.00 €", label: "Teinte Unie Grey 10% - 30.00 €" },
  { value: "Teinte Unie Brown 85% - 30.00 €", label: "Teinte Unie Brown 85% - 30.00 €" },
  { value: "Teinte Unie Brown 75% - 30.00 €", label: "Teinte Unie Brown 75% - 30.00 €" },
  { value: "Teinte Unie Brown 50% - 30.00 €", label: "Teinte Unie Brown 50% - 30.00 €" },
  { value: "Teinte Unie Brown 25% - 30.00 €", label: "Teinte Unie Brown 25% - 30.00 €" },
  { value: "Teinte Unie Brown 10% - 30.00 €", label: "Teinte Unie Brown 10% - 30.00 €" },
  { value: "Teinte Unie G15 85% - 30.00 €", label: "Teinte Unie G15 85% - 30.00 €" },
  { value: "Teinte Unie G15 75% - 30.00 €", label: "Teinte Unie G15 75% - 30.00 €" },
  { value: "Teinte Unie G15 50% - 30.00 €", label: "Teinte Unie G15 50% - 30.00 €" },
  { value: "Teinte Unie G15 25% - 30.00 €", label: "Teinte Unie G15 25% - 30.00 €" },
  { value: "Teinte Unie G15 10% - 30.00 €", label: "Teinte Unie G15 10% - 30.00 €" },
  { value: "Teinte Unie Blue 85% - 30.00 €", label: "Teinte Unie Blue 85% - 30.00 €" },
  { value: "Teinte Unie Blue 75% - 30.00 €", label: "Teinte Unie Blue 75% - 30.00 €" },
  { value: "Teinte Unie Blue 50% - 30.00 €", label: "Teinte Unie Blue 50% - 30.00 €" },
  { value: "Teinte Unie Blue 25% - 30.00 €", label: "Teinte Unie Blue 25% - 30.00 €" },
  { value: "Teinte Unie Blue 10% - 30.00 €", label: "Teinte Unie Blue 10% - 30.00 €" },
  { value: "Teinte Unie Yellow 85% - 30.00 €", label: "Teinte Unie Yellow 85% - 30.00 €" },
  { value: "Teinte Unie Yellow 75% - 30.00 €", label: "Teinte Unie Yellow 75% - 30.00 €" },
  { value: "Teinte Unie Yellow 50% - 30.00 €", label: "Teinte Unie Yellow 50% - 30.00 €" },
  { value: "Teinte Unie Yellow 25% - 30.00 €", label: "Teinte Unie Yellow 25% - 30.00 €" },
  { value: "Teinte Unie Yellow 10% - 30.00 €", label: "Teinte Unie Yellow 10% - 30.00 €" },
  { value: "Teinte Unie Pink 85% - 30.00 €", label: "Teinte Unie Pink 85% - 30.00 €" },
  { value: "Teinte Unie Pink 75% - 30.00 €", label: "Teinte Unie Pink 75% - 30.00 €" },
  { value: "Teinte Unie Pink 50% - 30.00 €", label: "Teinte Unie Pink 50% - 30.00 €" },
  { value: "Teinte Unie Pink 25% - 30.00 €", label: "Teinte Unie Pink 25% - 30.00 €" },
  { value: "Teinte Unie Pink 10% - 30.00 €", label: "Teinte Unie Pink 10% - 30.00 €" },
  { value: "Teinte Unie Purple 85% - 30.00 €", label: "Teinte Unie Purple 85% - 30.00 €" },
  { value: "Teinte Unie Purple 75% - 30.00 €", label: "Teinte Unie Purple 75% - 30.00 €" },
  { value: "Teinte Unie Purple 50% - 30.00 €", label: "Teinte Unie Purple 50% - 30.00 €" },
  { value: "Teinte Unie Purple 25% - 30.00 €", label: "Teinte Unie Purple 25% - 30.00 €" },
  { value: "Teinte Unie Purple 10% - 30.00 €", label: "Teinte Unie Purple 10% - 30.00 €" },
  { value: "Teinte Unie Black 85% - 30.00 €", label: "Teinte Unie Black 85% - 30.00 €" },
  { value: "Teinte Unie Black 75% - 30.00 €", label: "Teinte Unie Black 75% - 30.00 €" },
  { value: "Teinte Unie Black 50% - 30.00 €", label: "Teinte Unie Black 50% - 30.00 €" },
  { value: "Teinte Unie Black 25% - 30.00 €", label: "Teinte Unie Black 25% - 30.00 €" },
  { value: "Teinte Unie Black 10% - 30.00 €", label: "Teinte Unie Black 10% - 30.00 €" },
  { value: "Teinte Unie Amber 85% - 30.00 €", label: "Teinte Unie Amber 85% - 30.00 €" },
  { value: "Teinte Unie Amber 75% - 30.00 €", label: "Teinte Unie Amber 75% - 30.00 €" },
  { value: "Teinte Unie Amber 50% - 30.00 €", label: "Teinte Unie Amber 50% - 30.00 €" },
  { value: "Teinte Unie Amber 25% - 30.00 €", label: "Teinte Unie Amber 25% - 30.00 €" },
  { value: "Teinte Unie Amber 10% - 30.00 €", label: "Teinte Unie Amber 10% - 30.00 €" },
  { value: "Teinte Unie Rose 85% - 30.00 €", label: "Teinte Unie Rose 85% - 30.00 €" },
  { value: "Teinte Unie Rose 75% - 30.00 €", label: "Teinte Unie Rose 75% - 30.00 €" },
  { value: "Teinte Unie Rose 50% - 30.00 €", label: "Teinte Unie Rose 50% - 30.00 €" },
  { value: "Teinte Unie Rose 25% - 30.00 €", label: "Teinte Unie Rose 25% - 30.00 €" },
  { value: "Teinte Unie Rose 10% - 30.00 €", label: "Teinte Unie Rose 10% - 30.00 €" },
  { value: "Teinte Unie Champagne 85% - 30.00 €", label: "Teinte Unie Champagne 85% - 30.00 €" },
  { value: "Teinte Unie Champagne 75% - 30.00 €", label: "Teinte Unie Champagne 75% - 30.00 €" },
  { value: "Teinte Unie Champagne 50% - 30.00 €", label: "Teinte Unie Champagne 50% - 30.00 €" },
  { value: "Teinte Unie Champagne 25% - 30.00 €", label: "Teinte Unie Champagne 25% - 30.00 €" },
  { value: "Teinte Unie Champagne 10% - 30.00 €", label: "Teinte Unie Champagne 10% - 30.00 €" },
  { value: "Teinte Unie Orange 85% - 30.00 €", label: "Teinte Unie Orange 85% - 30.00 €" },
  { value: "Teinte Unie Orange 75% - 30.00 €", label: "Teinte Unie Orange 75% - 30.00 €" },
  { value: "Teinte Unie Orange 50% - 30.00 €", label: "Teinte Unie Orange 50% - 30.00 €" },
  { value: "Teinte Unie Orange 25% - 30.00 €", label: "Teinte Unie Orange 25% - 30.00 €" },
  { value: "Teinte Unie Orange 10% - 30.00 €", label: "Teinte Unie Orange 10% - 30.00 €" },
  { value: "Teinte Deg Grey 85%-0% - 50.00 €", label: "Teinte Deg Grey 85%-0% - 50.00 €" },
  { value: "Teinte Deg Grey 50%-0% - 50.00 €", label: "Teinte Deg Grey 50%-0% - 50.00 €" },
  { value: "Teinte Deg Grey 25%-0% - 50.00 €", label: "Teinte Deg Grey 25%-0% - 50.00 €" },
  { value: "Teinte Deg Grey 85%-25% - 50.00 €", label: "Teinte Deg Grey 85%-25% - 50.00 €" },
  { value: "Teinte Deg Grey 50%-25% - 50.00 €", label: "Teinte Deg Grey 50%-25% - 50.00 €" },
  { value: "Teinte Deg Grey 85%-50% - 50.00 €", label: "Teinte Deg Grey 85%-50% - 50.00 €" },
  { value: "Teinte Deg Brown 85%-0% - 50.00 €", label: "Teinte Deg Brown 85%-0% - 50.00 €" },
  { value: "Teinte Deg Brown 50%-0% - 50.00 €", label: "Teinte Deg Brown 50%-0% - 50.00 €" },
  { value: "Teinte Deg Brown 25%-0% - 50.00 €", label: "Teinte Deg Brown 25%-0% - 50.00 €" },
  { value: "Teinte Deg Brown 85%-25% - 50.00 €", label: "Teinte Deg Brown 85%-25% - 50.00 €" },
  { value: "Teinte Deg Brown 50%-25% - 50.00 €", label: "Teinte Deg Brown 50%-25% - 50.00 €" },
  { value: "Teinte Deg Brown 85%-50% - 50.00 €", label: "Teinte Deg Brown 85%-50% - 50.00 €" },
  { value: "Teinte Deg G15 85%-0% - 50.00 €", label: "Teinte Deg G15 85%-0% - 50.00 €" },
  { value: "Teinte Deg G15 50%-0% - 50.00 €", label: "Teinte Deg G15 50%-0% - 50.00 €" },
  { value: "Teinte Deg G15 25%-0% - 50.00 €", label: "Teinte Deg G15 25%-0% - 50.00 €" },
  { value: "Teinte Deg G15 85%-25% - 50.00 €", label: "Teinte Deg G15 85%-25% - 50.00 €" },
  { value: "Teinte Deg G15 50%-25% - 50.00 €", label: "Teinte Deg G15 50%-25% - 50.00 €" },
  { value: "Teinte Deg G15 85%-50% - 50.00 €", label: "Teinte Deg G15 85%-50% - 50.00 €" },
  { value: "Teinte Deg Blue 85%-0% - 50.00 €", label: "Teinte Deg Blue 85%-0% - 50.00 €" },
  { value: "Teinte Deg Blue 50%-0% - 50.00 €", label: "Teinte Deg Blue 50%-0% - 50.00 €" },
  { value: "Teinte Deg Blue 25%-0% - 50.00 €", label: "Teinte Deg Blue 25%-0% - 50.00 €" },
  { value: "Teinte Deg Blue 85%-25% - 50.00 €", label: "Teinte Deg Blue 85%-25% - 50.00 €" },
  { value: "Teinte Deg Blue 50%-25% - 50.00 €", label: "Teinte Deg Blue 50%-25% - 50.00 €" },
  { value: "Teinte Deg Blue 85%-50% - 50.00 €", label: "Teinte Deg Blue 85%-50% - 50.00 €" },
  { value: "Teinte Deg Yellow 85%-0% - 50.00 €", label: "Teinte Deg Yellow 85%-0% - 50.00 €" },
  { value: "Teinte Deg Yellow 50%-0% - 50.00 €", label: "Teinte Deg Yellow 50%-0% - 50.00 €" },
  { value: "Teinte Deg Yellow 25%-0% - 50.00 €", label: "Teinte Deg Yellow 25%-0% - 50.00 €" },
  { value: "Teinte Deg Yellow 85%-25% - 50.00 €", label: "Teinte Deg Yellow 85%-25% - 50.00 €" },
  { value: "Teinte Deg Yellow 50%-25% - 50.00 €", label: "Teinte Deg Yellow 50%-25% - 50.00 €" },
  { value: "Teinte Deg Yellow 85%-50% - 50.00 €", label: "Teinte Deg Yellow 85%-50% - 50.00 €" },
  { value: "Teinte Deg Pink 85%-0% - 50.00 €", label: "Teinte Deg Pink 85%-0% - 50.00 €" },
  { value: "Teinte Deg Pink 50%-0% - 50.00 €", label: "Teinte Deg Pink 50%-0% - 50.00 €" },
  { value: "Teinte Deg Pink 25%-0% - 50.00 €", label: "Teinte Deg Pink 25%-0% - 50.00 €" },
  { value: "Teinte Deg Pink 85%-25% - 50.00 €", label: "Teinte Deg Pink 85%-25% - 50.00 €" },
  { value: "Teinte Deg Pink 50%-25% - 50.00 €", label: "Teinte Deg Pink 50%-25% - 50.00 €" },
  { value: "Teinte Deg Pink 85%-50% - 50.00 €", label: "Teinte Deg Pink 85%-50% - 50.00 €" },
  { value: "Teinte Deg Purple 85%-0% - 50.00 €", label: "Teinte Deg Purple 85%-0% - 50.00 €" },
  { value: "Teinte Deg Purple 50%-0% - 50.00 €", label: "Teinte Deg Purple 50%-0% - 50.00 €" },
  { value: "Teinte Deg Purple 25%-0% - 50.00 €", label: "Teinte Deg Purple 25%-0% - 50.00 €" },
  { value: "Teinte Deg Purple 85%-25% - 50.00 €", label: "Teinte Deg Purple 85%-25% - 50.00 €" },
  { value: "Teinte Deg Purple 50%-25% - 50.00 €", label: "Teinte Deg Purple 50%-25% - 50.00 €" },
  { value: "Teinte Deg Purple 85%-50% - 50.00 €", label: "Teinte Deg Purple 85%-50% - 50.00 €" },
  { value: "Teinte Deg Black 85%-0% - 50.00 €", label: "Teinte Deg Black 85%-0% - 50.00 €" },
  { value: "Teinte Deg Black 50%-0% - 50.00 €", label: "Teinte Deg Black 50%-0% - 50.00 €" },
  { value: "Teinte Deg Black 25%-0% - 50.00 €", label: "Teinte Deg Black 25%-0% - 50.00 €" },
  { value: "Teinte Deg Black 85%-25% - 50.00 €", label: "Teinte Deg Black 85%-25% - 50.00 €" },
  { value: "Teinte Deg Black 50%-25% - 50.00 €", label: "Teinte Deg Black 50%-25% - 50.00 €" },
  { value: "Teinte Deg Black 85%-50% - 50.00 €", label: "Teinte Deg Black 85%-50% - 50.00 €" },
  { value: "Teinte Deg Amber 85%-0% - 50.00 €", label: "Teinte Deg Amber 85%-0% - 50.00 €" },
  { value: "Teinte Deg Amber 50%-0% - 50.00 €", label: "Teinte Deg Amber 50%-0% - 50.00 €" },
  { value: "Teinte Deg Amber 25%-0% - 50.00 €", label: "Teinte Deg Amber 25%-0% - 50.00 €" },
  { value: "Teinte Deg Amber 85%-25% - 50.00 €", label: "Teinte Deg Amber 85%-25% - 50.00 €" },
  { value: "Teinte Deg Amber 50%-25% - 50.00 €", label: "Teinte Deg Amber 50%-25% - 50.00 €" },
  { value: "Teinte Deg Amber 85%-50% - 50.00 €", label: "Teinte Deg Amber 85%-50% - 50.00 €" },
  { value: "Teinte Deg Rose 85%-0% - 50.00 €", label: "Teinte Deg Rose 85%-0% - 50.00 €" },
  { value: "Teinte Deg Rose 50%-0% - 50.00 €", label: "Teinte Deg Rose 50%-0% - 50.00 €" },
  { value: "Teinte Deg Rose 25%-0% - 50.00 €", label: "Teinte Deg Rose 25%-0% - 50.00 €" },
  { value: "Teinte Deg Rose 85%-25% - 50.00 €", label: "Teinte Deg Rose 85%-25% - 50.00 €" },
  { value: "Teinte Deg Rose 50%-25% - 50.00 €", label: "Teinte Deg Rose 50%-25% - 50.00 €" },
  { value: "Teinte Deg Rose 85%-50% - 50.00 €", label: "Teinte Deg Rose 85%-50% - 50.00 €" },
  {value: "Teinte Deg Champagne 85%-0% - 50.00 €", label: "Teinte Deg Champagne 85%-0% - 50.00 €" },
  {value: "Teinte Deg Champagne 50%-0% - 50.00 €", label: "Teinte Deg Champagne 50%-0% - 50.00 €" },
  {value: "Teinte Deg Champagne 25%-0% - 50.00 €", label: "Teinte Deg Champagne 25%-0% - 50.00 €" },
  {value: "Teinte Deg Champagne 85%-25% - 50.00 €", label: "Teinte Deg Champagne 85%-25% - 50.00 €" },
  {value: "Teinte Deg Champagne 50%-25% - 50.00 €", label: "Teinte Deg Champagne 50%-25% - 50.00 €" },
  {value: "Teinte Deg Champagne 85%-50% - 50.00 €", label: "Teinte Deg Champagne 85%-50% - 50.00 €" },
  { value: "Teinte Deg Orange 85%-0% - 50.00 €", label: "Teinte Deg Orange 85%-0% - 50.00 €" },
  { value: "Teinte Deg Orange 50%-0% - 50.00 €", label: "Teinte Deg Orange 50%-0% - 50.00 €" },
  { value: "Teinte Deg Orange 25%-0% - 50.00 €", label: "Teinte Deg Orange 25%-0% - 50.00 €" },
  { value: "Teinte Deg Orange 85%-25% - 50.00 €", label: "Teinte Deg Orange 85%-25% - 50.00 €" },
  { value: "Teinte Deg Orange 50%-25% - 50.00 €", label: "Teinte Deg Orange 50%-25% - 50.00 €" },
  { value: "Teinte Deg Orange 85%-50% - 50.00 €", label: "Teinte Deg Orange 85%-50% - 50.00 €" },
];
/////////////////////////////////////////////////

////////////////////////Treatment///////////////////////////////////
export const TreatmentA1 = [
  { value: "", label: "" },
  { value: "HC - 0.00 €", label: "HC - N/A" ,isDisabled: true },
  { value: "HMC - 0.00 €", label: "HMC - 0.00 €" },
  { value: "Super HMC - 0.00 €", label: "Super HMC - N/A" ,isDisabled: true },
  { value: "Back HMC - 0.00 €", label: "Back HMC - N/A" ,isDisabled: true },
  { value: "Super HMC Green - 0.00 €", label: "Super HMC Green - N/A" ,isDisabled: true },
  { value: "HMC Anti-Buée - 0.00 €", label: "HMC Anti-Buée - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Red - 0.00 €", label: "Mirror Back HMC Red - N/A" ,isDisabled: true },
  {value: "Mirror Back Super HMC Green - 0.00 €", label: "Mirror Back Super HMC Green - N/A" ,isDisabled: true },
  { value: "Premium Super HMC - 0.00 €", label: "Premium Super HMC - N/A" ,isDisabled: true },
  { value: "Super HMC Blue - 0.00 €", label: "Super HMC Blue - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Green - 0.00 €", label: "Mirror Back HMC Green - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Blue - 0.00 €", label: "Mirror Back HMC Blue - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Pink - 0.00 €", label: "Mirror Back HMC Pink - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Gold - 0.00 €", label: "Mirror Back HMC Gold - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Silver - 0.00 €", label: "Mirror Back HMC Silver - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Purple - 0.00 €", label: "Mirror Back HMC Purple - N/A" ,isDisabled: true },
  { value: "Mirror Back HMC Champagne - 0.00 €", label: "Mirror Back HMC Champagne - N/A" ,isDisabled: true },
  { value: "Mirror Back Super HMC Red - 0.00 €", label: "Mirror Back Super HMC Red - N/A" ,isDisabled: true },
  { value: "Mirror Back Super HMC Blue - 0.00 €", label: "Mirror Back Super HMC Blue - N/A" ,isDisabled: true },
  { value: "Mirror Back Super HMC Pink - 0.00 €", label: "Mirror Back Super HMC Pink - N/A" ,isDisabled: true },
  { value: "Mirror Back Super HMC Gold - 0.00 €", label: "Mirror Back Super HMC Gold - N/A" ,isDisabled: true },
  {value: "Mirror Back Super HMC Silver - 0.00 €", label: "Mirror Back Super HMC Silver - N/A" ,isDisabled: true },
  {value: "Mirror Back Super HMC Purple - 0.00 €", label: "Mirror Back Super HMC Purple - N/A" ,isDisabled: true },
  {value: "Mirror Back Super HMC Champagne - 0.00 €", label: "Mirror Back Super HMC Champagne - N/A" ,isDisabled: true },
];
export const Treatment234 = [
  { value: "", label: "" },
  { value: "HC - 0.00 €", label: "HC - 0.00 €", },
  { value: "HMC - 0.00 €", label: "HMC - 0.00 €", },
  { value: "Super HMC - 0.00 €", label: "Super HMC - 0.00 €", },
  { value: "Back HMC - 0.00 €", label: "Back HMC - 0.00 €", },
  { value: "Super HMC Green - 0.00 €", label: "Super HMC Green - 0.00 €", },
  { value: "HMC Anti-Buée - 100.00 €", label: "HMC Anti-Buée - 100.00 €" },
  { value: "Mirror Back HMC Red - 50.00 €", label: "Mirror Back HMC Red - 50.00 €" },
  {value:  "Mirror Back Super HMC Green - 50.00 €", label: "Mirror Back Super HMC Green - 50.00 €" },
  { value: "Premium Super HMC - 100.00 €", label: "Premium Super HMC - 100.00 €" },
  { value: "Super HMC Blue - 50.00 €", label: "Super HMC Blue - 50.00 €", },
  { value: "Mirror Back HMC Green - 50.00 €", label: "Mirror Back HMC Green - 50.00 €" },
  { value: "Mirror Back HMC Blue - 50.00 €", label: "Mirror Back HMC Blue - 50.00 €" },
  { value: "Mirror Back HMC Pink - 50.00 €", label: "Mirror Back HMC Pink - 50.00 €" },
  { value: "Mirror Back HMC Gold - 50.00 €", label: "Mirror Back HMC Gold - 50.00 €" },
  { value: "Mirror Back HMC Silver - 50.00 €", label: "Mirror Back HMC Silver - 50.00 €" },
  { value: "Mirror Back HMC Purple - 50.00 €", label: "Mirror Back HMC Purple - 50.00 €" },
  { value: "Mirror Back HMC Champagne - 50.00 €", label: "Mirror Back HMC Champagne - 50.00 €" },
  { value: "Mirror Back Super HMC Red - 50.00 €", label: "Mirror Back Super HMC Red - 50.00 €" },
  { value: "Mirror Back Super HMC Blue - 50.00 €", label: "Mirror Back Super HMC Blue - 50.00 €" },
  { value: "Mirror Back Super HMC Pink - 50.00 €", label: "Mirror Back Super HMC Pink - 50.00 €" },
  { value: "Mirror Back Super HMC Gold - 50.00 €", label: "Mirror Back Super HMC Gold - 50.00 €" },
  {value: "Mirror Back Super HMC Silver - 50.00 €", label: "Mirror Back Super HMC Silver - 50.00 €" },
  {value: "Mirror Back Super HMC Purple - 50.00 €", label: "Mirror Back Super HMC Purple - 50.00 €" },
  {value: "Mirror Back Super HMC Champagne - 50.00 €", label: "Mirror Back Super HMC Champagne - 50.00 €" },
];
export const TreatmentSP3 = [
  { value: "", label: "" },
  { value: "HC - 0.00 €", label: "HC - 0.00 €" },
  { value: "HMC - 0.00 €", label: "HMC - 0.00 €" },
  { value: "Super HMC - 100.00 €", label: "Super HMC Green - 100.00 €" },
  { value: "Super HMC Green - 100.00 €", label: "Super HMC Green - 100.00 €" },
  { value: "Back HMC - 0.00 €", label: "Back HMC - 0.00 €" },
  //{ value: "Back Super HMC - 100.00 €", label: "Back Super HMC - 100.00 €" },
  { value: "HMC Anti-Buée - 100.00 €", label: "HMC Anti-Buée - 100.00 €" },
  { value: "Mirror Back HMC Red - 50.00 €", label: "Mirror Back HMC Red - 50.00 €" },
  {
    value: "Mirror Back Super HMC Green - 100.00 €",
    label: "Mirror Back Super HMC Green - 100.00 €",
  },
  { value: "Premium Super HMC - 100.00 €", label: "Premium Super HMC - 100.00 €" },
  { value: "Super HMC Blue - 100.00 €", label: "Super HMC Blue - 100.00 €" },
  { value: "Mirror Back HMC Green - 50.00 €", label: "Mirror Back HMC Green - 50.00 €" },
  { value: "Mirror Back HMC Blue - 50.00 €", label: "Mirror Back HMC Blue - 50.00 €" },
  { value: "Mirror Back HMC Pink - 50.00 €", label: "Mirror Back HMC Pink - 50.00 €" },
  { value: "Mirror Back HMC Gold - 50.00 €", label: "Mirror Back HMC Gold - 50.00 €" },
  { value: "Mirror Back HMC Silver - 50.00 €", label: "Mirror Back HMC Silver - 50.00 €" },
  { value: "Mirror Back HMC Purple - 50.00 €", label: "Mirror Back HMC Purple - 50.00 €" },
  { value: "Mirror Back HMC Champagne - 50.00 €", label: "Mirror Back HMC Champagne - 50.00 €" },
  { value: "Mirror Back Super HMC Red - 100.00 €", label: "Mirror Back Super HMC Red - 100.00 €" },
  { value: "Mirror Back Super HMC Blue - 100.00 €", label: "Mirror Back Super HMC Blue - 100.00 €" },
  { value: "Mirror Back Super HMC Pink - 100.00 €", label: "Mirror Back Super HMC Pink - 100.00 €" },
  { value: "Mirror Back Super HMC Gold - 100.00 €", label: "Mirror Back Super HMC Gold - 100.00 €" },
  {
    value: "Mirror Back Super HMC Silver - 100.00 €",
    label: "Mirror Back Super HMC Silver - 100.00 €",
  },
  {
    value: "Mirror Back Super HMC Purple - 100.00 €",
    label: "Mirror Back Super HMC Purple - 100.00 €",
  },
  {
    value: "Mirror Back Super HMC Champagne - 100.00 €",
    label: "Mirror Back Super HMC Champagne - 100.00 €",
  },
];
//////////////////////////////////////////////////////////////////////////

////////////////////Indice//////////////////////////
export const IndiceA1 = [
  { value: "", label: "" },
  { value: "1.5 - 0.00 €", label: "1.5 - 0.00 €" },
  { value: "1.6 - 0.00 €", label: "1.6 - 0.00 €" },
  { value: "1.67 - 0.00 €", label: "1.67 - 0.00 €" },
  { value: "1.74 - 0.00 €", label: "1.74 - 0.00 €" },
];
export const Indiceothers = [
  { value: "", label: "" },
  { value: "1.5 - 0.00 €", label: "1.5 - 0.00 €" },
  { value: "1.6 - 30.00 €", label: "1.6 - 30.00 €" },
  { value: "1.67 - 70.00 €", label: "1.67 - 70.00 €" },
  { value: "1.74 - 120.00 €", label: "1.74 - 120.00 €" },
];
///////////////////////////////////////////////////////////////

/////////////////Typedeverre///////////////////
export const TypedeverreA1 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 0.00 €", label: "SYM HD SV A - 0.00 €" },
  { value: "SYM RX HD SV A - 0.00 €", label: "SYM RX HD SV A - 0.00 €" },
  { value: "SYM HD PREMIUM SV - 0.00 €", label: "SYM HD PREMIUM SV - 0.00 €" },
  { value: "SYM RX HD PREMIUM SV - 0.00 €", label: "SYM RX HD PREMIUM SV - 0.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 0.00 €", label: "SYM HD ZenView Add 0.50 - 0.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 0.00 €", label: "SYM HD ZenView Add 0.75 - 0.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 0.00 €", label: "SYM RX HD PREMIUM PROG 11 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 0.00 €", label: "SYM RX HD PROG A 11 - 0.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 0.00 €", label: "SYM RX HD DEG 1M - 0.00 €" },
  { value: "SYM RX HD DEG 2M - 0.00 €", label: "SYM RX HD DEG 2M - 0.00 €" },
  { value: "SYM RX HD DEG 3M - 0.00 €", label: "SYM RX HD DEG 3M - 0.00 €" },
  { value: "SYM RX HD DEG 4M - 0.00 €", label: "SYM RX HD DEG 4M - 0.00 €" },
  { value: "SYM RX HD DEG 5M - 0.00 €", label: "SYM RX HD DEG 5M - 0.00 €" },
  { value: "SYM RX HD DEG 6M - 0.00 €", label: "SYM RX HD DEG 6M - 0.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 0.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 0.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 0.00 €", label: "SYM RX DOUBLE FOYER 28 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 0.00 €", label: "SYM RX HD SV A Aspheric - 0.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 0.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 0.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 0.00 €", label: "SYM RX HD SV A Myopie - 0.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 0.00 €",
    label: "Sym RX HD Premium SV Myopie - 0.00 €",
  },
  // { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
  // { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
  // {
  //   value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  //   label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  // },
];
export const TypedeverreA2 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 34.00 €", label: "SYM HD SV A - 34.00 €" },
  { value: "SYM RX HD SV A - 74.00 €", label: "SYM RX HD SV A - 74.00 €" },
  { value: "SYM HD PREMIUM SV - 134.00 €", label: "SYM HD PREMIUM SV - 134.00 €" },
  { value: "SYM RX HD PREMIUM SV - 174.00 €", label: "SYM RX HD PREMIUM SV - 174.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 63.00 €", label: "SYM HD ZenView Add 0.50 - 63.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 63.00 €", label: "SYM HD ZenView Add 0.75 - 63.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 244.00 €", label: "SYM RX HD PREMIUM PROG 11 - 244.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 44.00 €", label: "SYM RX HD PROG A 11 - 44.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 44.00 €", label: "SYM RX HD DEG 1M - 44.00 €" },
  { value: "SYM RX HD DEG 2M - 44.00 €", label: "SYM RX HD DEG 2M - 44.00 €" },
  { value: "SYM RX HD DEG 3M - 44.00 €", label: "SYM RX HD DEG 3M - 44.00 €" },
  { value: "SYM RX HD DEG 4M - 44.00 €", label: "SYM RX HD DEG 4M - 44.00 €" },
  { value: "SYM RX HD DEG 5M - 44.00 €", label: "SYM RX HD DEG 5M - 44.00 €" },
  { value: "SYM RX HD DEG 6M - 44.00 €", label: "SYM RX HD DEG 6M - 44.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 244.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 244.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 44.00 €", label: "SYM RX DOUBLE FOYER 28 - 44.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 74.00 €", label: "SYM RX HD SV A Aspheric - 74.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 174.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 174.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 223.00 €", label: "SYM RX HD SV A Myopie - 223.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 323.00 €",
    label: "Sym RX HD Premium SV Myopie - 323.00 €",
  },
  // { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
  // { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
  // {
  //   value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  //   label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  // },
];
export const TypedeverreB1 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 219.00 €", label: "SYM HD SV A - 219.00 €" },
  { value: "SYM RX HD SV A - 259.00 €", label: "SYM RX HD SV A - 259.00 €" },
  { value: "SYM HD PREMIUM SV - 219.00 €", label: "SYM HD PREMIUM SV - 219.00 €" },
  { value: "SYM RX HD PREMIUM SV - 259.00 €", label: "SYM RX HD PREMIUM SV - 259.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 438.00 €", label: "SYM HD ZenView Add 0.50 - 438.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 438.00 €", label: "SYM HD ZenView Add 0.75 - 438.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 419.00 €", label: "SYM RX HD PREMIUM PROG 11 - 419.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 419.00 €", label: "SYM RX HD PROG A 11 - 419.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 419.00 €", label: "SYM RX HD DEG 1M - 419.00 €" },
  { value: "SYM RX HD DEG 2M - 419.00 €", label: "SYM RX HD DEG 2M - 419.00 €" },
  { value: "SYM RX HD DEG 3M - 419.00 €", label: "SYM RX HD DEG 3M - 419.00 €" },
  { value: "SYM RX HD DEG 4M - 419.00 €", label: "SYM RX HD DEG 4M - 419.00 €" },
  { value: "SYM RX HD DEG 5M - 419.00 €", label: "SYM RX HD DEG 5M - 419.00 €" },
  { value: "SYM RX HD DEG 6M - 419.00 €", label: "SYM RX HD DEG 6M - 419.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 619.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 619.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 419.00 €", label: "SYM RX DOUBLE FOYER 28 - 419.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 259.00 €", label: "SYM RX HD SV A Aspheric - 259.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 259.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 259.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 598.00 €", label: "SYM RX HD SV A Myopie - 598.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 698.00 €",
    label: "Sym RX HD Premium SV Myopie - 698.00 €",
  },
  // { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
  // { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
  // {
  //   value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  //   label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
//   },
 ];
export const TypedeverreB2 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 0.00 €", label: "SYM HD SV A - 0.00 €" },
  { value: "SYM RX HD SV A - 40.00 €", label: "SYM RX HD SV A - 40.00 €" },
  { value: "SYM HD PREMIUM SV - 100.00 €", label: "SYM HD PREMIUM SV - 100.00 €" },
  { value: "SYM RX HD PREMIUM SV - 140.00 €", label: "SYM RX HD PREMIUM SV - 140.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 19.00 €", label: "SYM HD ZenView Add 0.50 - 19.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 19.00 €", label: "SYM HD ZenView Add 0.75 - 19.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 200.00 €", label: "SYM RX HD PREMIUM PROG 11 - 200.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 0.00 €", label: "SYM RX HD PROG A 11 - 0.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 0.00 €", label: "SYM RX HD DEG 1M - 0.00 €" },
  { value: "SYM RX HD DEG 2M - 0.00 €", label: "SYM RX HD DEG 2M - 0.00 €" },
  { value: "SYM RX HD DEG 3M - 0.00 €", label: "SYM RX HD DEG 3M - 0.00 €" },
  { value: "SYM RX HD DEG 4M - 0.00 €", label: "SYM RX HD DEG 4M - 0.00 €" },
  { value: "SYM RX HD DEG 5M - 0.00 €", label: "SYM RX HD DEG 5M - 0.00 €" },
  { value: "SYM RX HD DEG 6M - 0.00 €", label: "SYM RX HD DEG 6M - 0.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 200.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 200.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 0.00 €", label: "SYM RX DOUBLE FOYER 28 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 40.00 €", label: "SYM RX HD SV A Aspheric - 40.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 140.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 140.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 179.00 €", label: "SYM RX HD SV A Myopie - 179.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 279.00 €",
    label: "Sym RX HD Premium SV Myopie - 279.00 €",
  },
  // { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
  // { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
  // {
  //   value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  //   label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  // },
];
export const TypedeverreSP1 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 49.00 €", label: "SYM HD SV A - 49.00 €" },
  { value: "SYM RX HD SV A - 89.00 €", label: "SYM RX HD SV A - 89.00 €" },
  { value: "SYM HD PREMIUM SV - 149.00 €", label: "SYM HD PREMIUM SV - 149.00 €" },
  { value: "SYM RX HD PREMIUM SV - 189.00 €", label: "SYM RX HD PREMIUM SV - 189.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 158.00 €", label: "SYM HD ZenView Add 0.50 - 158.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 158.00 €", label: "SYM HD ZenView Add 0.75 - 158.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 339.00 €", label: "SYM RX HD PREMIUM PROG 11 - 339.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 139.00 €", label: "SYM RX HD PROG A 11 - 139.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 139.00 €", label: "SYM RX HD DEG 1M - 139.00 €" },
  { value: "SYM RX HD DEG 2M - 139.00 €", label: "SYM RX HD DEG 2M - 139.00 €" },
  { value: "SYM RX HD DEG 3M - 139.00 €", label: "SYM RX HD DEG 3M - 139.00 €" },
  { value: "SYM RX HD DEG 4M - 139.00 €", label: "SYM RX HD DEG 4M - 139.00 €" },
  { value: "SYM RX HD DEG 5M - 139.00 €", label: "SYM RX HD DEG 5M - 139.00 €" },
  { value: "SYM RX HD DEG 6M - 139.00 €", label: "SYM RX HD DEG 6M - 139.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 339.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 339.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 139.00 €", label: "SYM RX DOUBLE FOYER 28 - 139.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 89.00 €", label: "SYM RX HD SV A Aspheric - 89.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 189.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 189.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 318.00 €", label: "SYM RX HD SV A Myopie - 318.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 418.00 €",
    label: "Sym RX HD Premium SV Myopie - 418.00 €",
  },
//   { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
//   { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
//   {
//     value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
//     label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
//   },
 ];
export const TypedeverreSP2 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 59.00 €", label: "SYM HD SV A - 59.00 €" },
  { value: "SYM RX HD SV A - 99.00 €", label: "SYM RX HD SV A - 99.00 €" },
  { value: "SYM HD PREMIUM SV - 159.00 €", label: "SYM HD PREMIUM SV - 159.00 €" },
  { value: "SYM RX HD PREMIUM SV - 199.00 €", label: "SYM RX HD PREMIUM SV - 199.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 108.00 €", label: "SYM HD ZenView Add 0.50 - 108.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 108.00 €", label: "SYM HD ZenView Add 0.75 - 108.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 289.00 €", label: "SYM RX HD PREMIUM PROG 11 - 289.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 89.00 €", label: "SYM RX HD PROG A 11 - 89.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 89.00 €", label: "SYM RX HD DEG 1M - 89.00 €" },
  { value: "SYM RX HD DEG 2M - 89.00 €", label: "SYM RX HD DEG 2M - 89.00 €" },
  { value: "SYM RX HD DEG 3M - 89.00 €", label: "SYM RX HD DEG 3M - 89.00 €" },
  { value: "SYM RX HD DEG 4M - 89.00 €", label: "SYM RX HD DEG 4M - 89.00 €" },
  { value: "SYM RX HD DEG 5M - 89.00 €", label: "SYM RX HD DEG 5M - 89.00 €" },
  { value: "SYM RX HD DEG 6M - 89.00 €", label: "SYM RX HD DEG 6M - 89.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 289.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 289.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 89.00 €", label: "SYM RX DOUBLE FOYER 28 - 89.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 99.00 €", label: "SYM RX HD SV A Aspheric - 99.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 199.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 199.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 268.00 €", label: "SYM RX HD SV A Myopie - 268.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 368.00 €",
    label: "Sym RX HD Premium SV Myopie - 368.00 €",
  },
  // { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
  // { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
  // {
  //   value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  //   label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  // },
];
export const TypedeverreSP3 = [
  { value: "", label: "" },
  { value: "SYM HD SV A - 54.00 €", label: "SYM HD SV A - 54.00 €" },
  { value: "SYM RX HD SV A - 94.00 €", label: "SYM RX HD SV A - 94.00 €" },
  { value: "SYM HD PREMIUM SV - 154.00 €", label: "SYM HD PREMIUM SV - 154.00 €" },
  { value: "SYM RX HD PREMIUM SV - 194.00 €", label: "SYM RX HD PREMIUM SV - 194.00 €" },
  { value: "SYM HD ZenView Add 0.50 - 133.00 €", label: "SYM HD ZenView Add 0.50 - 133.00 €" },
  { value: "SYM HD ZenView Add 0.75 - 133.00 €", label: "SYM HD ZenView Add 0.75 - 133.00 €" },
  { value: "SYM RX HD PREMIUM PROG 11 - 314.00 €", label: "SYM RX HD PREMIUM PROG 11 - 314.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 5 - 0.00 €", label: "SYM RX HD PREMIUM PROG 5 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 7 - 0.00 €", label: "SYM RX HD PREMIUM PROG 7 - 0.00 €" },
  // { value: "SYM RX HD PREMIUM PROG 9 - 0.00 €", label: "SYM RX HD PREMIUM PROG 9 - 0.00 €" },
  { value: "SYM RX HD PROG A 11 - 114.00 €", label: "SYM RX HD PROG A 11 - 114.00 €" },
  // { value: "SYM RX HD PROG A 5 - 0.00 €", label: "SYM RX HD PROG A 5 - 0.00 €" },
  // { value: "SYM RX HD PROG A 7 - 0.00 €", label: "SYM RX HD PROG A 7 - 0.00 €" },
  // { value: "SYM RX HD PROG A 9 - 0.00 €", label: "SYM RX HD PROG A 9 - 0.00 €" },
  { value: "SYM RX HD DEG 1M - 114.00 €", label: "SYM RX HD DEG 1M - 114.00 €" },
  { value: "SYM RX HD DEG 2M - 114.00 €", label: "SYM RX HD DEG 2M - 114.00 €" },
  { value: "SYM RX HD DEG 3M - 114.00 €", label: "SYM RX HD DEG 3M - 114.00 €" },
  { value: "SYM RX HD DEG 4M - 114.00 €", label: "SYM RX HD DEG 4M - 114.00 €" },
  { value: "SYM RX HD DEG 5M - 114.00 €", label: "SYM RX HD DEG 5M - 114.00 €" },
  { value: "SYM RX HD DEG 6M - 114.00 €", label: "SYM RX HD DEG 6M - 114.00 €" },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH16 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH17 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH18 - 0.00 €",
  // },
  // {
  //   value: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  //   label: "SYM RX HD ULTRA PREMIUM PROG FH19 - 0.00 €",
  // },
  {
    value: "SYM RX HD ULTRA PREMIUM PROG FH20 - 314.00 €",
    label: "SYM RX HD ULTRA PREMIUM PROG FH20 - 314.00 €",
  },
  // { value: "SYM RX DOUBLE FOYER 25 - 0.00 €", label: "SYM RX DOUBLE FOYER 25 - 0.00 €" },
  { value: "SYM RX DOUBLE FOYER 28 - 114.00 €", label: "SYM RX DOUBLE FOYER 28 - 114.00 €" },
  // { value: "SYM RX DOUBLE FOYER 35 - 0.00 €", label: "SYM RX DOUBLE FOYER 35 - 0.00 €" },
  // { value: "SYM RX DOUBLE FOYER 45 - 0.00 €", label: "SYM RX DOUBLE FOYER 45 - 0.00 €" },
  { value: "SYM RX HD SV A Aspheric - 94.00 €", label: "SYM RX HD SV A Aspheric - 94.00 €" },
  {
    value: "SYM RX HD PREMIUM SV Aspheric - 194.00 €",
    label: "SYM RX HD PREMIUM SV Aspheric - 194.00 €",
  },
  { value: "SYM RX HD SV A Myopie - 293.00 €", label: "SYM RX HD SV A Myopie - 293.00 €" },
  {
    value: "Sym RX HD Premium SV Myopie - 393.00 €",
    label: "Sym RX HD Premium SV Myopie - 393.00 €",
  },
  // { value: "SYM RX HD SV A MINERAL - 0.00 €", label: "SYM RX HD SV A MINERAL - 0.00 €" },
  // { value: "SYM RX PROG A MINERAL - 0.00 €", label: "SYM RX PROG A MINERAL - 0.00 €" },
  // {
  //   value: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  //   label: "SYM RX DOUBLE FOYER MINERAL - 0.00 €",
  // },
]
////////////////////////////////////////////////

////////////////Option de verre//////////////////////
export const LensTypeDetailsA1 = [
  { value: "", label: "" },
  // { value: "Night Drive - 0.00 €", label: "Night Drive - N/A" },
  { value: "Photochromic Grey - 0.00 €", label: "Photochromic Grey - N/A",isDisabled: true },
  { value: "Photochromic Brown - 0.00 €", label: "Photochromic Brown - N/A",isDisabled: true },
  { value: "Photochromic G15 - 0.00 €", label: "Photochromic G15 - N/A",isDisabled: true },
  { value: "Nupolar Grey - 0.00 €", label: "Nupolar Grey - N/A",isDisabled: true },
  { value: "Nupolar Brown - 0.00 €", label: "Nupolar Brown - N/A",isDisabled: true },
  { value: "Nupolar G15 - 0.00 €", label: "Nupolar G15 - N/A",isDisabled: true },
  { value: "Nupolar Y30 - 0.00 €", label: "Nupolar Y30 - N/A",isDisabled: true },
  // { value: "Transition 7 Brown - 0.00 €", label: "Transition 7 Brown - N/A",isDisabled: true },
  { value: "Transition 8 Grey - 0.00 €", label: "Transition 8 Grey - N/A",isDisabled: true },
  { value: "Transition 8 Brown - 0.00 €", label: "Transition 8 Brown - N/A",isDisabled: true },
  { value: "Transition 8 Green - 0.00 €", label: "Transition 8 Green - N/A",isDisabled: true },
  { value: "Transition Vantage Grey - 0.00 €", label: "Transition Vantage Grey - N/A",isDisabled: true },
  { value: "Transition XTRA Grey - 0.00 €", label: "Transition XTRA Grey - N/A",isDisabled: true },
  { value: "Blue Mass - 0.00 €", label: "Blue Mass - N/A",isDisabled: true },
  {value: "Photochromic Grey Blue Mass - 0.00 €", label: "Photochromic Grey Blue Mass - N/A",isDisabled: true },
  {value: "Photochromic Brown Blue Mass - 0.00 €", label: "Photochromic Brown Blue Mass - N/A",isDisabled: true },
];
export const LensTypeDetailsB2 = [
  { value: "", label: "" },
  // { value: "Night Drive - 0.00 €", label: "Night Drive - N/A" },
  { value: "Photochromic Grey - 125.00 €", label: "Photochromic Grey - 125.00 €" },
  { value: "Photochromic Brown - 125.00 €", label: "Photochromic Brown - 125.00 €" },
  { value: "Photochromic G15 - 125.00 €", label: "Photochromic G15 - 125.00 €" },
  { value: "Photochromic Blue - 125.00 €", label: "Photochromic Blue - 125.00 €" },
  { value: "Nupolar Grey - 75.00 €", label: "Nupolar Grey - 75.00 €" },
  { value: "Nupolar Brown - 75.00 €", label: "Nupolar Brown - 75.00 €" },
  { value: "Nupolar G15 - 75.00 €", label: "Nupolar G15 - 75.00 €" },
  { value: "Nupolar Y30 - 75.00 €", label: "Nupolar Y30 - 75.00 €" },
  { value: "Nupolar Blue - 75.00 €", label: "Nupolar Blue - 75.00 €" },
  // { value: "Transition 7 Brown - 250.00 €", label: "Transition 7 Brown - 250.00 €" },
  { value: "Transition 8 Grey - 250.00 €", label: "Transition 8 Grey - 250.00 €" },
  { value: "Transition 8 Brown - 250.00 €", label: "Transition 8 Brown - 250.00 €" },
  { value: "Transition 8 Green - 250.00 €", label: "Transition 8 Green - 250.00 €" },
  { value: "Transition Vantage Grey - 250.00 €", label: "Transition Vantage Grey - 250.00 €" },
  { value: "Transition XTRA Grey - 250.00 €", label: "Transition XTRA Grey - 250.00 €" },
  { value: "Blue Mass - 50.00 €", label: "Blue Mass - 50.00 €" },
  {value: "Photochromic Grey Blue Mass - 175.00 €", label: "Photochromic Grey Blue Mass - 175.00 €" },
  {value: "Photochromic Brown Blue Mass - 175.00 €", label: "Photochromic Brown Blue Mass - 175.00 €" },
];
export const LensTypeDetailsB34 = [
  { value: "", label: "" },
  // { value: "Night Drive - 0.00 €", label: "Night Drive - N/A" },
  { value: "Photochromic Grey - 150.00 €", label: "Photochromic Grey - 150.00 €" },
  { value: "Photochromic Brown - 150.00 €", label: "Photochromic Brown - 150.00 €" },
  { value: "Photochromic G15 - 150.00 €", label: "Photochromic G15 - 150.00 €" },
  { value: "Nupolar Grey - 100.00 €", label: "Nupolar Grey - 100.00 €" },
  { value: "Nupolar Brown - 100.00 €", label: "Nupolar Brown - 100.00 €" },
  { value: "Nupolar G15 - 100.00 €", label: "Nupolar G15 - 100.00 €" },
  { value: "Nupolar Y30 - 100.00 €", label: "Nupolar Y30 - 100.00 €" },
  { value: "Nupolar Blue - 100.00 €", label: "Nupolar Blue - 100.00 €" },
  // { value: "Transition 7 Brown - 300.00 €", label: "Transition 7 Brown - 300.00 €" },
  { value: "Transition 8 Grey - 300.00 €", label: "Transition 8 Grey - 300.00 €" },
  { value: "Transition 8 Brown - 300.00 €", label: "Transition 8 Brown - 300.00 €" },
  { value: "Transition 8 Green - 300.00 €", label: "Transition 8 Green - 300.00 €" },
  { value: "Transition Vantage Grey - 300.00 €", label: "Transition Vantage Grey - 300.00 €" },
  { value: "Transition XTRA Grey - 300.00 €", label: "Transition XTRA Grey - 300.00 €" },
  { value: "Blue Mass - 50.00 €", label: "Blue Mass - 50.00 €" },
  {value: "Photochromic Grey Blue Mass - 200.00 €", label: "Photochromic Grey Blue Mass - 200.00 €" },
  {value: "Photochromic Brown Blue Mass - 200.00 €", label: "Photochromic Brown Blue Mass - 200.00 €" },
];
////////////////////////////////////////////////
export const Typedeteinte = [
  { value: "", label: "" },
  { value: "Solid", label: "Solid" },
  { value: "GradientColor", label: "GradientColor" },
];

//////////////////////Monture//////////////////////
export const MontureA1 = [
  { value: "", label: "" },
  { value: "Autre - 30.00 €", label: "Autre - 30.00 €" },
  { value: "62582 C2 - 30.00 €", label: "62582 C2 - 30.00 €" },
  { value: "62643 C2-5 - 30.00 €", label: "62643 C2-5 - 30.00 €" },
  { value: "62720 C2 - 30.00 €", label: "62720 C2 - 30.00 €" },
  { value: "62722 C3 - 30.00 €", label: "62722 C3 - 30.00 €" },
  { value: "62725 C3 - 30.00 €", label: "62725 C3 - 30.00 €" },
  { value: "62733 C1 - 30.00 €", label: "62733 C1 - 30.00 €" },
  { value: "62946 C2-4 - 30.00 €", label: "62946 C2-4 - 30.00 €" },
  { value: "B 6109 C2 - 30.00 €", label: "B 6109 C2 - 30.00 €" },
  { value: "BT 0012 C2 - 30.00 €", label: "BT 0012 C2 - 30.00 €" },
  { value: "BT 0012 C4 - 30.00 €", label: "BT 0012 C4 - 30.00 €" },
  { value: "BT 0012 C6 - 30.00 €", label: "BT 0012 C6 - 30.00 €" },
  { value: "BT 0012 C8 - 30.00 €", label: "BT 0012 C8 - 30.00 €" },
  { value: "BT 0014 C2 - 30.00 €", label: "BT 0014 C2 - 30.00 €" },
  { value: "BT 0014 C6 - 30.00 €", label: "BT 0014 C6 - 30.00 €" },
  { value: "BT 0014 C8 - 30.00 €", label: "BT 0014 C8 - 30.00 €" },
  { value: "BT 0025 C3B - 30.00 €", label: "BT 0025 C3B - 30.00 €" },
  { value: "CG 014 C1 - 30.00 €", label: "CG 014 C1 - 30.00 €" },
  { value: "CG 014 C2 - 30.00 €", label: "CG 014 C2 - 30.00 €" },
  { value: "CG 014 C3 - 30.00 €", label: "CG 014 C3 - 30.00 €" },
  { value: "CG 014 C4 - 30.00 €", label: "CG 014 C4 - 30.00 €" },
  { value: "CG 014 C5 - 30.00 €", label: "CG 014 C5 - 30.00 €" },
  { value: "CG 016 C2 - 30.00 €", label: "CG 016 C2 - 30.00 €" },
  { value: "CG 016 C3 - 30.00 €", label: "CG 016 C3 - 30.00 €" },
  { value: "CG 017 C2 - 30.00 €", label: "CG 017 C2 - 30.00 €" },
  { value: "CG 017 C3 - 30.00 €", label: "CG 017 C3 - 30.00 €" },
  { value: "CG 019 C1 - 30.00 €", label: "CG 019 C1 - 30.00 €" },
  { value: "CG 019 C2 - 30.00 €", label: "CG 019 C2 - 30.00 €" },
  { value: "CG 019 C3 - 30.00 €", label: "CG 019 C3 - 30.00 €" },
  { value: "CG 020 C2 - 30.00 €", label: "CG 020 C2 - 30.00 €" },
  { value: "CG 021 C2 - 30.00 €", label: "CG 021 C2 - 30.00 €" },
  { value: "CG 021 C3 - 30.00 €", label: "CG 021 C3 - 30.00 €" },
  { value: "CG 024 C1 - 30.00 €", label: "CG 024 C1 - 30.00 €" },
  { value: "CG 024 C3 - 30.00 €", label: "CG 024 C3 - 30.00 €" },
  { value: "IN-2207 N01 - 30.00 €", label: "IN-2207 N01 - 30.00 €" },
  { value: "IN-2207 N02 - 30.00 €", label: "IN-2207 N02 - 30.00 €" },
  { value: "IN-2208 N03 - 30.00 €", label: "IN-2208 N03 - 30.00 €" },
  { value: "IN-2209 C11 - 30.00 €", label: "IN-2209 C11 - 30.00 €" },
  { value: "IN-2210 N02 - 30.00 €", label: "IN-2210 N02 - 30.00 €" },
  { value: "IN-2210 N03 - 30.00 €", label: "IN-2210 N03 - 30.00 €" },
  { value: "IN-2212 N03 - 30.00 €", label: "IN-2212 N03 - 30.00 €" },
  { value: "IN-2214 N03 - 30.00 €", label: "IN-2214 N03 - 30.00 €" },
  { value: "IN-2216 N01 - 30.00 €", label: "IN-2216 N01 - 30.00 €" },
  { value: "IN-2216 N02 - 30.00 €", label: "IN-2216 N02 - 30.00 €" },
  { value: "IN-2219 N01 - 30.00 €", label: "IN-2219 N01 - 30.00 €" },
  { value: "IN-2219 N02 - 30.00 €", label: "IN-2219 N02 - 30.00 €" },
  { value: "IN-2220 N02 - 30.00 €", label: "IN-2220 N02 - 30.00 €" },
  { value: "SYM A 01 006 - 30.00 €", label: "SYM A 01 006 - 30.00 €" },
  { value: "SYM A 01 009 - 30.00 €", label: "SYM A 01 009 - 30.00 €" },
  { value: "SYM A 01 011 - 30.00 €", label: "SYM A 01 011 - 30.00 €" },
  { value: "SYM A 01 224 - 30.00 €", label: "SYM A 01 224 - 30.00 €" },
  { value: "SYM A 01 236 - 30.00 €", label: "SYM A 01 236 - 30.00 €" },
  { value: "SYM A 02 001 - 30.00 €", label: "SYM A 02 001 - 30.00 €" },
  { value: "SYM A 02 002 - 30.00 €", label: "SYM A 02 002 - 30.00 €" },
  { value: "SYM A 02 013 - 30.00 €", label: "SYM A 02 013 - 30.00 €" },
  { value: "SYM A 03 003 - 30.00 €", label: "SYM A 03 003 - 30.00 €" },
  { value: "SYM A 03 008 - 30.00 €", label: "SYM A 03 008 - 30.00 €" },
  { value: "SYM A 03 011 - 30.00 €", label: "SYM A 03 011 - 30.00 €" },
  { value: "SYM A 03 013 - 30.00 €", label: "SYM A 03 013 - 30.00 €" },
  { value: "SYM A 03 016 - 30.00 €", label: "SYM A 03 016 - 30.00 €" },
  { value: "SYM A 04 004 - 30.00 €", label: "SYM A 04 004 - 30.00 €" },
  { value: "SYM A 04 005 - 30.00 €", label: "SYM A 04 005 - 30.00 €" },
  { value: "SYM A 04 014 - 30.00 €", label: "SYM A 04 014 - 30.00 €" },
  { value: "SYM A 04 257 - 30.00 €", label: "SYM A 04 257 - 30.00 €" },
  { value: "SYM A 05 001 - 30.00 €", label: "SYM A 05 001 - 30.00 €" },
  { value: "SYM A 05 010 - 30.00 €", label: "SYM A 05 010 - 30.00 €" },
  { value: "SYM A 05 011 - 30.00 €", label: "SYM A 05 011 - 30.00 €" },
  { value: "SYM A 05 013 - 30.00 €", label: "SYM A 05 013 - 30.00 €" },
  { value: "SYM A 06 004 - 30.00 €", label: "SYM A 06 004 - 30.00 €" },
  { value: "SYM A 06 008 - 30.00 €", label: "SYM A 06 008 - 30.00 €" },
  { value: "SYM A 06 012 - 30.00 €", label: "SYM A 06 012 - 30.00 €" },
  { value: "SYM A 06 014 - 30.00 €", label: "SYM A 06 014 - 30.00 €" },
  { value: "SYM A 06 015 - 30.00 €", label: "SYM A 06 015 - 30.00 €" },
  { value: "SYM A 07 217 - 30.00 €", label: "SYM A 07 217 - 30.00 €" },
  { value: "SYM A 07 218 - 30.00 €", label: "SYM A 07 218 - 30.00 €" },
  { value: "SYM A 07 219 - 30.00 €", label: "SYM A 07 219 - 30.00 €" },
  { value: "SYM A 07 220 - 30.00 €", label: "SYM A 07 220 - 30.00 €" },
  { value: "SYM A 07 245 - 30.00 €", label: "SYM A 07 245 - 30.00 €" },
  { value: "SYM A 08 245 - 30.00 €", label: "SYM A 08 245 - 30.00 €" },
  { value: "SYM B 01 224 - 30.00 €", label: "SYM B 01 224 - 30.00 €" },
  { value: "SYM B 01 225 - 30.00 €", label: "SYM B 01 225 - 30.00 €" },
  { value: "SYM B 01 245 - 30.00 €", label: "SYM B 01 245 - 30.00 €" },
  { value: "SYM B 02 001 - 30.00 €", label: "SYM B 02 001 - 30.00 €" },
  { value: "SYM B 02 013 - 30.00 €", label: "SYM B 02 013 - 30.00 €" },
  { value: "SYM B 02 226 - 30.00 €", label: "SYM B 02 226 - 30.00 €" },
  { value: "SYM B 03 014 - 30.00 €", label: "SYM B 03 014 - 30.00 €" },
  { value: "SYM B 03 227 - 30.00 €", label: "SYM B 03 227 - 30.00 €" },
  { value: "SYM B 03 228 - 30.00 €", label: "SYM B 03 228 - 30.00 €" },
  { value: "SYM B 04 014 - 30.00 €", label: "SYM B 04 014 - 30.00 €" },
  { value: "SYM B 04 230 - 30.00 €", label: "SYM B 04 230 - 30.00 €" },
  { value: "SYM B 04 231 - 30.00 €", label: "SYM B 04 231 - 30.00 €" },
  { value: "SYM B 04 257 - 30.00 €", label: "SYM B 04 257 - 30.00 €" },
  { value: "SYM B 05 232 - 30.00 €", label: "SYM B 05 232 - 30.00 €" },
  { value: "SYM B 05 233 - 30.00 €", label: "SYM B 05 233 - 30.00 €" },
  { value: "SYM B 05 234 - 30.00 €", label: "SYM B 05 234 - 30.00 €" },
  { value: "SYM B 06 004 - 30.00 €", label: "SYM B 06 004 - 30.00 €" },
  { value: "SYM B 06 014 - 30.00 €", label: "SYM B 06 014 - 30.00 €" },
  { value: "SYM B 06 015 - 30.00 €", label: "SYM B 06 015 - 30.00 €" },
  { value: "SYM B 06 235 - 30.00 €", label: "SYM B 06 235 - 30.00 €" },
  { value: "SYM C 01 236 - 30.00 €", label: "SYM C 01 236 - 30.00 €" },
  { value: "SYM C 01 237 - 30.00 €", label: "SYM C 01 237 - 30.00 €" },
  { value: "SYM C 01 238 - 30.00 €", label: "SYM C 01 238 - 30.00 €" },
  { value: "SYM C 01 239 - 30.00 €", label: "SYM C 01 239 - 30.00 €" },
  { value: "SYM C 01 245 - 30.00 €", label: "SYM C 01 245 - 30.00 €" },
  { value: "SYM C 02 025 - 30.00 €", label: "SYM C 02 025 - 30.00 €" },
  { value: "SYM C 02 233 - 30.00 €", label: "SYM C 02 233 - 30.00 €" },
  { value: "SYM C 02 246 - 30.00 €", label: "SYM C 02 246 - 30.00 €" },
  { value: "SYM C 02 247 - 30.00 €", label: "SYM C 02 247 - 30.00 €" },
  { value: "SYM C 02 257 - 30.00 €", label: "SYM C 02 257 - 30.00 €" },
  { value: "SYM C 03 016 - 30.00 €", label: "SYM C 03 016 - 30.00 €" },
  { value: "SYM C 03 140 - 30.00 €", label: "SYM C 03 140 - 30.00 €" },
  { value: "SYM C 03 240 - 30.00 €", label: "SYM C 03 240 - 30.00 €" },
  { value: "SYM C 03 248 - 30.00 €", label: "SYM C 03 248 - 30.00 €" },
  { value: "SYM C 04 014 - 30.00 €", label: "SYM C 04 014 - 30.00 €" },
  { value: "SYM C 04 224 - 30.00 €", label: "SYM C 04 224 - 30.00 €" },
  { value: "SYM C 04 228 - 30.00 €", label: "SYM C 04 228 - 30.00 €" },
  { value: "SYM C 04 230 - 30.00 €", label: "SYM C 04 230 - 30.00 €" },
  { value: "SYM C 04 241 - 30.00 €", label: "SYM C 04 241 - 30.00 €" },
  { value: "SYM C 05 242 - 30.00 €", label: "SYM C 05 242 - 30.00 €" },
  { value: "SYM C 05 243 - 30.00 €", label: "SYM C 05 243 - 30.00 €" },
  { value: "SYM C 05 244 - 30.00 €", label: "SYM C 05 244 - 30.00 €" },
  { value: "SYM K 01 151 - 30.00 €", label: "SYM K 01 151 - 30.00 €" },
  { value: "SYM K 01 252 - 30.00 €", label: "SYM K 01 252 - 30.00 €" },
  { value: "SYM K 01 253 - 30.00 €", label: "SYM K 01 253 - 30.00 €" },
  { value: "SYM K 01 254 - 30.00 €", label: "SYM K 01 254 - 30.00 €" },
  { value: "SYM K 01 255 - 30.00 €", label: "SYM K 01 255 - 30.00 €" },
  { value: "SYM K 02 251 - 30.00 €", label: "SYM K 02 251 - 30.00 €" },
  { value: "SYM K 02 252 - 30.00 €", label: "SYM K 02 252 - 30.00 €" },
  { value: "SYM K 02 253 - 30.00 €", label: "SYM K 02 253 - 30.00 €" },
  { value: "SYM K 02 254 - 30.00 €", label: "SYM K 02 254 - 30.00 €" },
  { value: "SYM K 02 255 - 30.00 €", label: "SYM K 02 255 - 30.00 €" },
  { value: "SYM K 03 251 - 30.00 €", label: "SYM K 03 251 - 30.00 €" },
  { value: "SYM K 03 252 - 30.00 €", label: "SYM K 03 252 - 30.00 €" },
  { value: "SYM K 03 253 - 30.00 €", label: "SYM K 03 253 - 30.00 €" },
  { value: "SYM K 03 254 - 30.00 €", label: "SYM K 03 254 - 30.00 €" },
  { value: "SYM K 03 255 - 30.00 €", label: "SYM K 03 255 - 30.00 €" },
  { value: "SYM K 04 251 - 30.00 €", label: "SYM K 04 251 - 30.00 €" },
  { value: "SYM K 04 252 - 30.00 €", label: "SYM K 04 252 - 30.00 €" },
  { value: "SYM K 04 253 - 30.00 €", label: "SYM K 04 253 - 30.00 €" },
  { value: "SYM K 04 254 - 30.00 €", label: "SYM K 04 254 - 30.00 €" },
  { value: "SYM K 04 255 - 30.00 €", label: "SYM K 04 255 - 30.00 €" },
  { value: "YC 1091 C1 - 30.00 €", label: "YC 1091 C1 - 30.00 €" },
  { value: "YC 1091 C4 - 30.00 €", label: "YC 1091 C4 - 30.00 €" },
  { value: "YC 11022 C2 - 30.00 €", label: "YC 11022 C2 - 30.00 €" },
  { value: "YC 11022 C3 - 30.00 €", label: "YC 11022 C3 - 30.00 €" },
  { value: "YC 11023 C4 - 30.00 €", label: "YC 11023 C4 - 30.00 €" },
  { value: "YC 11024 C3 - 30.00 €", label: "YC 11024 C3 - 30.00 €" },
  { value: "YC 11025 C4 - 30.00 €", label: "YC 11025 C4 - 30.00 €" },
  { value: "YC 11028 C2 - 30.00 €", label: "YC 11028 C2 - 30.00 €" },
  { value: "YC 11029 C3 - 30.00 €", label: "YC 11029 C3 - 30.00 €" },
  { value: "YC 11029 C4 - 30.00 €", label: "YC 11029 C4 - 30.00 €" },
  { value: "YC 11030 C2 - 30.00 €", label: "YC 11030 C2 - 30.00 €" },
  { value: "YC 11030 C3 - 30.00 €", label: "YC 11030 C3 - 30.00 €" },
  { value: "YC 11030 C4 - 30.00 €", label: "YC 11030 C4 - 30.00 €" },
  { value: "YC 11031 C2 - 30.00 €", label: "YC 11031 C2 - 30.00 €" },
  { value: "YC 11032 C4 - 30.00 €", label: "YC 11032 C4 - 30.00 €" },
  { value: "YC 11037 C4 - 30.00 €", label: "YC 11037 C4 - 30.00 €" },
  { value: "YC 11040 C4 - 30.00 €", label: "YC 11040 C4 - 30.00 €" },
  { value: "YC 11052 C2 - 30.00 €", label: "YC 11052 C2 - 30.00 €" },
  { value: "YC 11063 C4 - 30.00 €", label: "YC 11063 C4 - 30.00 €" },
  { value: "YC 11064 C2 - 30.00 €", label: "YC 11064 C2 - 30.00 €" },
  { value: "YC 11068 C4 - 30.00 €", label: "YC 11068 C4 - 30.00 €" },
  { value: "YC 12003 C1 - 30.00 €", label: "YC 12003 C1 - 30.00 €" },
  { value: "YC 12003 C2 - 30.00 €", label: "YC 12003 C2 - 30.00 €" },
  { value: "YC 12003 C3 - 30.00 €", label: "YC 12003 C3 - 30.00 €" },
  { value: "YC 12003 C4 - 30.00 €", label: "YC 12003 C4 - 30.00 €" },
  { value: "YC 13001 C2 - 30.00 €", label: "YC 13001 C2 - 30.00 €" },
  { value: "YC 13002 C2 - 30.00 €", label: "YC 13002 C2 - 30.00 €" },
  { value: "YC 13002 C3 - 30.00 €", label: "YC 13002 C3 - 30.00 €" },
  { value: "YC 13003 C1 - 30.00 €", label: "YC 13003 C1 - 30.00 €" },
  { value: "YC 14044 C3 - 30.00 €", label: "YC 14044 C3 - 30.00 €" },
  { value: "YC 14045 C2 - 30.00 €", label: "YC 14045 C2 - 30.00 €" },
  { value: "YC 14047 C1 - 30.00 €", label: "YC 14047 C1 - 30.00 €" },
  { value: "YC 14047 C4 - 30.00 €", label: "YC 14047 C4 - 30.00 €" },
  { value: "YC 14047 C7 - 30.00 €", label: "YC 14047 C7 - 30.00 €" },
  { value: "YC 14048 C1 - 30.00 €", label: "YC 14048 C1 - 30.00 €" },
  { value: "YC 14048 C2 - 30.00 €", label: "YC 14048 C2 - 30.00 €" },
  { value: "YC 15035 C2 - 30.00 €", label: "YC 15035 C2 - 30.00 €" },
  { value: "YC 15085 C2 - 30.00 €", label: "YC 15085 C2 - 30.00 €" },
  { value: "YC 15085 C4 - 30.00 €", label: "YC 15085 C4 - 30.00 €" },
  { value: "YC 15086 C4 - 30.00 €", label: "YC 15086 C4 - 30.00 €" },
  { value: "YC 21001 C1 - 30.00 €", label: "YC 21001 C1 - 30.00 €" },
  { value: "YC 21001 C2 - 30.00 €", label: "YC 21001 C2 - 30.00 €" },
  { value: "YC 21001 C3 - 30.00 €", label: "YC 21001 C3 - 30.00 €" },
  { value: "YC 21001 C4 - 30.00 €", label: "YC 21001 C4 - 30.00 €" },
  { value: "YC 21002 C3 - 30.00 €", label: "YC 21002 C3 - 30.00 €" },
  { value: "YC 21007 C4 - 30.00 €", label: "YC 21007 C4 - 30.00 €" },
  { value: "YC 21009 C1 - 30.00 €", label: "YC 21009 C1 - 30.00 €" },
  { value: "YC 21012 C1 - 30.00 €", label: "YC 21012 C1 - 30.00 €" },
  { value: "YC 21012 C3 - 30.00 €", label: "YC 21012 C3 - 30.00 €" },
  { value: "YC 21012 C4 - 30.00 €", label: "YC 21012 C4 - 30.00 €" },
  { value: "YC 21013 C1 - 30.00 €", label: "YC 21013 C1 - 30.00 €" },
  { value: "YC 21013 C2 - 30.00 €", label: "YC 21013 C2 - 30.00 €" },
  { value: "YC 21013 C3 - 30.00 €", label: "YC 21013 C3 - 30.00 €" },
  { value: "YC 21013 C4 - 30.00 €", label: "YC 21013 C4 - 30.00 €" },
  { value: "YC 21014 C2 - 30.00 €", label: "YC 21014 C2 - 30.00 €" },
  { value: "YC 21014 C3 - 30.00 €", label: "YC 21014 C3 - 30.00 €" },
  { value: "YC 21015 C2 - 30.00 €", label: "YC 21015 C2 - 30.00 €" },
  { value: "YC 21026 C4 - 30.00 €", label: "YC 21026 C4 - 30.00 €" },
  { value: "YC 21031 C1 - 30.00 €", label: "YC 21031 C1 - 30.00 €" },
  { value: "YC 21031 C4 - 30.00 €", label: "YC 21031 C4 - 30.00 €" },
  { value: "YC 21034 C1 - 30.00 €", label: "YC 21034 C1 - 30.00 €" },
  { value: "YC 21034 C3 - 30.00 €", label: "YC 21034 C3 - 30.00 €" },
  { value: "YC 21034 C4 - 30.00 €", label: "YC 21034 C4 - 30.00 €" },
  { value: "YC 21036 C1 - 30.00 €", label: "YC 21036 C1 - 30.00 €" },
  { value: "YC 21038 C2 - 30.00 €", label: "YC 21038 C2 - 30.00 €" },
  { value: "YC 21039 C4 - 30.00 €", label: "YC 21039 C4 - 30.00 €" },
  { value: "YC 21061 C4 - 30.00 €", label: "YC 21061 C4 - 30.00 €" },
  { value: "YC 21077 C1 - 30.00 €", label: "YC 21077 C1 - 30.00 €" },
  { value: "YC 21086 C3 - 30.00 €", label: "YC 21086 C3 - 30.00 €" },
  { value: "YC 21093 C2 - 30.00 €", label: "YC 21093 C2 - 30.00 €" },
  { value: "YC 21105 C2 - 30.00 €", label: "YC 21105 C2 - 30.00 €" },
  { value: "YC 21105 C3 - 30.00 €", label: "YC 21105 C3 - 30.00 €" },
  { value: "YC 21122 C4 - 30.00 €", label: "YC 21122 C4 - 30.00 €" },
  { value: "YC 21131 C3 - 30.00 €", label: "YC 21131 C3 - 30.00 €" },
  { value: "YC 21144 C3 - 30.00 €", label: "YC 21144 C3 - 30.00 €" },
  { value: "YC 21144 C4 - 30.00 €", label: "YC 21144 C4 - 30.00 €" },
  { value: "YC 21145 C3 - 30.00 €", label: "YC 21145 C3 - 30.00 €" },
  { value: "YC 21145 C4 - 30.00 €", label: "YC 21145 C4 - 30.00 €" },
  { value: "YC 21146 C1 - 30.00 €", label: "YC 21146 C1 - 30.00 €" },
  { value: "YC 21147 C1 - 30.00 €", label: "YC 21147 C1 - 30.00 €" },
  { value: "YC 21148 C3 - 30.00 €", label: "YC 21148 C3 - 30.00 €" },
  { value: "YC 21148 C4 - 30.00 €", label: "YC 21148 C4 - 30.00 €" },
  { value: "YC 21149 C1 - 30.00 €", label: "YC 21149 C1 - 30.00 €" },
  { value: "YC 21151 C4 - 30.00 €", label: "YC 21151 C4 - 30.00 €" },
  { value: "YC 21155 C2 - 30.00 €", label: "YC 21155 C2 - 30.00 €" },
  { value: "YC 21155 C3 - 30.00 €", label: "YC 21155 C3 - 30.00 €" },
  { value: "YC 21156 C2 - 30.00 €", label: "YC 21156 C2 - 30.00 €" },
  { value: "YC 21158 C1 - 30.00 €", label: "YC 21158 C1 - 30.00 €" },
  { value: "YC 21158 C3 - 30.00 €", label: "YC 21158 C3 - 30.00 €" },
  { value: "YC 21158 C4 - 30.00 €", label: "YC 21158 C4 - 30.00 €" },
  { value: "YC 21159 C4 - 30.00 €", label: "YC 21159 C4 - 30.00 €" },
  { value: "YC 21161 C3 - 30.00 €", label: "YC 21161 C3 - 30.00 €" },
  { value: "YC 21163 C2 - 30.00 €", label: "YC 21163 C2 - 30.00 €" },
  { value: "YC 21163 C3 - 30.00 €", label: "YC 21163 C3 - 30.00 €" },
  { value: "YC 21166 C2 - 30.00 €", label: "YC 21166 C2 - 30.00 €" },
  { value: "YC 21184 C4 - 30.00 €", label: "YC 21184 C4 - 30.00 €" },
  { value: "YC 2171 C1 - 30.00 €", label: "YC 2171 C1 - 30.00 €" },
  { value: "YC 22002 C1 - 30.00 €", label: "YC 22002 C1 - 30.00 €" },
  { value: "YC 22002 C4 - 30.00 €", label: "YC 22002 C4 - 30.00 €" },
  { value: "YC 22031 C2 - 30.00 €", label: "YC 22031 C2 - 30.00 €" },
  { value: "YC 22036 C4 - 30.00 €", label: "YC 22036 C4 - 30.00 €" },
  { value: "YC 22041 C4 - 30.00 €", label: "YC 22041 C4 - 30.00 €" },
  { value: "YC 22043 C3 - 30.00 €", label: "YC 22043 C3 - 30.00 €" },
  { value: "YC 22050 C4 - 30.00 €", label: "YC 22050 C4 - 30.00 €" },
  { value: "YC 22057 C1 - 30.00 €", label: "YC 22057 C1 - 30.00 €" },
  { value: "YC 22057 C4 - 30.00 €", label: "YC 22057 C4 - 30.00 €" },
  { value: "YC 22062 C3 - 30.00 €", label: "YC 22062 C3 - 30.00 €" },
  { value: "YC 22081 C1 - 30.00 €", label: "YC 22081 C1 - 30.00 €" },
  { value: "YC 22082 C3 - 30.00 €", label: "YC 22082 C3 - 30.00 €" },
  { value: "YC 22082 C4 - 30.00 €", label: "YC 22082 C4 - 30.00 €" },
  { value: "YC 22095 C2 - 30.00 €", label: "YC 22095 C2 - 30.00 €" },
  { value: "YC 22095 C3 - 30.00 €", label: "YC 22095 C3 - 30.00 €" },
  { value: "YC 22101 C1 - 30.00 €", label: "YC 22101 C1 - 30.00 €" },
  { value: "YC 22101 C3 - 30.00 €", label: "YC 22101 C3 - 30.00 €" },
  { value: "YC 22102 C4 - 30.00 €", label: "YC 22102 C4 - 30.00 €" },
  { value: "YC 22122 C4 - 30.00 €", label: "YC 22122 C4 - 30.00 €" },
  { value: "YC 23043 C2 - 30.00 €", label: "YC 23043 C2 - 30.00 €" },
  { value: "YC 23043 C3 - 30.00 €", label: "YC 23043 C3 - 30.00 €" },
  { value: "YC 23052 C4 - 30.00 €", label: "YC 23052 C4 - 30.00 €" },
  { value: "YC 23055 C1 - 30.00 €", label: "YC 23055 C1 - 30.00 €" },
  { value: "YC 23055 C2 - 30.00 €", label: "YC 23055 C2 - 30.00 €" },
  { value: "YC 23057 C3 - 30.00 €", label: "YC 23057 C3 - 30.00 €" },
  { value: "YC 23057 C4 - 30.00 €", label: "YC 23057 C4 - 30.00 €" },
  { value: "YC 23061 C4 - 30.00 €", label: "YC 23061 C4 - 30.00 €" },
  { value: "YC 23087 C4 - 30.00 €", label: "YC 23087 C4 - 30.00 €" },
  { value: "YC 23112 C1 - 30.00 €", label: "YC 23112 C1 - 30.00 €" },
  { value: "YC 23112 C2 - 30.00 €", label: "YC 23112 C2 - 30.00 €" },
  { value: "YC 23121 C1 - 30.00 €", label: "YC 23121 C1 - 30.00 €" },
  { value: "YC 23121 C3 - 30.00 €", label: "YC 23121 C3 - 30.00 €" },
  { value: "YC 23125 C1 - 30.00 €", label: "YC 23125 C1 - 30.00 €" },
  { value: "YC 23125 C2 - 30.00 €", label: "YC 23125 C2 - 30.00 €" },
  { value: "YC 23125 C3 - 30.00 €", label: "YC 23125 C3 - 30.00 €" },
  { value: "YC 23139 C1 - 30.00 €", label: "YC 23139 C1 - 30.00 €" },
  { value: "YC 23139 C4 - 30.00 €", label: "YC 23139 C4 - 30.00 €" },
  { value: "YC 23142 C1 - 30.00 €", label: "YC 23142 C1 - 30.00 €" },
  { value: "YC 23142 C2 - 30.00 €", label: "YC 23142 C2 - 30.00 €" },
  { value: "YC 23142 C3 - 30.00 €", label: "YC 23142 C3 - 30.00 €" },
  { value: "YC 23153 C3 - 30.00 €", label: "YC 23153 C3 - 30.00 €" },
  { value: "YC 23168 C2 - 30.00 €", label: "YC 23168 C2 - 30.00 €" },
  { value: "YC 23168 C3 - 30.00 €", label: "YC 23168 C3 - 30.00 €" },
  { value: "YC 23168 C4 - 30.00 €", label: "YC 23168 C4 - 30.00 €" },
  { value: "YC 23170 C1 - 30.00 €", label: "YC 23170 C1 - 30.00 €" },
  { value: "YC 23170 C3 - 30.00 €", label: "YC 23170 C3 - 30.00 €" },
  { value: "YC 23171 C1 - 30.00 €", label: "YC 23171 C1 - 30.00 €" },
  { value: "YC 23171 C3 - 30.00 €", label: "YC 23171 C3 - 30.00 €" },
  { value: "YC 23171 C4 - 30.00 €", label: "YC 23171 C4 - 30.00 €" },
  { value: "YC 23174 C4 - 30.00 €", label: "YC 23174 C4 - 30.00 €" },
  { value: "YC 25008 C2 - 30.00 €", label: "YC 25008 C2 - 30.00 €" },
  { value: "YC 26007 C4 - 30.00 €", label: "YC 26007 C4 - 30.00 €" },
  { value: "YC 26008 C1 - 30.00 €", label: "YC 26008 C1 - 30.00 €" },
  { value: "YC 26008 C2 - 30.00 €", label: "YC 26008 C2 - 30.00 €" },
  { value: "YC 26008 C4 - 30.00 €", label: "YC 26008 C4 - 30.00 €" },
  { value: "YC 26010 C1 - 30.00 €", label: "YC 26010 C1 - 30.00 €" },
  { value: "YC 26010 C2 - 30.00 €", label: "YC 26010 C2 - 30.00 €" },
  { value: "YC 26012 C2 - 30.00 €", label: "YC 26012 C2 - 30.00 €" },
  { value: "YC 26019 C1 - 30.00 €", label: "YC 26019 C1 - 30.00 €" },
  { value: "YC 26027 C2 - 30.00 €", label: "YC 26027 C2 - 30.00 €" },
  { value: "YC 26028 C1 - 30.00 €", label: "YC 26028 C1 - 30.00 €" },
  { value: "YC 26028 C3 - 30.00 €", label: "YC 26028 C3 - 30.00 €" },
  { value: "YC 26032 C1 - 30.00 €", label: "YC 26032 C1 - 30.00 €" },
  { value: "YC 26042 C2 - 30.00 €", label: "YC 26042 C2 - 30.00 €" },
  { value: "YC 26042 C4 - 30.00 €", label: "YC 26042 C4 - 30.00 €" },
  { value: "YC 26048 C2 - 30.00 €", label: "YC 26048 C2 - 30.00 €" },
  { value: "YC 26048 C3 - 30.00 €", label: "YC 26048 C3 - 30.00 €" },
  { value: "YC 26048 C4 - 30.00 €", label: "YC 26048 C4 - 30.00 €" },
  { value: "YC 26049 C2 - 30.00 €", label: "YC 26049 C2 - 30.00 €" },
  { value: "YC 26049 C4 - 30.00 €", label: "YC 26049 C4 - 30.00 €" },
  { value: "YC 28004 C1 - 30.00 €", label: "YC 28004 C1 - 30.00 €" },
  { value: "YC 28018 C1 - 30.00 €", label: "YC 28018 C1 - 30.00 €" },
  { value: "YC 28022 C1 - 30.00 €", label: "YC 28022 C1 - 30.00 €" },
  { value: "YC 28027 C2 - 30.00 €", label: "YC 28027 C2 - 30.00 €" },
  { value: "YC 28032 C3 - 30.00 €", label: "YC 28032 C3 - 30.00 €" },
  { value: "YC 28034 C4 - 30.00 €", label: "YC 28034 C4 - 30.00 €" },
  { value: "YC 28039 C3 - 30.00 €", label: "YC 28039 C3 - 30.00 €" },
  { value: "YC 28052 C1 - 30.00 €", label: "YC 28052 C1 - 30.00 €" },
  { value: "YC 28052 C2 - 30.00 €", label: "YC 28052 C2 - 30.00 €" },
  { value: "YC 28055 C3 - 30.00 €", label: "YC 28055 C3 - 30.00 €" },
  { value: "YC 29001 C2 - 30.00 €", label: "YC 29001 C2 - 30.00 €" },
  { value: "YC 29001 C3 - 30.00 €", label: "YC 29001 C3 - 30.00 €" },
  { value: "YC 29002 C1 - 30.00 €", label: "YC 29002 C1 - 30.00 €" },
  { value: "YC 29003 C1 - 30.00 €", label: "YC 29003 C1 - 30.00 €" },
  { value: "YC 29003 C4 - 30.00 €", label: "YC 29003 C4 - 30.00 €" },
  { value: "YC 29005 C2 - 30.00 €", label: "YC 29005 C2 - 30.00 €" },
  { value: "YC 29006 C2 - 30.00 €", label: "YC 29006 C2 - 30.00 €" },
  { value: "YC 29008 C4 - 30.00 €", label: "YC 29008 C4 - 30.00 €" },
  { value: "YC 29009 C1 - 30.00 €", label: "YC 29009 C1 - 30.00 €" },
  { value: "YC 29009 C2 - 30.00 €", label: "YC 29009 C2 - 30.00 €" },
  { value: "YC 29009 C3 - 30.00 €", label: "YC 29009 C3 - 30.00 €" },
  { value: "YC 29009 C4 - 30.00 €", label: "YC 29009 C4 - 30.00 €" },
  { value: "YC 29011 C1 - 30.00 €", label: "YC 29011 C1 - 30.00 €" },
  { value: "YC 29011 C4 - 30.00 €", label: "YC 29011 C4 - 30.00 €" },
  { value: "YC 29013 C1 - 30.00 €", label: "YC 29013 C1 - 30.00 €" },
  { value: "YC 29013 C2 - 30.00 €", label: "YC 29013 C2 - 30.00 €" },
  { value: "YC 29024 C1 - 30.00 €", label: "YC 29024 C1 - 30.00 €" },
  { value: "YC 29025 C1 - 30.00 €", label: "YC 29025 C1 - 30.00 €" },
  { value: "YC 29031 C2 - 30.00 €", label: "YC 29031 C2 - 30.00 €" },
  { value: "YC 29031 C4 - 30.00 €", label: "YC 29031 C4 - 30.00 €" },
  { value: "YC 29032 C1 - 30.00 €", label: "YC 29032 C1 - 30.00 €" },
  { value: "YC 29032 C2 - 30.00 €", label: "YC 29032 C2 - 30.00 €" },
  { value: "YC 29032 C3 - 30.00 €", label: "YC 29032 C3 - 30.00 €" },
  { value: "YC 29032 C4 - 30.00 €", label: "YC 29032 C4 - 30.00 €" },
  { value: "YC 29033 C3 - 30.00 €", label: "YC 29033 C3 - 30.00 €" },
  { value: "YC 29034 C3 - 30.00 €", label: "YC 29034 C3 - 30.00 €" },
  { value: "YC 29035 C4 - 30.00 €", label: "YC 29035 C4 - 30.00 €" },
  { value: "YC 29047 C1 - 30.00 €", label: "YC 29047 C1 - 30.00 €" },
  { value: "YC 29047 C3 - 30.00 €", label: "YC 29047 C3 - 30.00 €" },
  { value: "YC 29048 C1 - 30.00 €", label: "YC 29048 C1 - 30.00 €" },
  { value: "YC 29063 C1 - 30.00 €", label: "YC 29063 C1 - 30.00 €" },
  { value: "YC 29063 C4 - 30.00 €", label: "YC 29063 C4 - 30.00 €" },
  { value: "YC 29065 C1 - 30.00 €", label: "YC 29065 C1 - 30.00 €" },
  { value: "YC 29065 C2 - 30.00 €", label: "YC 29065 C2 - 30.00 €" },
  { value: "YC 30015 C1 - 30.00 €", label: "YC 30015 C1 - 30.00 €" },
  { value: "YC 30015 C3 - 30.00 €", label: "YC 30015 C3 - 30.00 €" },
  { value: "YC 30017 C1 - 30.00 €", label: "YC 30017 C1 - 30.00 €" },
  { value: "YC 30017 C2 - 30.00 €", label: "YC 30017 C2 - 30.00 €" },
  { value: "YC 30041 C1 - 30.00 €", label: "YC 30041 C1 - 30.00 €" },
  { value: "YC 30041 C2 - 30.00 €", label: "YC 30041 C2 - 30.00 €" },
  { value: "YC 30049 C4 - 30.00 €", label: "YC 30049 C4 - 30.00 €" },
  { value: "YC 30057 C2 - 30.00 €", label: "YC 30057 C2 - 30.00 €" },
  { value: "YC 31064 C2 - 30.00 €", label: "YC 31064 C2 - 30.00 €" },
  { value: "YC 31064 C3 - 30.00 €", label: "YC 31064 C3 - 30.00 €" },
  { value: "YC 31064 C4 - 30.00 €", label: "YC 31064 C4 - 30.00 €" },
  { value: "YC 31069 C1 - 30.00 €", label: "YC 31069 C1 - 30.00 €" },
  { value: "YC 31072 C4 - 30.00 €", label: "YC 31072 C4 - 30.00 €" },
  { value: "YC 31078 C1 - 30.00 €", label: "YC 31078 C1 - 30.00 €" },
  { value: "YC 31085 C1 - 30.00 €", label: "YC 31085 C1 - 30.00 €" },
  { value: "YC 313 C1 - 30.00 €", label: "YC 313 C1 - 30.00 €" },
  { value: "YC 313 C4 - 30.00 €", label: "YC 313 C4 - 30.00 €" },
  { value: "YC 33059 C1 - 30.00 €", label: "YC 33059 C1 - 30.00 €" },
  { value: "YC 33063 C1 - 30.00 €", label: "YC 33063 C1 - 30.00 €" },
  { value: "YC 34009 C2 - 30.00 €", label: "YC 34009 C2 - 30.00 €" },
  { value: "YC 34009 C3 - 30.00 €", label: "YC 34009 C3 - 30.00 €" },
  { value: "YC 39003 C5 - 30.00 €", label: "YC 39003 C5 - 30.00 €" },
  { value: "YC 39027 C1 - 30.00 €", label: "YC 39027 C1 - 30.00 €" },
  { value: "YC 39028 C2 - 30.00 €", label: "YC 39028 C2 - 30.00 €" },
  { value: "YC 39063 C3 - 30.00 €", label: "YC 39063 C3 - 30.00 €" },
  { value: "YC 39072 C4 - 30.00 €", label: "YC 39072 C4 - 30.00 €" },
  { value: "YC 60019 C1 - 30.00 €", label: "YC 60019 C1 - 30.00 €" },
  { value: "YC 60019 C4 - 30.00 €", label: "YC 60019 C4 - 30.00 €" },
  { value: "YC 60023 C7 - 30.00 €", label: "YC 60023 C7 - 30.00 €" },
  { value: "YC 60023 C8 - 30.00 €", label: "YC 60023 C8 - 30.00 €" },
  { value: "YC 60026 C1 - 30.00 €", label: "YC 60026 C1 - 30.00 €" },
  { value: "YC 60026 C4 - 30.00 €", label: "YC 60026 C4 - 30.00 €" },
  { value: "YC 60031 C4 - 30.00 €", label: "YC 60031 C4 - 30.00 €" },
  { value: "YC 60032 C6 - 30.00 €", label: "YC 60032 C6 - 30.00 €" },
  { value: "YC 60036 C1 - 30.00 €", label: "YC 60036 C1 - 30.00 €" },
  { value: "YC 60036 C4 - 30.00 €", label: "YC 60036 C4 - 30.00 €" },
  { value: "YC 60036 C7 - 30.00 €", label: "YC 60036 C7 - 30.00 €" },
  { value: "YC 60037 C4 - 30.00 €", label: "YC 60037 C4 - 30.00 €" },
  { value: "YC 60051 C7 - 30.00 €", label: "YC 60051 C7 - 30.00 €" },
  { value: "YC 63006 C5 - 30.00 €", label: "YC 63006 C5 - 30.00 €" },
  { value: "YC 63006 C6 - 30.00 €", label: "YC 63006 C6 - 30.00 €" },
  { value: "YC 63038 C6 - 30.00 €", label: "YC 63038 C6 - 30.00 €" },
  { value: "YC 8087 C1 - 30.00 €", label: "YC 8087 C1 - 30.00 €" },
  { value: "YC 8087 C3 - 30.00 €", label: "YC 8087 C3 - 30.00 €" },
  { value: "YC 9609 C1 - 30.00 €", label: "YC 9609 C1 - 30.00 €" },
  { value: "YC 9609 C3 - 30.00 €", label: "YC 9609 C3 - 30.00 €" },
  { value: "YC 25025 C2 - 30.00 €", label: "YC 25025 C2 - 30.00 €" },
  { value: "GC 001 C2 - 30.00 €", label: "GC 001 C2 - 30.00 €" },
  { value: "GC 001 C3 - 30.00 €", label: "GC 001 C3 - 30.00 €" },
  { value: "GC 002 C2 - 30.00 €", label: "GC 002 C2 - 30.00 €" },
  { value: "GC 002 C3 - 30.00 €", label: "GC 002 C3 - 30.00 €" },
  { value: "GC 003 C2 - 30.00 €", label: "GC 003 C2 - 30.00 €" },
  { value: "GC 003 C3 - 30.00 €", label: "GC 003 C3 - 30.00 €" },
  { value: "GC 004 C1 - 30.00 €", label: "GC 004 C1 - 30.00 €" },
  { value: "GC 004 C2 - 30.00 €", label: "GC 004 C2 - 30.00 €" },
  { value: "GC 004 C3 - 30.00 €", label: "GC 004 C3 - 30.00 €" },
  { value: "GC 005 C3 - 30.00 €", label: "GC 005 C3 - 30.00 €" },
  { value: "GC 006 C1 - 30.00 €", label: "GC 006 C1 - 30.00 €" },
  { value: "GC 006 C3 - 30.00 €", label: "GC 006 C3 - 30.00 €" },
  { value: "GC 007 C3 - 30.00 €", label: "GC 007 C3 - 30.00 €" },
  { value: "62733 C2 - 30.00 €", label: "62733 C2 - 30.00 €" },
  { value: "62733 C3 - 30.00 €", label: "62733 C3 - 30.00 €" },
  { value: "YC 11057 C2 - 30.00 €", label: "YC 11057 C2 - 30.00 €" },
];
export const MontureB1 = [
  { value: "", label: "" },
  { value: "Autre - 100.00 €", label: "Autre - 100.00 €" },
  { value: "62582 C2 - 100.00 €", label: "62582 C2 - 100.00 €" },
  { value: "62643 C2-5 - 100.00 €", label: "62643 C2-5 - 100.00 €" },
  { value: "62720 C2 - 100.00 €", label: "62720 C2 - 100.00 €" },
  { value: "62722 C3 - 100.00 €", label: "62722 C3 - 100.00 €" },
  { value: "62725 C3 - 100.00 €", label: "62725 C3 - 100.00 €" },
  { value: "62733 C1 - 100.00 €", label: "62733 C1 - 100.00 €" },
  { value: "62946 C2-4 - 100.00 €", label: "62946 C2-4 - 100.00 €" },
  { value: "B 6109 C2 - 100.00 €", label: "B 6109 C2 - 100.00 €" },
  { value: "BT 0012 C2 - 100.00 €", label: "BT 0012 C2 - 100.00 €" },
  { value: "BT 0012 C4 - 100.00 €", label: "BT 0012 C4 - 100.00 €" },
  { value: "BT 0012 C6 - 100.00 €", label: "BT 0012 C6 - 100.00 €" },
  { value: "BT 0012 C8 - 100.00 €", label: "BT 0012 C8 - 100.00 €" },
  { value: "BT 0014 C2 - 100.00 €", label: "BT 0014 C2 - 100.00 €" },
  { value: "BT 0014 C6 - 100.00 €", label: "BT 0014 C6 - 100.00 €" },
  { value: "BT 0014 C8 - 100.00 €", label: "BT 0014 C8 - 100.00 €" },
  { value: "BT 0025 C3B - 100.00 €", label: "BT 0025 C3B - 100.00 €" },
  { value: "CG 014 C1 - 100.00 €", label: "CG 014 C1 - 100.00 €" },
  { value: "CG 014 C2 - 100.00 €", label: "CG 014 C2 - 100.00 €" },
  { value: "CG 014 C3 - 100.00 €", label: "CG 014 C3 - 100.00 €" },
  { value: "CG 014 C4 - 100.00 €", label: "CG 014 C4 - 100.00 €" },
  { value: "CG 014 C5 - 100.00 €", label: "CG 014 C5 - 100.00 €" },
  { value: "CG 016 C2 - 100.00 €", label: "CG 016 C2 - 100.00 €" },
  { value: "CG 016 C3 - 100.00 €", label: "CG 016 C3 - 100.00 €" },
  { value: "CG 017 C2 - 100.00 €", label: "CG 017 C2 - 100.00 €" },
  { value: "CG 017 C3 - 100.00 €", label: "CG 017 C3 - 100.00 €" },
  { value: "CG 019 C1 - 100.00 €", label: "CG 019 C1 - 100.00 €" },
  { value: "CG 019 C2 - 100.00 €", label: "CG 019 C2 - 100.00 €" },
  { value: "CG 019 C3 - 100.00 €", label: "CG 019 C3 - 100.00 €" },
  { value: "CG 020 C2 - 100.00 €", label: "CG 020 C2 - 100.00 €" },
  { value: "CG 021 C2 - 100.00 €", label: "CG 021 C2 - 100.00 €" },
  { value: "CG 021 C3 - 100.00 €", label: "CG 021 C3 - 100.00 €" },
  { value: "CG 024 C1 - 100.00 €", label: "CG 024 C1 - 100.00 €" },
  { value: "CG 024 C3 - 100.00 €", label: "CG 024 C3 - 100.00 €" },
  { value: "IN-2207 N01 - 100.00 €", label: "IN-2207 N01 - 100.00 €" },
  { value: "IN-2207 N02 - 100.00 €", label: "IN-2207 N02 - 100.00 €" },
  { value: "IN-2208 N03 - 100.00 €", label: "IN-2208 N03 - 100.00 €" },
  { value: "IN-2209 C11 - 100.00 €", label: "IN-2209 C11 - 100.00 €" },
  { value: "IN-2210 N02 - 100.00 €", label: "IN-2210 N02 - 100.00 €" },
  { value: "IN-2210 N03 - 100.00 €", label: "IN-2210 N03 - 100.00 €" },
  { value: "IN-2212 N03 - 100.00 €", label: "IN-2212 N03 - 100.00 €" },
  { value: "IN-2214 N03 - 100.00 €", label: "IN-2214 N03 - 100.00 €" },
  { value: "IN-2216 N01 - 100.00 €", label: "IN-2216 N01 - 100.00 €" },
  { value: "IN-2216 N02 - 100.00 €", label: "IN-2216 N02 - 100.00 €" },
  { value: "IN-2219 N01 - 100.00 €", label: "IN-2219 N01 - 100.00 €" },
  { value: "IN-2219 N02 - 100.00 €", label: "IN-2219 N02 - 100.00 €" },
  { value: "IN-2220 N02 - 100.00 €", label: "IN-2220 N02 - 100.00 €" },
  { value: "SYM A 01 006 - 100.00 €", label: "SYM A 01 006 - 100.00 €" },
  { value: "SYM A 01 009 - 100.00 €", label: "SYM A 01 009 - 100.00 €" },
  { value: "SYM A 01 011 - 100.00 €", label: "SYM A 01 011 - 100.00 €" },
  { value: "SYM A 01 224 - 100.00 €", label: "SYM A 01 224 - 100.00 €" },
  { value: "SYM A 01 236 - 100.00 €", label: "SYM A 01 236 - 100.00 €" },
  { value: "SYM A 02 001 - 100.00 €", label: "SYM A 02 001 - 100.00 €" },
  { value: "SYM A 02 002 - 100.00 €", label: "SYM A 02 002 - 100.00 €" },
  { value: "SYM A 02 013 - 100.00 €", label: "SYM A 02 013 - 100.00 €" },
  { value: "SYM A 03 003 - 100.00 €", label: "SYM A 03 003 - 100.00 €" },
  { value: "SYM A 03 008 - 100.00 €", label: "SYM A 03 008 - 100.00 €" },
  { value: "SYM A 03 011 - 100.00 €", label: "SYM A 03 011 - 100.00 €" },
  { value: "SYM A 03 013 - 100.00 €", label: "SYM A 03 013 - 100.00 €" },
  { value: "SYM A 03 016 - 100.00 €", label: "SYM A 03 016 - 100.00 €" },
  { value: "SYM A 04 004 - 100.00 €", label: "SYM A 04 004 - 100.00 €" },
  { value: "SYM A 04 005 - 100.00 €", label: "SYM A 04 005 - 100.00 €" },
  { value: "SYM A 04 014 - 100.00 €", label: "SYM A 04 014 - 100.00 €" },
  { value: "SYM A 04 257 - 100.00 €", label: "SYM A 04 257 - 100.00 €" },
  { value: "SYM A 05 001 - 100.00 €", label: "SYM A 05 001 - 100.00 €" },
  { value: "SYM A 05 010 - 100.00 €", label: "SYM A 05 010 - 100.00 €" },
  { value: "SYM A 05 011 - 100.00 €", label: "SYM A 05 011 - 100.00 €" },
  { value: "SYM A 05 013 - 100.00 €", label: "SYM A 05 013 - 100.00 €" },
  { value: "SYM A 06 004 - 100.00 €", label: "SYM A 06 004 - 100.00 €" },
  { value: "SYM A 06 008 - 100.00 €", label: "SYM A 06 008 - 100.00 €" },
  { value: "SYM A 06 012 - 100.00 €", label: "SYM A 06 012 - 100.00 €" },
  { value: "SYM A 06 014 - 100.00 €", label: "SYM A 06 014 - 100.00 €" },
  { value: "SYM A 06 015 - 100.00 €", label: "SYM A 06 015 - 100.00 €" },
  { value: "SYM A 07 217 - 100.00 €", label: "SYM A 07 217 - 100.00 €" },
  { value: "SYM A 07 218 - 100.00 €", label: "SYM A 07 218 - 100.00 €" },
  { value: "SYM A 07 219 - 100.00 €", label: "SYM A 07 219 - 100.00 €" },
  { value: "SYM A 07 220 - 100.00 €", label: "SYM A 07 220 - 100.00 €" },
  { value: "SYM A 07 245 - 100.00 €", label: "SYM A 07 245 - 100.00 €" },
  { value: "SYM A 08 245 - 100.00 €", label: "SYM A 08 245 - 100.00 €" },
  { value: "SYM B 01 224 - 100.00 €", label: "SYM B 01 224 - 100.00 €" },
  { value: "SYM B 01 225 - 100.00 €", label: "SYM B 01 225 - 100.00 €" },
  { value: "SYM B 01 245 - 100.00 €", label: "SYM B 01 245 - 100.00 €" },
  { value: "SYM B 02 001 - 100.00 €", label: "SYM B 02 001 - 100.00 €" },
  { value: "SYM B 02 013 - 100.00 €", label: "SYM B 02 013 - 100.00 €" },
  { value: "SYM B 02 226 - 100.00 €", label: "SYM B 02 226 - 100.00 €" },
  { value: "SYM B 03 014 - 100.00 €", label: "SYM B 03 014 - 100.00 €" },
  { value: "SYM B 03 227 - 100.00 €", label: "SYM B 03 227 - 100.00 €" },
  { value: "SYM B 03 228 - 100.00 €", label: "SYM B 03 228 - 100.00 €" },
  { value: "SYM B 04 014 - 100.00 €", label: "SYM B 04 014 - 100.00 €" },
  { value: "SYM B 04 230 - 100.00 €", label: "SYM B 04 230 - 100.00 €" },
  { value: "SYM B 04 231 - 100.00 €", label: "SYM B 04 231 - 100.00 €" },
  { value: "SYM B 04 257 - 100.00 €", label: "SYM B 04 257 - 100.00 €" },
  { value: "SYM B 05 232 - 100.00 €", label: "SYM B 05 232 - 100.00 €" },
  { value: "SYM B 05 233 - 100.00 €", label: "SYM B 05 233 - 100.00 €" },
  { value: "SYM B 05 234 - 100.00 €", label: "SYM B 05 234 - 100.00 €" },
  { value: "SYM B 06 004 - 100.00 €", label: "SYM B 06 004 - 100.00 €" },
  { value: "SYM B 06 014 - 100.00 €", label: "SYM B 06 014 - 100.00 €" },
  { value: "SYM B 06 015 - 100.00 €", label: "SYM B 06 015 - 100.00 €" },
  { value: "SYM B 06 235 - 100.00 €", label: "SYM B 06 235 - 100.00 €" },
  { value: "SYM C 01 236 - 100.00 €", label: "SYM C 01 236 - 100.00 €" },
  { value: "SYM C 01 237 - 100.00 €", label: "SYM C 01 237 - 100.00 €" },
  { value: "SYM C 01 238 - 100.00 €", label: "SYM C 01 238 - 100.00 €" },
  { value: "SYM C 01 239 - 100.00 €", label: "SYM C 01 239 - 100.00 €" },
  { value: "SYM C 01 245 - 100.00 €", label: "SYM C 01 245 - 100.00 €" },
  { value: "SYM C 02 025 - 100.00 €", label: "SYM C 02 025 - 100.00 €" },
  { value: "SYM C 02 233 - 100.00 €", label: "SYM C 02 233 - 100.00 €" },
  { value: "SYM C 02 246 - 100.00 €", label: "SYM C 02 246 - 100.00 €" },
  { value: "SYM C 02 247 - 100.00 €", label: "SYM C 02 247 - 100.00 €" },
  { value: "SYM C 02 257 - 100.00 €", label: "SYM C 02 257 - 100.00 €" },
  { value: "SYM C 03 016 - 100.00 €", label: "SYM C 03 016 - 100.00 €" },
  { value: "SYM C 03 140 - 100.00 €", label: "SYM C 03 140 - 100.00 €" },
  { value: "SYM C 03 240 - 100.00 €", label: "SYM C 03 240 - 100.00 €" },
  { value: "SYM C 03 248 - 100.00 €", label: "SYM C 03 248 - 100.00 €" },
  { value: "SYM C 04 014 - 100.00 €", label: "SYM C 04 014 - 100.00 €" },
  { value: "SYM C 04 224 - 100.00 €", label: "SYM C 04 224 - 100.00 €" },
  { value: "SYM C 04 228 - 100.00 €", label: "SYM C 04 228 - 100.00 €" },
  { value: "SYM C 04 230 - 100.00 €", label: "SYM C 04 230 - 100.00 €" },
  { value: "SYM C 04 241 - 100.00 €", label: "SYM C 04 241 - 100.00 €" },
  { value: "SYM C 05 242 - 100.00 €", label: "SYM C 05 242 - 100.00 €" },
  { value: "SYM C 05 243 - 100.00 €", label: "SYM C 05 243 - 100.00 €" },
  { value: "SYM C 05 244 - 100.00 €", label: "SYM C 05 244 - 100.00 €" },
  { value: "SYM K 01 151 - 100.00 €", label: "SYM K 01 151 - 100.00 €" },
  { value: "SYM K 01 252 - 100.00 €", label: "SYM K 01 252 - 100.00 €" },
  { value: "SYM K 01 253 - 100.00 €", label: "SYM K 01 253 - 100.00 €" },
  { value: "SYM K 01 254 - 100.00 €", label: "SYM K 01 254 - 100.00 €" },
  { value: "SYM K 01 255 - 100.00 €", label: "SYM K 01 255 - 100.00 €" },
  { value: "SYM K 02 251 - 100.00 €", label: "SYM K 02 251 - 100.00 €" },
  { value: "SYM K 02 252 - 100.00 €", label: "SYM K 02 252 - 100.00 €" },
  { value: "SYM K 02 253 - 100.00 €", label: "SYM K 02 253 - 100.00 €" },
  { value: "SYM K 02 254 - 100.00 €", label: "SYM K 02 254 - 100.00 €" },
  { value: "SYM K 02 255 - 100.00 €", label: "SYM K 02 255 - 100.00 €" },
  { value: "SYM K 03 251 - 100.00 €", label: "SYM K 03 251 - 100.00 €" },
  { value: "SYM K 03 252 - 100.00 €", label: "SYM K 03 252 - 100.00 €" },
  { value: "SYM K 03 253 - 100.00 €", label: "SYM K 03 253 - 100.00 €" },
  { value: "SYM K 03 254 - 100.00 €", label: "SYM K 03 254 - 100.00 €" },
  { value: "SYM K 03 255 - 100.00 €", label: "SYM K 03 255 - 100.00 €" },
  { value: "SYM K 04 251 - 100.00 €", label: "SYM K 04 251 - 100.00 €" },
  { value: "SYM K 04 252 - 100.00 €", label: "SYM K 04 252 - 100.00 €" },
  { value: "SYM K 04 253 - 100.00 €", label: "SYM K 04 253 - 100.00 €" },
  { value: "SYM K 04 254 - 100.00 €", label: "SYM K 04 254 - 100.00 €" },
  { value: "SYM K 04 255 - 100.00 €", label: "SYM K 04 255 - 100.00 €" },
  { value: "YC 1091 C1 - 100.00 €", label: "YC 1091 C1 - 100.00 €" },
  { value: "YC 1091 C4 - 100.00 €", label: "YC 1091 C4 - 100.00 €" },
  { value: "YC 11022 C2 - 100.00 €", label: "YC 11022 C2 - 100.00 €" },
  { value: "YC 11022 C3 - 100.00 €", label: "YC 11022 C3 - 100.00 €" },
  { value: "YC 11023 C4 - 100.00 €", label: "YC 11023 C4 - 100.00 €" },
  { value: "YC 11024 C3 - 100.00 €", label: "YC 11024 C3 - 100.00 €" },
  { value: "YC 11025 C4 - 100.00 €", label: "YC 11025 C4 - 100.00 €" },
  { value: "YC 11028 C2 - 100.00 €", label: "YC 11028 C2 - 100.00 €" },
  { value: "YC 11029 C3 - 100.00 €", label: "YC 11029 C3 - 100.00 €" },
  { value: "YC 11029 C4 - 100.00 €", label: "YC 11029 C4 - 100.00 €" },
  { value: "YC 11030 C2 - 100.00 €", label: "YC 11030 C2 - 100.00 €" },
  { value: "YC 11030 C3 - 100.00 €", label: "YC 11030 C3 - 100.00 €" },
  { value: "YC 11030 C4 - 100.00 €", label: "YC 11030 C4 - 100.00 €" },
  { value: "YC 11031 C2 - 100.00 €", label: "YC 11031 C2 - 100.00 €" },
  { value: "YC 11032 C4 - 100.00 €", label: "YC 11032 C4 - 100.00 €" },
  { value: "YC 11037 C4 - 100.00 €", label: "YC 11037 C4 - 100.00 €" },
  { value: "YC 11040 C4 - 100.00 €", label: "YC 11040 C4 - 100.00 €" },
  { value: "YC 11052 C2 - 100.00 €", label: "YC 11052 C2 - 100.00 €" },
  { value: "YC 11063 C4 - 100.00 €", label: "YC 11063 C4 - 100.00 €" },
  { value: "YC 11064 C2 - 100.00 €", label: "YC 11064 C2 - 100.00 €" },
  { value: "YC 11068 C4 - 100.00 €", label: "YC 11068 C4 - 100.00 €" },
  { value: "YC 12003 C1 - 100.00 €", label: "YC 12003 C1 - 100.00 €" },
  { value: "YC 12003 C2 - 100.00 €", label: "YC 12003 C2 - 100.00 €" },
  { value: "YC 12003 C3 - 100.00 €", label: "YC 12003 C3 - 100.00 €" },
  { value: "YC 12003 C4 - 100.00 €", label: "YC 12003 C4 - 100.00 €" },
  { value: "YC 13001 C2 - 100.00 €", label: "YC 13001 C2 - 100.00 €" },
  { value: "YC 13002 C2 - 100.00 €", label: "YC 13002 C2 - 100.00 €" },
  { value: "YC 13002 C3 - 100.00 €", label: "YC 13002 C3 - 100.00 €" },
  { value: "YC 13003 C1 - 100.00 €", label: "YC 13003 C1 - 100.00 €" },
  { value: "YC 14044 C3 - 100.00 €", label: "YC 14044 C3 - 100.00 €" },
  { value: "YC 14045 C2 - 100.00 €", label: "YC 14045 C2 - 100.00 €" },
  { value: "YC 14047 C1 - 100.00 €", label: "YC 14047 C1 - 100.00 €" },
  { value: "YC 14047 C4 - 100.00 €", label: "YC 14047 C4 - 100.00 €" },
  { value: "YC 14047 C7 - 100.00 €", label: "YC 14047 C7 - 100.00 €" },
  { value: "YC 14048 C1 - 100.00 €", label: "YC 14048 C1 - 100.00 €" },
  { value: "YC 14048 C2 - 100.00 €", label: "YC 14048 C2 - 100.00 €" },
  { value: "YC 15035 C2 - 100.00 €", label: "YC 15035 C2 - 100.00 €" },
  { value: "YC 15085 C2 - 100.00 €", label: "YC 15085 C2 - 100.00 €" },
  { value: "YC 15085 C4 - 100.00 €", label: "YC 15085 C4 - 100.00 €" },
  { value: "YC 15086 C4 - 100.00 €", label: "YC 15086 C4 - 100.00 €" },
  { value: "YC 21001 C1 - 100.00 €", label: "YC 21001 C1 - 100.00 €" },
  { value: "YC 21001 C2 - 100.00 €", label: "YC 21001 C2 - 100.00 €" },
  { value: "YC 21001 C3 - 100.00 €", label: "YC 21001 C3 - 100.00 €" },
  { value: "YC 21001 C4 - 100.00 €", label: "YC 21001 C4 - 100.00 €" },
  { value: "YC 21002 C3 - 100.00 €", label: "YC 21002 C3 - 100.00 €" },
  { value: "YC 21007 C4 - 100.00 €", label: "YC 21007 C4 - 100.00 €" },
  { value: "YC 21009 C1 - 100.00 €", label: "YC 21009 C1 - 100.00 €" },
  { value: "YC 21012 C1 - 100.00 €", label: "YC 21012 C1 - 100.00 €" },
  { value: "YC 21012 C3 - 100.00 €", label: "YC 21012 C3 - 100.00 €" },
  { value: "YC 21012 C4 - 100.00 €", label: "YC 21012 C4 - 100.00 €" },
  { value: "YC 21013 C1 - 100.00 €", label: "YC 21013 C1 - 100.00 €" },
  { value: "YC 21013 C2 - 100.00 €", label: "YC 21013 C2 - 100.00 €" },
  { value: "YC 21013 C3 - 100.00 €", label: "YC 21013 C3 - 100.00 €" },
  { value: "YC 21013 C4 - 100.00 €", label: "YC 21013 C4 - 100.00 €" },
  { value: "YC 21014 C2 - 100.00 €", label: "YC 21014 C2 - 100.00 €" },
  { value: "YC 21014 C3 - 100.00 €", label: "YC 21014 C3 - 100.00 €" },
  { value: "YC 21015 C2 - 100.00 €", label: "YC 21015 C2 - 100.00 €" },
  { value: "YC 21026 C4 - 100.00 €", label: "YC 21026 C4 - 100.00 €" },
  { value: "YC 21031 C1 - 100.00 €", label: "YC 21031 C1 - 100.00 €" },
  { value: "YC 21031 C4 - 100.00 €", label: "YC 21031 C4 - 100.00 €" },
  { value: "YC 21034 C1 - 100.00 €", label: "YC 21034 C1 - 100.00 €" },
  { value: "YC 21034 C3 - 100.00 €", label: "YC 21034 C3 - 100.00 €" },
  { value: "YC 21034 C4 - 100.00 €", label: "YC 21034 C4 - 100.00 €" },
  { value: "YC 21036 C1 - 100.00 €", label: "YC 21036 C1 - 100.00 €" },
  { value: "YC 21038 C2 - 100.00 €", label: "YC 21038 C2 - 100.00 €" },
  { value: "YC 21039 C4 - 100.00 €", label: "YC 21039 C4 - 100.00 €" },
  { value: "YC 21061 C4 - 100.00 €", label: "YC 21061 C4 - 100.00 €" },
  { value: "YC 21077 C1 - 100.00 €", label: "YC 21077 C1 - 100.00 €" },
  { value: "YC 21086 C3 - 100.00 €", label: "YC 21086 C3 - 100.00 €" },
  { value: "YC 21093 C2 - 100.00 €", label: "YC 21093 C2 - 100.00 €" },
  { value: "YC 21105 C2 - 100.00 €", label: "YC 21105 C2 - 100.00 €" },
  { value: "YC 21105 C3 - 100.00 €", label: "YC 21105 C3 - 100.00 €" },
  { value: "YC 21122 C4 - 100.00 €", label: "YC 21122 C4 - 100.00 €" },
  { value: "YC 21131 C3 - 100.00 €", label: "YC 21131 C3 - 100.00 €" },
  { value: "YC 21144 C3 - 100.00 €", label: "YC 21144 C3 - 100.00 €" },
  { value: "YC 21144 C4 - 100.00 €", label: "YC 21144 C4 - 100.00 €" },
  { value: "YC 21145 C3 - 100.00 €", label: "YC 21145 C3 - 100.00 €" },
  { value: "YC 21145 C4 - 100.00 €", label: "YC 21145 C4 - 100.00 €" },
  { value: "YC 21146 C1 - 100.00 €", label: "YC 21146 C1 - 100.00 €" },
  { value: "YC 21147 C1 - 100.00 €", label: "YC 21147 C1 - 100.00 €" },
  { value: "YC 21148 C3 - 100.00 €", label: "YC 21148 C3 - 100.00 €" },
  { value: "YC 21148 C4 - 100.00 €", label: "YC 21148 C4 - 100.00 €" },
  { value: "YC 21149 C1 - 100.00 €", label: "YC 21149 C1 - 100.00 €" },
  { value: "YC 21151 C4 - 100.00 €", label: "YC 21151 C4 - 100.00 €" },
  { value: "YC 21155 C2 - 100.00 €", label: "YC 21155 C2 - 100.00 €" },
  { value: "YC 21155 C3 - 100.00 €", label: "YC 21155 C3 - 100.00 €" },
  { value: "YC 21156 C2 - 100.00 €", label: "YC 21156 C2 - 100.00 €" },
  { value: "YC 21158 C1 - 100.00 €", label: "YC 21158 C1 - 100.00 €" },
  { value: "YC 21158 C3 - 100.00 €", label: "YC 21158 C3 - 100.00 €" },
  { value: "YC 21158 C4 - 100.00 €", label: "YC 21158 C4 - 100.00 €" },
  { value: "YC 21159 C4 - 100.00 €", label: "YC 21159 C4 - 100.00 €" },
  { value: "YC 21161 C3 - 100.00 €", label: "YC 21161 C3 - 100.00 €" },
  { value: "YC 21163 C2 - 100.00 €", label: "YC 21163 C2 - 100.00 €" },
  { value: "YC 21163 C3 - 100.00 €", label: "YC 21163 C3 - 100.00 €" },
  { value: "YC 21166 C2 - 100.00 €", label: "YC 21166 C2 - 100.00 €" },
  { value: "YC 21184 C4 - 100.00 €", label: "YC 21184 C4 - 100.00 €" },
  { value: "YC 2171 C1 - 100.00 €", label: "YC 2171 C1 - 100.00 €" },
  { value: "YC 22002 C1 - 100.00 €", label: "YC 22002 C1 - 100.00 €" },
  { value: "YC 22002 C4 - 100.00 €", label: "YC 22002 C4 - 100.00 €" },
  { value: "YC 22031 C2 - 100.00 €", label: "YC 22031 C2 - 100.00 €" },
  { value: "YC 22036 C4 - 100.00 €", label: "YC 22036 C4 - 100.00 €" },
  { value: "YC 22041 C4 - 100.00 €", label: "YC 22041 C4 - 100.00 €" },
  { value: "YC 22043 C3 - 100.00 €", label: "YC 22043 C3 - 100.00 €" },
  { value: "YC 22050 C4 - 100.00 €", label: "YC 22050 C4 - 100.00 €" },
  { value: "YC 22057 C1 - 100.00 €", label: "YC 22057 C1 - 100.00 €" },
  { value: "YC 22057 C4 - 100.00 €", label: "YC 22057 C4 - 100.00 €" },
  { value: "YC 22062 C3 - 100.00 €", label: "YC 22062 C3 - 100.00 €" },
  { value: "YC 22081 C1 - 100.00 €", label: "YC 22081 C1 - 100.00 €" },
  { value: "YC 22082 C3 - 100.00 €", label: "YC 22082 C3 - 100.00 €" },
  { value: "YC 22082 C4 - 100.00 €", label: "YC 22082 C4 - 100.00 €" },
  { value: "YC 22095 C2 - 100.00 €", label: "YC 22095 C2 - 100.00 €" },
  { value: "YC 22095 C3 - 100.00 €", label: "YC 22095 C3 - 100.00 €" },
  { value: "YC 22101 C1 - 100.00 €", label: "YC 22101 C1 - 100.00 €" },
  { value: "YC 22101 C3 - 100.00 €", label: "YC 22101 C3 - 100.00 €" },
  { value: "YC 22102 C4 - 100.00 €", label: "YC 22102 C4 - 100.00 €" },
  { value: "YC 22122 C4 - 100.00 €", label: "YC 22122 C4 - 100.00 €" },
  { value: "YC 23043 C2 - 100.00 €", label: "YC 23043 C2 - 100.00 €" },
  { value: "YC 23043 C3 - 100.00 €", label: "YC 23043 C3 - 100.00 €" },
  { value: "YC 23052 C4 - 100.00 €", label: "YC 23052 C4 - 100.00 €" },
  { value: "YC 23055 C1 - 100.00 €", label: "YC 23055 C1 - 100.00 €" },
  { value: "YC 23055 C2 - 100.00 €", label: "YC 23055 C2 - 100.00 €" },
  { value: "YC 23057 C3 - 100.00 €", label: "YC 23057 C3 - 100.00 €" },
  { value: "YC 23057 C4 - 100.00 €", label: "YC 23057 C4 - 100.00 €" },
  { value: "YC 23061 C4 - 100.00 €", label: "YC 23061 C4 - 100.00 €" },
  { value: "YC 23087 C4 - 100.00 €", label: "YC 23087 C4 - 100.00 €" },
  { value: "YC 23112 C1 - 100.00 €", label: "YC 23112 C1 - 100.00 €" },
  { value: "YC 23112 C2 - 100.00 €", label: "YC 23112 C2 - 100.00 €" },
  { value: "YC 23121 C1 - 100.00 €", label: "YC 23121 C1 - 100.00 €" },
  { value: "YC 23121 C3 - 100.00 €", label: "YC 23121 C3 - 100.00 €" },
  { value: "YC 23125 C1 - 100.00 €", label: "YC 23125 C1 - 100.00 €" },
  { value: "YC 23125 C2 - 100.00 €", label: "YC 23125 C2 - 100.00 €" },
  { value: "YC 23125 C3 - 100.00 €", label: "YC 23125 C3 - 100.00 €" },
  { value: "YC 23139 C1 - 100.00 €", label: "YC 23139 C1 - 100.00 €" },
  { value: "YC 23139 C4 - 100.00 €", label: "YC 23139 C4 - 100.00 €" },
  { value: "YC 23142 C1 - 100.00 €", label: "YC 23142 C1 - 100.00 €" },
  { value: "YC 23142 C2 - 100.00 €", label: "YC 23142 C2 - 100.00 €" },
  { value: "YC 23142 C3 - 100.00 €", label: "YC 23142 C3 - 100.00 €" },
  { value: "YC 23153 C3 - 100.00 €", label: "YC 23153 C3 - 100.00 €" },
  { value: "YC 23168 C2 - 100.00 €", label: "YC 23168 C2 - 100.00 €" },
  { value: "YC 23168 C3 - 100.00 €", label: "YC 23168 C3 - 100.00 €" },
  { value: "YC 23168 C4 - 100.00 €", label: "YC 23168 C4 - 100.00 €" },
  { value: "YC 23170 C1 - 100.00 €", label: "YC 23170 C1 - 100.00 €" },
  { value: "YC 23170 C3 - 100.00 €", label: "YC 23170 C3 - 100.00 €" },
  { value: "YC 23171 C1 - 100.00 €", label: "YC 23171 C1 - 100.00 €" },
  { value: "YC 23171 C3 - 100.00 €", label: "YC 23171 C3 - 100.00 €" },
  { value: "YC 23171 C4 - 100.00 €", label: "YC 23171 C4 - 100.00 €" },
  { value: "YC 23174 C4 - 100.00 €", label: "YC 23174 C4 - 100.00 €" },
  { value: "YC 25008 C2 - 100.00 €", label: "YC 25008 C2 - 100.00 €" },
  { value: "YC 26007 C4 - 100.00 €", label: "YC 26007 C4 - 100.00 €" },
  { value: "YC 26008 C1 - 100.00 €", label: "YC 26008 C1 - 100.00 €" },
  { value: "YC 26008 C2 - 100.00 €", label: "YC 26008 C2 - 100.00 €" },
  { value: "YC 26008 C4 - 100.00 €", label: "YC 26008 C4 - 100.00 €" },
  { value: "YC 26010 C1 - 100.00 €", label: "YC 26010 C1 - 100.00 €" },
  { value: "YC 26010 C2 - 100.00 €", label: "YC 26010 C2 - 100.00 €" },
  { value: "YC 26012 C2 - 100.00 €", label: "YC 26012 C2 - 100.00 €" },
  { value: "YC 26019 C1 - 100.00 €", label: "YC 26019 C1 - 100.00 €" },
  { value: "YC 26027 C2 - 100.00 €", label: "YC 26027 C2 - 100.00 €" },
  { value: "YC 26028 C1 - 100.00 €", label: "YC 26028 C1 - 100.00 €" },
  { value: "YC 26028 C3 - 100.00 €", label: "YC 26028 C3 - 100.00 €" },
  { value: "YC 26032 C1 - 100.00 €", label: "YC 26032 C1 - 100.00 €" },
  { value: "YC 26042 C2 - 100.00 €", label: "YC 26042 C2 - 100.00 €" },
  { value: "YC 26042 C4 - 100.00 €", label: "YC 26042 C4 - 100.00 €" },
  { value: "YC 26048 C2 - 100.00 €", label: "YC 26048 C2 - 100.00 €" },
  { value: "YC 26048 C3 - 100.00 €", label: "YC 26048 C3 - 100.00 €" },
  { value: "YC 26048 C4 - 100.00 €", label: "YC 26048 C4 - 100.00 €" },
  { value: "YC 26049 C2 - 100.00 €", label: "YC 26049 C2 - 100.00 €" },
  { value: "YC 26049 C4 - 100.00 €", label: "YC 26049 C4 - 100.00 €" },
  { value: "YC 28004 C1 - 100.00 €", label: "YC 28004 C1 - 100.00 €" },
  { value: "YC 28018 C1 - 100.00 €", label: "YC 28018 C1 - 100.00 €" },
  { value: "YC 28022 C1 - 100.00 €", label: "YC 28022 C1 - 100.00 €" },
  { value: "YC 28027 C2 - 100.00 €", label: "YC 28027 C2 - 100.00 €" },
  { value: "YC 28032 C3 - 100.00 €", label: "YC 28032 C3 - 100.00 €" },
  { value: "YC 28034 C4 - 100.00 €", label: "YC 28034 C4 - 100.00 €" },
  { value: "YC 28039 C3 - 100.00 €", label: "YC 28039 C3 - 100.00 €" },
  { value: "YC 28052 C1 - 100.00 €", label: "YC 28052 C1 - 100.00 €" },
  { value: "YC 28052 C2 - 100.00 €", label: "YC 28052 C2 - 100.00 €" },
  { value: "YC 28055 C3 - 100.00 €", label: "YC 28055 C3 - 100.00 €" },
  { value: "YC 29001 C2 - 100.00 €", label: "YC 29001 C2 - 100.00 €" },
  { value: "YC 29001 C3 - 100.00 €", label: "YC 29001 C3 - 100.00 €" },
  { value: "YC 29002 C1 - 100.00 €", label: "YC 29002 C1 - 100.00 €" },
  { value: "YC 29003 C1 - 100.00 €", label: "YC 29003 C1 - 100.00 €" },
  { value: "YC 29003 C4 - 100.00 €", label: "YC 29003 C4 - 100.00 €" },
  { value: "YC 29005 C2 - 100.00 €", label: "YC 29005 C2 - 100.00 €" },
  { value: "YC 29006 C2 - 100.00 €", label: "YC 29006 C2 - 100.00 €" },
  { value: "YC 29008 C4 - 100.00 €", label: "YC 29008 C4 - 100.00 €" },
  { value: "YC 29009 C1 - 100.00 €", label: "YC 29009 C1 - 100.00 €" },
  { value: "YC 29009 C2 - 100.00 €", label: "YC 29009 C2 - 100.00 €" },
  { value: "YC 29009 C3 - 100.00 €", label: "YC 29009 C3 - 100.00 €" },
  { value: "YC 29009 C4 - 100.00 €", label: "YC 29009 C4 - 100.00 €" },
  { value: "YC 29011 C1 - 100.00 €", label: "YC 29011 C1 - 100.00 €" },
  { value: "YC 29011 C4 - 100.00 €", label: "YC 29011 C4 - 100.00 €" },
  { value: "YC 29013 C1 - 100.00 €", label: "YC 29013 C1 - 100.00 €" },
  { value: "YC 29013 C2 - 100.00 €", label: "YC 29013 C2 - 100.00 €" },
  { value: "YC 29024 C1 - 100.00 €", label: "YC 29024 C1 - 100.00 €" },
  { value: "YC 29025 C1 - 100.00 €", label: "YC 29025 C1 - 100.00 €" },
  { value: "YC 29031 C2 - 100.00 €", label: "YC 29031 C2 - 100.00 €" },
  { value: "YC 29031 C4 - 100.00 €", label: "YC 29031 C4 - 100.00 €" },
  { value: "YC 29032 C1 - 100.00 €", label: "YC 29032 C1 - 100.00 €" },
  { value: "YC 29032 C2 - 100.00 €", label: "YC 29032 C2 - 100.00 €" },
  { value: "YC 29032 C3 - 100.00 €", label: "YC 29032 C3 - 100.00 €" },
  { value: "YC 29032 C4 - 100.00 €", label: "YC 29032 C4 - 100.00 €" },
  { value: "YC 29033 C3 - 100.00 €", label: "YC 29033 C3 - 100.00 €" },
  { value: "YC 29034 C3 - 100.00 €", label: "YC 29034 C3 - 100.00 €" },
  { value: "YC 29035 C4 - 100.00 €", label: "YC 29035 C4 - 100.00 €" },
  { value: "YC 29047 C1 - 100.00 €", label: "YC 29047 C1 - 100.00 €" },
  { value: "YC 29047 C3 - 100.00 €", label: "YC 29047 C3 - 100.00 €" },
  { value: "YC 29048 C1 - 100.00 €", label: "YC 29048 C1 - 100.00 €" },
  { value: "YC 29063 C1 - 100.00 €", label: "YC 29063 C1 - 100.00 €" },
  { value: "YC 29063 C4 - 100.00 €", label: "YC 29063 C4 - 100.00 €" },
  { value: "YC 29065 C1 - 100.00 €", label: "YC 29065 C1 - 100.00 €" },
  { value: "YC 29065 C2 - 100.00 €", label: "YC 29065 C2 - 100.00 €" },
  { value: "YC 30015 C1 - 100.00 €", label: "YC 30015 C1 - 100.00 €" },
  { value: "YC 30015 C3 - 100.00 €", label: "YC 30015 C3 - 100.00 €" },
  { value: "YC 30017 C1 - 100.00 €", label: "YC 30017 C1 - 100.00 €" },
  { value: "YC 30017 C2 - 100.00 €", label: "YC 30017 C2 - 100.00 €" },
  { value: "YC 30041 C1 - 100.00 €", label: "YC 30041 C1 - 100.00 €" },
  { value: "YC 30041 C2 - 100.00 €", label: "YC 30041 C2 - 100.00 €" },
  { value: "YC 30049 C4 - 100.00 €", label: "YC 30049 C4 - 100.00 €" },
  { value: "YC 30057 C2 - 100.00 €", label: "YC 30057 C2 - 100.00 €" },
  { value: "YC 31064 C2 - 100.00 €", label: "YC 31064 C2 - 100.00 €" },
  { value: "YC 31064 C3 - 100.00 €", label: "YC 31064 C3 - 100.00 €" },
  { value: "YC 31064 C4 - 100.00 €", label: "YC 31064 C4 - 100.00 €" },
  { value: "YC 31069 C1 - 100.00 €", label: "YC 31069 C1 - 100.00 €" },
  { value: "YC 31072 C4 - 100.00 €", label: "YC 31072 C4 - 100.00 €" },
  { value: "YC 31078 C1 - 100.00 €", label: "YC 31078 C1 - 100.00 €" },
  { value: "YC 31085 C1 - 100.00 €", label: "YC 31085 C1 - 100.00 €" },
  { value: "YC 313 C1 - 100.00 €", label: "YC 313 C1 - 100.00 €" },
  { value: "YC 313 C4 - 100.00 €", label: "YC 313 C4 - 100.00 €" },
  { value: "YC 33059 C1 - 100.00 €", label: "YC 33059 C1 - 100.00 €" },
  { value: "YC 33063 C1 - 100.00 €", label: "YC 33063 C1 - 100.00 €" },
  { value: "YC 34009 C2 - 100.00 €", label: "YC 34009 C2 - 100.00 €" },
  { value: "YC 34009 C3 - 100.00 €", label: "YC 34009 C3 - 100.00 €" },
  { value: "YC 39003 C5 - 100.00 €", label: "YC 39003 C5 - 100.00 €" },
  { value: "YC 39027 C1 - 100.00 €", label: "YC 39027 C1 - 100.00 €" },
  { value: "YC 39028 C2 - 100.00 €", label: "YC 39028 C2 - 100.00 €" },
  { value: "YC 39063 C3 - 100.00 €", label: "YC 39063 C3 - 100.00 €" },
  { value: "YC 39072 C4 - 100.00 €", label: "YC 39072 C4 - 100.00 €" },
  { value: "YC 60019 C1 - 100.00 €", label: "YC 60019 C1 - 100.00 €" },
  { value: "YC 60019 C4 - 100.00 €", label: "YC 60019 C4 - 100.00 €" },
  { value: "YC 60023 C7 - 100.00 €", label: "YC 60023 C7 - 100.00 €" },
  { value: "YC 60023 C8 - 100.00 €", label: "YC 60023 C8 - 100.00 €" },
  { value: "YC 60026 C1 - 100.00 €", label: "YC 60026 C1 - 100.00 €" },
  { value: "YC 60026 C4 - 100.00 €", label: "YC 60026 C4 - 100.00 €" },
  { value: "YC 60031 C4 - 100.00 €", label: "YC 60031 C4 - 100.00 €" },
  { value: "YC 60032 C6 - 100.00 €", label: "YC 60032 C6 - 100.00 €" },
  { value: "YC 60036 C1 - 100.00 €", label: "YC 60036 C1 - 100.00 €" },
  { value: "YC 60036 C4 - 100.00 €", label: "YC 60036 C4 - 100.00 €" },
  { value: "YC 60036 C7 - 100.00 €", label: "YC 60036 C7 - 100.00 €" },
  { value: "YC 60037 C4 - 100.00 €", label: "YC 60037 C4 - 100.00 €" },
  { value: "YC 60051 C7 - 100.00 €", label: "YC 60051 C7 - 100.00 €" },
  { value: "YC 63006 C5 - 100.00 €", label: "YC 63006 C5 - 100.00 €" },
  { value: "YC 63006 C6 - 100.00 €", label: "YC 63006 C6 - 100.00 €" },
  { value: "YC 63038 C6 - 100.00 €", label: "YC 63038 C6 - 100.00 €" },
  { value: "YC 8087 C1 - 100.00 €", label: "YC 8087 C1 - 100.00 €" },
  { value: "YC 8087 C3 - 100.00 €", label: "YC 8087 C3 - 100.00 €" },
  { value: "YC 9609 C1 - 100.00 €", label: "YC 9609 C1 - 100.00 €" },
  { value: "YC 9609 C3 - 100.00 €", label: "YC 9609 C3 - 100.00 €" },
  { value: "YC 25025 C2 - 100.00 €", label: "YC 25025 C2 - 100.00 €" },
  { value: "GC 001 C2 - 100.00 €", label: "GC 001 C2 - 100.00 €" },
  { value: "GC 001 C3 - 100.00 €", label: "GC 001 C3 - 100.00 €" },
  { value: "GC 002 C2 - 100.00 €", label: "GC 002 C2 - 100.00 €" },
  { value: "GC 002 C3 - 100.00 €", label: "GC 002 C3 - 100.00 €" },
  { value: "GC 003 C2 - 100.00 €", label: "GC 003 C2 - 100.00 €" },
  { value: "GC 003 C3 - 100.00 €", label: "GC 003 C3 - 100.00 €" },
  { value: "GC 004 C1 - 100.00 €", label: "GC 004 C1 - 100.00 €" },
  { value: "GC 004 C2 - 100.00 €", label: "GC 004 C2 - 100.00 €" },
  { value: "GC 004 C3 - 100.00 €", label: "GC 004 C3 - 100.00 €" },
  { value: "GC 005 C3 - 100.00 €", label: "GC 005 C3 - 100.00 €" },
  { value: "GC 006 C1 - 100.00 €", label: "GC 006 C1 - 100.00 €" },
  { value: "GC 006 C3 - 100.00 €", label: "GC 006 C3 - 100.00 €" },
  { value: "GC 007 C3 - 100.00 €", label: "GC 007 C3 - 100.00 €" },
  { value: "62733 C2 - 100.00 €", label: "62733 C2 - 100.00 €" },
  { value: "62733 C3 - 100.00 €", label: "62733 C3 - 100.00 €" },
  { value: "YC 11057 C2 - 100.00 €", label: "YC 11057 C2 - 100.00 €" },
];
export const Monture2 = [
  { value: "", label: "" },
  { value: "Autre - 1.00 €", label: "Autre - 1.00 €" },
  { value: "62582 C2 - 1.00 €", label: "62582 C2 - 1.00 €" },
  { value: "62643 C2-5 - 1.00 €", label: "62643 C2-5 - 1.00 €" },
  { value: "62720 C2 - 1.00 €", label: "62720 C2 - 1.00 €" },
  { value: "62722 C3 - 1.00 €", label: "62722 C3 - 1.00 €" },
  { value: "62725 C3 - 1.00 €", label: "62725 C3 - 1.00 €" },
  { value: "62733 C1 - 1.00 €", label: "62733 C1 - 1.00 €" },
  { value: "62946 C2-4 - 1.00 €", label: "62946 C2-4 - 1.00 €" },
  { value: "B 6109 C2 - 1.00 €", label: "B 6109 C2 - 1.00 €" },
  { value: "BT 0012 C2 - 1.00 €", label: "BT 0012 C2 - 1.00 €" },
  { value: "BT 0012 C4 - 1.00 €", label: "BT 0012 C4 - 1.00 €" },
  { value: "BT 0012 C6 - 1.00 €", label: "BT 0012 C6 - 1.00 €" },
  { value: "BT 0012 C8 - 1.00 €", label: "BT 0012 C8 - 1.00 €" },
  { value: "BT 0014 C2 - 1.00 €", label: "BT 0014 C2 - 1.00 €" },
  { value: "BT 0014 C6 - 1.00 €", label: "BT 0014 C6 - 1.00 €" },
  { value: "BT 0014 C8 - 1.00 €", label: "BT 0014 C8 - 1.00 €" },
  { value: "BT 0025 C3B - 1.00 €", label: "BT 0025 C3B - 1.00 €" },
  { value: "CG 014 C1 - 1.00 €", label: "CG 014 C1 - 1.00 €" },
  { value: "CG 014 C2 - 1.00 €", label: "CG 014 C2 - 1.00 €" },
  { value: "CG 014 C3 - 1.00 €", label: "CG 014 C3 - 1.00 €" },
  { value: "CG 014 C4 - 1.00 €", label: "CG 014 C4 - 1.00 €" },
  { value: "CG 014 C5 - 1.00 €", label: "CG 014 C5 - 1.00 €" },
  { value: "CG 016 C2 - 1.00 €", label: "CG 016 C2 - 1.00 €" },
  { value: "CG 016 C3 - 1.00 €", label: "CG 016 C3 - 1.00 €" },
  { value: "CG 017 C2 - 1.00 €", label: "CG 017 C2 - 1.00 €" },
  { value: "CG 017 C3 - 1.00 €", label: "CG 017 C3 - 1.00 €" },
  { value: "CG 019 C1 - 1.00 €", label: "CG 019 C1 - 1.00 €" },
  { value: "CG 019 C2 - 1.00 €", label: "CG 019 C2 - 1.00 €" },
  { value: "CG 019 C3 - 1.00 €", label: "CG 019 C3 - 1.00 €" },
  { value: "CG 020 C2 - 1.00 €", label: "CG 020 C2 - 1.00 €" },
  { value: "CG 021 C2 - 1.00 €", label: "CG 021 C2 - 1.00 €" },
  { value: "CG 021 C3 - 1.00 €", label: "CG 021 C3 - 1.00 €" },
  { value: "CG 024 C1 - 1.00 €", label: "CG 024 C1 - 1.00 €" },
  { value: "CG 024 C3 - 1.00 €", label: "CG 024 C3 - 1.00 €" },
  { value: "IN-2207 N01 - 1.00 €", label: "IN-2207 N01 - 1.00 €" },
  { value: "IN-2207 N02 - 1.00 €", label: "IN-2207 N02 - 1.00 €" },
  { value: "IN-2208 N03 - 1.00 €", label: "IN-2208 N03 - 1.00 €" },
  { value: "IN-2209 C11 - 1.00 €", label: "IN-2209 C11 - 1.00 €" },
  { value: "IN-2210 N02 - 1.00 €", label: "IN-2210 N02 - 1.00 €" },
  { value: "IN-2210 N03 - 1.00 €", label: "IN-2210 N03 - 1.00 €" },
  { value: "IN-2212 N03 - 1.00 €", label: "IN-2212 N03 - 1.00 €" },
  { value: "IN-2214 N03 - 1.00 €", label: "IN-2214 N03 - 1.00 €" },
  { value: "IN-2216 N01 - 1.00 €", label: "IN-2216 N01 - 1.00 €" },
  { value: "IN-2216 N02 - 1.00 €", label: "IN-2216 N02 - 1.00 €" },
  { value: "IN-2219 N01 - 1.00 €", label: "IN-2219 N01 - 1.00 €" },
  { value: "IN-2219 N02 - 1.00 €", label: "IN-2219 N02 - 1.00 €" },
  { value: "IN-2220 N02 - 1.00 €", label: "IN-2220 N02 - 1.00 €" },
  { value: "SYM A 01 006 - 1.00 €", label: "SYM A 01 006 - 1.00 €" },
  { value: "SYM A 01 009 - 1.00 €", label: "SYM A 01 009 - 1.00 €" },
  { value: "SYM A 01 011 - 1.00 €", label: "SYM A 01 011 - 1.00 €" },
  { value: "SYM A 01 224 - 1.00 €", label: "SYM A 01 224 - 1.00 €" },
  { value: "SYM A 01 236 - 1.00 €", label: "SYM A 01 236 - 1.00 €" },
  { value: "SYM A 02 001 - 1.00 €", label: "SYM A 02 001 - 1.00 €" },
  { value: "SYM A 02 002 - 1.00 €", label: "SYM A 02 002 - 1.00 €" },
  { value: "SYM A 02 013 - 1.00 €", label: "SYM A 02 013 - 1.00 €" },
  { value: "SYM A 03 003 - 1.00 €", label: "SYM A 03 003 - 1.00 €" },
  { value: "SYM A 03 008 - 1.00 €", label: "SYM A 03 008 - 1.00 €" },
  { value: "SYM A 03 011 - 1.00 €", label: "SYM A 03 011 - 1.00 €" },
  { value: "SYM A 03 013 - 1.00 €", label: "SYM A 03 013 - 1.00 €" },
  { value: "SYM A 03 016 - 1.00 €", label: "SYM A 03 016 - 1.00 €" },
  { value: "SYM A 04 004 - 1.00 €", label: "SYM A 04 004 - 1.00 €" },
  { value: "SYM A 04 005 - 1.00 €", label: "SYM A 04 005 - 1.00 €" },
  { value: "SYM A 04 014 - 1.00 €", label: "SYM A 04 014 - 1.00 €" },
  { value: "SYM A 04 257 - 1.00 €", label: "SYM A 04 257 - 1.00 €" },
  { value: "SYM A 05 001 - 1.00 €", label: "SYM A 05 001 - 1.00 €" },
  { value: "SYM A 05 010 - 1.00 €", label: "SYM A 05 010 - 1.00 €" },
  { value: "SYM A 05 011 - 1.00 €", label: "SYM A 05 011 - 1.00 €" },
  { value: "SYM A 05 013 - 1.00 €", label: "SYM A 05 013 - 1.00 €" },
  { value: "SYM A 06 004 - 1.00 €", label: "SYM A 06 004 - 1.00 €" },
  { value: "SYM A 06 008 - 1.00 €", label: "SYM A 06 008 - 1.00 €" },
  { value: "SYM A 06 012 - 1.00 €", label: "SYM A 06 012 - 1.00 €" },
  { value: "SYM A 06 014 - 1.00 €", label: "SYM A 06 014 - 1.00 €" },
  { value: "SYM A 06 015 - 1.00 €", label: "SYM A 06 015 - 1.00 €" },
  { value: "SYM A 07 217 - 1.00 €", label: "SYM A 07 217 - 1.00 €" },
  { value: "SYM A 07 218 - 1.00 €", label: "SYM A 07 218 - 1.00 €" },
  { value: "SYM A 07 219 - 1.00 €", label: "SYM A 07 219 - 1.00 €" },
  { value: "SYM A 07 220 - 1.00 €", label: "SYM A 07 220 - 1.00 €" },
  { value: "SYM A 07 245 - 1.00 €", label: "SYM A 07 245 - 1.00 €" },
  { value: "SYM A 08 245 - 1.00 €", label: "SYM A 08 245 - 1.00 €" },
  { value: "SYM B 01 224 - 1.00 €", label: "SYM B 01 224 - 1.00 €" },
  { value: "SYM B 01 225 - 1.00 €", label: "SYM B 01 225 - 1.00 €" },
  { value: "SYM B 01 245 - 1.00 €", label: "SYM B 01 245 - 1.00 €" },
  { value: "SYM B 02 001 - 1.00 €", label: "SYM B 02 001 - 1.00 €" },
  { value: "SYM B 02 013 - 1.00 €", label: "SYM B 02 013 - 1.00 €" },
  { value: "SYM B 02 226 - 1.00 €", label: "SYM B 02 226 - 1.00 €" },
  { value: "SYM B 03 014 - 1.00 €", label: "SYM B 03 014 - 1.00 €" },
  { value: "SYM B 03 227 - 1.00 €", label: "SYM B 03 227 - 1.00 €" },
  { value: "SYM B 03 228 - 1.00 €", label: "SYM B 03 228 - 1.00 €" },
  { value: "SYM B 04 014 - 1.00 €", label: "SYM B 04 014 - 1.00 €" },
  { value: "SYM B 04 230 - 1.00 €", label: "SYM B 04 230 - 1.00 €" },
  { value: "SYM B 04 231 - 1.00 €", label: "SYM B 04 231 - 1.00 €" },
  { value: "SYM B 04 257 - 1.00 €", label: "SYM B 04 257 - 1.00 €" },
  { value: "SYM B 05 232 - 1.00 €", label: "SYM B 05 232 - 1.00 €" },
  { value: "SYM B 05 233 - 1.00 €", label: "SYM B 05 233 - 1.00 €" },
  { value: "SYM B 05 234 - 1.00 €", label: "SYM B 05 234 - 1.00 €" },
  { value: "SYM B 06 004 - 1.00 €", label: "SYM B 06 004 - 1.00 €" },
  { value: "SYM B 06 014 - 1.00 €", label: "SYM B 06 014 - 1.00 €" },
  { value: "SYM B 06 015 - 1.00 €", label: "SYM B 06 015 - 1.00 €" },
  { value: "SYM B 06 235 - 1.00 €", label: "SYM B 06 235 - 1.00 €" },
  { value: "SYM C 01 236 - 1.00 €", label: "SYM C 01 236 - 1.00 €" },
  { value: "SYM C 01 237 - 1.00 €", label: "SYM C 01 237 - 1.00 €" },
  { value: "SYM C 01 238 - 1.00 €", label: "SYM C 01 238 - 1.00 €" },
  { value: "SYM C 01 239 - 1.00 €", label: "SYM C 01 239 - 1.00 €" },
  { value: "SYM C 01 245 - 1.00 €", label: "SYM C 01 245 - 1.00 €" },
  { value: "SYM C 02 025 - 1.00 €", label: "SYM C 02 025 - 1.00 €" },
  { value: "SYM C 02 233 - 1.00 €", label: "SYM C 02 233 - 1.00 €" },
  { value: "SYM C 02 246 - 1.00 €", label: "SYM C 02 246 - 1.00 €" },
  { value: "SYM C 02 247 - 1.00 €", label: "SYM C 02 247 - 1.00 €" },
  { value: "SYM C 02 257 - 1.00 €", label: "SYM C 02 257 - 1.00 €" },
  { value: "SYM C 03 016 - 1.00 €", label: "SYM C 03 016 - 1.00 €" },
  { value: "SYM C 03 140 - 1.00 €", label: "SYM C 03 140 - 1.00 €" },
  { value: "SYM C 03 240 - 1.00 €", label: "SYM C 03 240 - 1.00 €" },
  { value: "SYM C 03 248 - 1.00 €", label: "SYM C 03 248 - 1.00 €" },
  { value: "SYM C 04 014 - 1.00 €", label: "SYM C 04 014 - 1.00 €" },
  { value: "SYM C 04 224 - 1.00 €", label: "SYM C 04 224 - 1.00 €" },
  { value: "SYM C 04 228 - 1.00 €", label: "SYM C 04 228 - 1.00 €" },
  { value: "SYM C 04 230 - 1.00 €", label: "SYM C 04 230 - 1.00 €" },
  { value: "SYM C 04 241 - 1.00 €", label: "SYM C 04 241 - 1.00 €" },
  { value: "SYM C 05 242 - 1.00 €", label: "SYM C 05 242 - 1.00 €" },
  { value: "SYM C 05 243 - 1.00 €", label: "SYM C 05 243 - 1.00 €" },
  { value: "SYM C 05 244 - 1.00 €", label: "SYM C 05 244 - 1.00 €" },
  { value: "SYM K 01 151 - 1.00 €", label: "SYM K 01 151 - 1.00 €" },
  { value: "SYM K 01 252 - 1.00 €", label: "SYM K 01 252 - 1.00 €" },
  { value: "SYM K 01 253 - 1.00 €", label: "SYM K 01 253 - 1.00 €" },
  { value: "SYM K 01 254 - 1.00 €", label: "SYM K 01 254 - 1.00 €" },
  { value: "SYM K 01 255 - 1.00 €", label: "SYM K 01 255 - 1.00 €" },
  { value: "SYM K 02 251 - 1.00 €", label: "SYM K 02 251 - 1.00 €" },
  { value: "SYM K 02 252 - 1.00 €", label: "SYM K 02 252 - 1.00 €" },
  { value: "SYM K 02 253 - 1.00 €", label: "SYM K 02 253 - 1.00 €" },
  { value: "SYM K 02 254 - 1.00 €", label: "SYM K 02 254 - 1.00 €" },
  { value: "SYM K 02 255 - 1.00 €", label: "SYM K 02 255 - 1.00 €" },
  { value: "SYM K 03 251 - 1.00 €", label: "SYM K 03 251 - 1.00 €" },
  { value: "SYM K 03 252 - 1.00 €", label: "SYM K 03 252 - 1.00 €" },
  { value: "SYM K 03 253 - 1.00 €", label: "SYM K 03 253 - 1.00 €" },
  { value: "SYM K 03 254 - 1.00 €", label: "SYM K 03 254 - 1.00 €" },
  { value: "SYM K 03 255 - 1.00 €", label: "SYM K 03 255 - 1.00 €" },
  { value: "SYM K 04 251 - 1.00 €", label: "SYM K 04 251 - 1.00 €" },
  { value: "SYM K 04 252 - 1.00 €", label: "SYM K 04 252 - 1.00 €" },
  { value: "SYM K 04 253 - 1.00 €", label: "SYM K 04 253 - 1.00 €" },
  { value: "SYM K 04 254 - 1.00 €", label: "SYM K 04 254 - 1.00 €" },
  { value: "SYM K 04 255 - 1.00 €", label: "SYM K 04 255 - 1.00 €" },
  { value: "YC 1091 C1 - 1.00 €", label: "YC 1091 C1 - 1.00 €" },
  { value: "YC 1091 C4 - 1.00 €", label: "YC 1091 C4 - 1.00 €" },
  { value: "YC 11022 C2 - 1.00 €", label: "YC 11022 C2 - 1.00 €" },
  { value: "YC 11022 C3 - 1.00 €", label: "YC 11022 C3 - 1.00 €" },
  { value: "YC 11023 C4 - 1.00 €", label: "YC 11023 C4 - 1.00 €" },
  { value: "YC 11024 C3 - 1.00 €", label: "YC 11024 C3 - 1.00 €" },
  { value: "YC 11025 C4 - 1.00 €", label: "YC 11025 C4 - 1.00 €" },
  { value: "YC 11028 C2 - 1.00 €", label: "YC 11028 C2 - 1.00 €" },
  { value: "YC 11029 C3 - 1.00 €", label: "YC 11029 C3 - 1.00 €" },
  { value: "YC 11029 C4 - 1.00 €", label: "YC 11029 C4 - 1.00 €" },
  { value: "YC 11030 C2 - 1.00 €", label: "YC 11030 C2 - 1.00 €" },
  { value: "YC 11030 C3 - 1.00 €", label: "YC 11030 C3 - 1.00 €" },
  { value: "YC 11030 C4 - 1.00 €", label: "YC 11030 C4 - 1.00 €" },
  { value: "YC 11031 C2 - 1.00 €", label: "YC 11031 C2 - 1.00 €" },
  { value: "YC 11032 C4 - 1.00 €", label: "YC 11032 C4 - 1.00 €" },
  { value: "YC 11037 C4 - 1.00 €", label: "YC 11037 C4 - 1.00 €" },
  { value: "YC 11040 C4 - 1.00 €", label: "YC 11040 C4 - 1.00 €" },
  { value: "YC 11052 C2 - 1.00 €", label: "YC 11052 C2 - 1.00 €" },
  { value: "YC 11063 C4 - 1.00 €", label: "YC 11063 C4 - 1.00 €" },
  { value: "YC 11064 C2 - 1.00 €", label: "YC 11064 C2 - 1.00 €" },
  { value: "YC 11068 C4 - 1.00 €", label: "YC 11068 C4 - 1.00 €" },
  { value: "YC 12003 C1 - 1.00 €", label: "YC 12003 C1 - 1.00 €" },
  { value: "YC 12003 C2 - 1.00 €", label: "YC 12003 C2 - 1.00 €" },
  { value: "YC 12003 C3 - 1.00 €", label: "YC 12003 C3 - 1.00 €" },
  { value: "YC 12003 C4 - 1.00 €", label: "YC 12003 C4 - 1.00 €" },
  { value: "YC 13001 C2 - 1.00 €", label: "YC 13001 C2 - 1.00 €" },
  { value: "YC 13002 C2 - 1.00 €", label: "YC 13002 C2 - 1.00 €" },
  { value: "YC 13002 C3 - 1.00 €", label: "YC 13002 C3 - 1.00 €" },
  { value: "YC 13003 C1 - 1.00 €", label: "YC 13003 C1 - 1.00 €" },
  { value: "YC 14044 C3 - 1.00 €", label: "YC 14044 C3 - 1.00 €" },
  { value: "YC 14045 C2 - 1.00 €", label: "YC 14045 C2 - 1.00 €" },
  { value: "YC 14047 C1 - 1.00 €", label: "YC 14047 C1 - 1.00 €" },
  { value: "YC 14047 C4 - 1.00 €", label: "YC 14047 C4 - 1.00 €" },
  { value: "YC 14047 C7 - 1.00 €", label: "YC 14047 C7 - 1.00 €" },
  { value: "YC 14048 C1 - 1.00 €", label: "YC 14048 C1 - 1.00 €" },
  { value: "YC 14048 C2 - 1.00 €", label: "YC 14048 C2 - 1.00 €" },
  { value: "YC 15035 C2 - 1.00 €", label: "YC 15035 C2 - 1.00 €" },
  { value: "YC 15085 C2 - 1.00 €", label: "YC 15085 C2 - 1.00 €" },
  { value: "YC 15085 C4 - 1.00 €", label: "YC 15085 C4 - 1.00 €" },
  { value: "YC 15086 C4 - 1.00 €", label: "YC 15086 C4 - 1.00 €" },
  { value: "YC 21001 C1 - 1.00 €", label: "YC 21001 C1 - 1.00 €" },
  { value: "YC 21001 C2 - 1.00 €", label: "YC 21001 C2 - 1.00 €" },
  { value: "YC 21001 C3 - 1.00 €", label: "YC 21001 C3 - 1.00 €" },
  { value: "YC 21001 C4 - 1.00 €", label: "YC 21001 C4 - 1.00 €" },
  { value: "YC 21002 C3 - 1.00 €", label: "YC 21002 C3 - 1.00 €" },
  { value: "YC 21007 C4 - 1.00 €", label: "YC 21007 C4 - 1.00 €" },
  { value: "YC 21009 C1 - 1.00 €", label: "YC 21009 C1 - 1.00 €" },
  { value: "YC 21012 C1 - 1.00 €", label: "YC 21012 C1 - 1.00 €" },
  { value: "YC 21012 C3 - 1.00 €", label: "YC 21012 C3 - 1.00 €" },
  { value: "YC 21012 C4 - 1.00 €", label: "YC 21012 C4 - 1.00 €" },
  { value: "YC 21013 C1 - 1.00 €", label: "YC 21013 C1 - 1.00 €" },
  { value: "YC 21013 C2 - 1.00 €", label: "YC 21013 C2 - 1.00 €" },
  { value: "YC 21013 C3 - 1.00 €", label: "YC 21013 C3 - 1.00 €" },
  { value: "YC 21013 C4 - 1.00 €", label: "YC 21013 C4 - 1.00 €" },
  { value: "YC 21014 C2 - 1.00 €", label: "YC 21014 C2 - 1.00 €" },
  { value: "YC 21014 C3 - 1.00 €", label: "YC 21014 C3 - 1.00 €" },
  { value: "YC 21015 C2 - 1.00 €", label: "YC 21015 C2 - 1.00 €" },
  { value: "YC 21026 C4 - 1.00 €", label: "YC 21026 C4 - 1.00 €" },
  { value: "YC 21031 C1 - 1.00 €", label: "YC 21031 C1 - 1.00 €" },
  { value: "YC 21031 C4 - 1.00 €", label: "YC 21031 C4 - 1.00 €" },
  { value: "YC 21034 C1 - 1.00 €", label: "YC 21034 C1 - 1.00 €" },
  { value: "YC 21034 C3 - 1.00 €", label: "YC 21034 C3 - 1.00 €" },
  { value: "YC 21034 C4 - 1.00 €", label: "YC 21034 C4 - 1.00 €" },
  { value: "YC 21036 C1 - 1.00 €", label: "YC 21036 C1 - 1.00 €" },
  { value: "YC 21038 C2 - 1.00 €", label: "YC 21038 C2 - 1.00 €" },
  { value: "YC 21039 C4 - 1.00 €", label: "YC 21039 C4 - 1.00 €" },
  { value: "YC 21061 C4 - 1.00 €", label: "YC 21061 C4 - 1.00 €" },
  { value: "YC 21077 C1 - 1.00 €", label: "YC 21077 C1 - 1.00 €" },
  { value: "YC 21086 C3 - 1.00 €", label: "YC 21086 C3 - 1.00 €" },
  { value: "YC 21093 C2 - 1.00 €", label: "YC 21093 C2 - 1.00 €" },
  { value: "YC 21105 C2 - 1.00 €", label: "YC 21105 C2 - 1.00 €" },
  { value: "YC 21105 C3 - 1.00 €", label: "YC 21105 C3 - 1.00 €" },
  { value: "YC 21122 C4 - 1.00 €", label: "YC 21122 C4 - 1.00 €" },
  { value: "YC 21131 C3 - 1.00 €", label: "YC 21131 C3 - 1.00 €" },
  { value: "YC 21144 C3 - 1.00 €", label: "YC 21144 C3 - 1.00 €" },
  { value: "YC 21144 C4 - 1.00 €", label: "YC 21144 C4 - 1.00 €" },
  { value: "YC 21145 C3 - 1.00 €", label: "YC 21145 C3 - 1.00 €" },
  { value: "YC 21145 C4 - 1.00 €", label: "YC 21145 C4 - 1.00 €" },
  { value: "YC 21146 C1 - 1.00 €", label: "YC 21146 C1 - 1.00 €" },
  { value: "YC 21147 C1 - 1.00 €", label: "YC 21147 C1 - 1.00 €" },
  { value: "YC 21148 C3 - 1.00 €", label: "YC 21148 C3 - 1.00 €" },
  { value: "YC 21148 C4 - 1.00 €", label: "YC 21148 C4 - 1.00 €" },
  { value: "YC 21149 C1 - 1.00 €", label: "YC 21149 C1 - 1.00 €" },
  { value: "YC 21151 C4 - 1.00 €", label: "YC 21151 C4 - 1.00 €" },
  { value: "YC 21155 C2 - 1.00 €", label: "YC 21155 C2 - 1.00 €" },
  { value: "YC 21155 C3 - 1.00 €", label: "YC 21155 C3 - 1.00 €" },
  { value: "YC 21156 C2 - 1.00 €", label: "YC 21156 C2 - 1.00 €" },
  { value: "YC 21158 C1 - 1.00 €", label: "YC 21158 C1 - 1.00 €" },
  { value: "YC 21158 C3 - 1.00 €", label: "YC 21158 C3 - 1.00 €" },
  { value: "YC 21158 C4 - 1.00 €", label: "YC 21158 C4 - 1.00 €" },
  { value: "YC 21159 C4 - 1.00 €", label: "YC 21159 C4 - 1.00 €" },
  { value: "YC 21161 C3 - 1.00 €", label: "YC 21161 C3 - 1.00 €" },
  { value: "YC 21163 C2 - 1.00 €", label: "YC 21163 C2 - 1.00 €" },
  { value: "YC 21163 C3 - 1.00 €", label: "YC 21163 C3 - 1.00 €" },
  { value: "YC 21166 C2 - 1.00 €", label: "YC 21166 C2 - 1.00 €" },
  { value: "YC 21184 C4 - 1.00 €", label: "YC 21184 C4 - 1.00 €" },
  { value: "YC 2171 C1 - 1.00 €", label: "YC 2171 C1 - 1.00 €" },
  { value: "YC 22002 C1 - 1.00 €", label: "YC 22002 C1 - 1.00 €" },
  { value: "YC 22002 C4 - 1.00 €", label: "YC 22002 C4 - 1.00 €" },
  { value: "YC 22031 C2 - 1.00 €", label: "YC 22031 C2 - 1.00 €" },
  { value: "YC 22036 C4 - 1.00 €", label: "YC 22036 C4 - 1.00 €" },
  { value: "YC 22041 C4 - 1.00 €", label: "YC 22041 C4 - 1.00 €" },
  { value: "YC 22043 C3 - 1.00 €", label: "YC 22043 C3 - 1.00 €" },
  { value: "YC 22050 C4 - 1.00 €", label: "YC 22050 C4 - 1.00 €" },
  { value: "YC 22057 C1 - 1.00 €", label: "YC 22057 C1 - 1.00 €" },
  { value: "YC 22057 C4 - 1.00 €", label: "YC 22057 C4 - 1.00 €" },
  { value: "YC 22062 C3 - 1.00 €", label: "YC 22062 C3 - 1.00 €" },
  { value: "YC 22081 C1 - 1.00 €", label: "YC 22081 C1 - 1.00 €" },
  { value: "YC 22082 C3 - 1.00 €", label: "YC 22082 C3 - 1.00 €" },
  { value: "YC 22082 C4 - 1.00 €", label: "YC 22082 C4 - 1.00 €" },
  { value: "YC 22095 C2 - 1.00 €", label: "YC 22095 C2 - 1.00 €" },
  { value: "YC 22095 C3 - 1.00 €", label: "YC 22095 C3 - 1.00 €" },
  { value: "YC 22101 C1 - 1.00 €", label: "YC 22101 C1 - 1.00 €" },
  { value: "YC 22101 C3 - 1.00 €", label: "YC 22101 C3 - 1.00 €" },
  { value: "YC 22102 C4 - 1.00 €", label: "YC 22102 C4 - 1.00 €" },
  { value: "YC 22122 C4 - 1.00 €", label: "YC 22122 C4 - 1.00 €" },
  { value: "YC 23043 C2 - 1.00 €", label: "YC 23043 C2 - 1.00 €" },
  { value: "YC 23043 C3 - 1.00 €", label: "YC 23043 C3 - 1.00 €" },
  { value: "YC 23052 C4 - 1.00 €", label: "YC 23052 C4 - 1.00 €" },
  { value: "YC 23055 C1 - 1.00 €", label: "YC 23055 C1 - 1.00 €" },
  { value: "YC 23055 C2 - 1.00 €", label: "YC 23055 C2 - 1.00 €" },
  { value: "YC 23057 C3 - 1.00 €", label: "YC 23057 C3 - 1.00 €" },
  { value: "YC 23057 C4 - 1.00 €", label: "YC 23057 C4 - 1.00 €" },
  { value: "YC 23061 C4 - 1.00 €", label: "YC 23061 C4 - 1.00 €" },
  { value: "YC 23087 C4 - 1.00 €", label: "YC 23087 C4 - 1.00 €" },
  { value: "YC 23112 C1 - 1.00 €", label: "YC 23112 C1 - 1.00 €" },
  { value: "YC 23112 C2 - 1.00 €", label: "YC 23112 C2 - 1.00 €" },
  { value: "YC 23121 C1 - 1.00 €", label: "YC 23121 C1 - 1.00 €" },
  { value: "YC 23121 C3 - 1.00 €", label: "YC 23121 C3 - 1.00 €" },
  { value: "YC 23125 C1 - 1.00 €", label: "YC 23125 C1 - 1.00 €" },
  { value: "YC 23125 C2 - 1.00 €", label: "YC 23125 C2 - 1.00 €" },
  { value: "YC 23125 C3 - 1.00 €", label: "YC 23125 C3 - 1.00 €" },
  { value: "YC 23139 C1 - 1.00 €", label: "YC 23139 C1 - 1.00 €" },
  { value: "YC 23139 C4 - 1.00 €", label: "YC 23139 C4 - 1.00 €" },
  { value: "YC 23142 C1 - 1.00 €", label: "YC 23142 C1 - 1.00 €" },
  { value: "YC 23142 C2 - 1.00 €", label: "YC 23142 C2 - 1.00 €" },
  { value: "YC 23142 C3 - 1.00 €", label: "YC 23142 C3 - 1.00 €" },
  { value: "YC 23153 C3 - 1.00 €", label: "YC 23153 C3 - 1.00 €" },
  { value: "YC 23168 C2 - 1.00 €", label: "YC 23168 C2 - 1.00 €" },
  { value: "YC 23168 C3 - 1.00 €", label: "YC 23168 C3 - 1.00 €" },
  { value: "YC 23168 C4 - 1.00 €", label: "YC 23168 C4 - 1.00 €" },
  { value: "YC 23170 C1 - 1.00 €", label: "YC 23170 C1 - 1.00 €" },
  { value: "YC 23170 C3 - 1.00 €", label: "YC 23170 C3 - 1.00 €" },
  { value: "YC 23171 C1 - 1.00 €", label: "YC 23171 C1 - 1.00 €" },
  { value: "YC 23171 C3 - 1.00 €", label: "YC 23171 C3 - 1.00 €" },
  { value: "YC 23171 C4 - 1.00 €", label: "YC 23171 C4 - 1.00 €" },
  { value: "YC 23174 C4 - 1.00 €", label: "YC 23174 C4 - 1.00 €" },
  { value: "YC 25008 C2 - 1.00 €", label: "YC 25008 C2 - 1.00 €" },
  { value: "YC 26007 C4 - 1.00 €", label: "YC 26007 C4 - 1.00 €" },
  { value: "YC 26008 C1 - 1.00 €", label: "YC 26008 C1 - 1.00 €" },
  { value: "YC 26008 C2 - 1.00 €", label: "YC 26008 C2 - 1.00 €" },
  { value: "YC 26008 C4 - 1.00 €", label: "YC 26008 C4 - 1.00 €" },
  { value: "YC 26010 C1 - 1.00 €", label: "YC 26010 C1 - 1.00 €" },
  { value: "YC 26010 C2 - 1.00 €", label: "YC 26010 C2 - 1.00 €" },
  { value: "YC 26012 C2 - 1.00 €", label: "YC 26012 C2 - 1.00 €" },
  { value: "YC 26019 C1 - 1.00 €", label: "YC 26019 C1 - 1.00 €" },
  { value: "YC 26027 C2 - 1.00 €", label: "YC 26027 C2 - 1.00 €" },
  { value: "YC 26028 C1 - 1.00 €", label: "YC 26028 C1 - 1.00 €" },
  { value: "YC 26028 C3 - 1.00 €", label: "YC 26028 C3 - 1.00 €" },
  { value: "YC 26032 C1 - 1.00 €", label: "YC 26032 C1 - 1.00 €" },
  { value: "YC 26042 C2 - 1.00 €", label: "YC 26042 C2 - 1.00 €" },
  { value: "YC 26042 C4 - 1.00 €", label: "YC 26042 C4 - 1.00 €" },
  { value: "YC 26048 C2 - 1.00 €", label: "YC 26048 C2 - 1.00 €" },
  { value: "YC 26048 C3 - 1.00 €", label: "YC 26048 C3 - 1.00 €" },
  { value: "YC 26048 C4 - 1.00 €", label: "YC 26048 C4 - 1.00 €" },
  { value: "YC 26049 C2 - 1.00 €", label: "YC 26049 C2 - 1.00 €" },
  { value: "YC 26049 C4 - 1.00 €", label: "YC 26049 C4 - 1.00 €" },
  { value: "YC 28004 C1 - 1.00 €", label: "YC 28004 C1 - 1.00 €" },
  { value: "YC 28018 C1 - 1.00 €", label: "YC 28018 C1 - 1.00 €" },
  { value: "YC 28022 C1 - 1.00 €", label: "YC 28022 C1 - 1.00 €" },
  { value: "YC 28027 C2 - 1.00 €", label: "YC 28027 C2 - 1.00 €" },
  { value: "YC 28032 C3 - 1.00 €", label: "YC 28032 C3 - 1.00 €" },
  { value: "YC 28034 C4 - 1.00 €", label: "YC 28034 C4 - 1.00 €" },
  { value: "YC 28039 C3 - 1.00 €", label: "YC 28039 C3 - 1.00 €" },
  { value: "YC 28052 C1 - 1.00 €", label: "YC 28052 C1 - 1.00 €" },
  { value: "YC 28052 C2 - 1.00 €", label: "YC 28052 C2 - 1.00 €" },
  { value: "YC 28055 C3 - 1.00 €", label: "YC 28055 C3 - 1.00 €" },
  { value: "YC 29001 C2 - 1.00 €", label: "YC 29001 C2 - 1.00 €" },
  { value: "YC 29001 C3 - 1.00 €", label: "YC 29001 C3 - 1.00 €" },
  { value: "YC 29002 C1 - 1.00 €", label: "YC 29002 C1 - 1.00 €" },
  { value: "YC 29003 C1 - 1.00 €", label: "YC 29003 C1 - 1.00 €" },
  { value: "YC 29003 C4 - 1.00 €", label: "YC 29003 C4 - 1.00 €" },
  { value: "YC 29005 C2 - 1.00 €", label: "YC 29005 C2 - 1.00 €" },
  { value: "YC 29006 C2 - 1.00 €", label: "YC 29006 C2 - 1.00 €" },
  { value: "YC 29008 C4 - 1.00 €", label: "YC 29008 C4 - 1.00 €" },
  { value: "YC 29009 C1 - 1.00 €", label: "YC 29009 C1 - 1.00 €" },
  { value: "YC 29009 C2 - 1.00 €", label: "YC 29009 C2 - 1.00 €" },
  { value: "YC 29009 C3 - 1.00 €", label: "YC 29009 C3 - 1.00 €" },
  { value: "YC 29009 C4 - 1.00 €", label: "YC 29009 C4 - 1.00 €" },
  { value: "YC 29011 C1 - 1.00 €", label: "YC 29011 C1 - 1.00 €" },
  { value: "YC 29011 C4 - 1.00 €", label: "YC 29011 C4 - 1.00 €" },
  { value: "YC 29013 C1 - 1.00 €", label: "YC 29013 C1 - 1.00 €" },
  { value: "YC 29013 C2 - 1.00 €", label: "YC 29013 C2 - 1.00 €" },
  { value: "YC 29024 C1 - 1.00 €", label: "YC 29024 C1 - 1.00 €" },
  { value: "YC 29025 C1 - 1.00 €", label: "YC 29025 C1 - 1.00 €" },
  { value: "YC 29031 C2 - 1.00 €", label: "YC 29031 C2 - 1.00 €" },
  { value: "YC 29031 C4 - 1.00 €", label: "YC 29031 C4 - 1.00 €" },
  { value: "YC 29032 C1 - 1.00 €", label: "YC 29032 C1 - 1.00 €" },
  { value: "YC 29032 C2 - 1.00 €", label: "YC 29032 C2 - 1.00 €" },
  { value: "YC 29032 C3 - 1.00 €", label: "YC 29032 C3 - 1.00 €" },
  { value: "YC 29032 C4 - 1.00 €", label: "YC 29032 C4 - 1.00 €" },
  { value: "YC 29033 C3 - 1.00 €", label: "YC 29033 C3 - 1.00 €" },
  { value: "YC 29034 C3 - 1.00 €", label: "YC 29034 C3 - 1.00 €" },
  { value: "YC 29035 C4 - 1.00 €", label: "YC 29035 C4 - 1.00 €" },
  { value: "YC 29047 C1 - 1.00 €", label: "YC 29047 C1 - 1.00 €" },
  { value: "YC 29047 C3 - 1.00 €", label: "YC 29047 C3 - 1.00 €" },
  { value: "YC 29048 C1 - 1.00 €", label: "YC 29048 C1 - 1.00 €" },
  { value: "YC 29063 C1 - 1.00 €", label: "YC 29063 C1 - 1.00 €" },
  { value: "YC 29063 C4 - 1.00 €", label: "YC 29063 C4 - 1.00 €" },
  { value: "YC 29065 C1 - 1.00 €", label: "YC 29065 C1 - 1.00 €" },
  { value: "YC 29065 C2 - 1.00 €", label: "YC 29065 C2 - 1.00 €" },
  { value: "YC 30015 C1 - 1.00 €", label: "YC 30015 C1 - 1.00 €" },
  { value: "YC 30015 C3 - 1.00 €", label: "YC 30015 C3 - 1.00 €" },
  { value: "YC 30017 C1 - 1.00 €", label: "YC 30017 C1 - 1.00 €" },
  { value: "YC 30017 C2 - 1.00 €", label: "YC 30017 C2 - 1.00 €" },
  { value: "YC 30041 C1 - 1.00 €", label: "YC 30041 C1 - 1.00 €" },
  { value: "YC 30041 C2 - 1.00 €", label: "YC 30041 C2 - 1.00 €" },
  { value: "YC 30049 C4 - 1.00 €", label: "YC 30049 C4 - 1.00 €" },
  { value: "YC 30057 C2 - 1.00 €", label: "YC 30057 C2 - 1.00 €" },
  { value: "YC 31064 C2 - 1.00 €", label: "YC 31064 C2 - 1.00 €" },
  { value: "YC 31064 C3 - 1.00 €", label: "YC 31064 C3 - 1.00 €" },
  { value: "YC 31064 C4 - 1.00 €", label: "YC 31064 C4 - 1.00 €" },
  { value: "YC 31069 C1 - 1.00 €", label: "YC 31069 C1 - 1.00 €" },
  { value: "YC 31072 C4 - 1.00 €", label: "YC 31072 C4 - 1.00 €" },
  { value: "YC 31078 C1 - 1.00 €", label: "YC 31078 C1 - 1.00 €" },
  { value: "YC 31085 C1 - 1.00 €", label: "YC 31085 C1 - 1.00 €" },
  { value: "YC 313 C1 - 1.00 €", label: "YC 313 C1 - 1.00 €" },
  { value: "YC 313 C4 - 1.00 €", label: "YC 313 C4 - 1.00 €" },
  { value: "YC 33059 C1 - 1.00 €", label: "YC 33059 C1 - 1.00 €" },
  { value: "YC 33063 C1 - 1.00 €", label: "YC 33063 C1 - 1.00 €" },
  { value: "YC 34009 C2 - 1.00 €", label: "YC 34009 C2 - 1.00 €" },
  { value: "YC 34009 C3 - 1.00 €", label: "YC 34009 C3 - 1.00 €" },
  { value: "YC 39003 C5 - 1.00 €", label: "YC 39003 C5 - 1.00 €" },
  { value: "YC 39027 C1 - 1.00 €", label: "YC 39027 C1 - 1.00 €" },
  { value: "YC 39028 C2 - 1.00 €", label: "YC 39028 C2 - 1.00 €" },
  { value: "YC 39063 C3 - 1.00 €", label: "YC 39063 C3 - 1.00 €" },
  { value: "YC 39072 C4 - 1.00 €", label: "YC 39072 C4 - 1.00 €" },
  { value: "YC 60019 C1 - 1.00 €", label: "YC 60019 C1 - 1.00 €" },
  { value: "YC 60019 C4 - 1.00 €", label: "YC 60019 C4 - 1.00 €" },
  { value: "YC 60023 C7 - 1.00 €", label: "YC 60023 C7 - 1.00 €" },
  { value: "YC 60023 C8 - 1.00 €", label: "YC 60023 C8 - 1.00 €" },
  { value: "YC 60026 C1 - 1.00 €", label: "YC 60026 C1 - 1.00 €" },
  { value: "YC 60026 C4 - 1.00 €", label: "YC 60026 C4 - 1.00 €" },
  { value: "YC 60031 C4 - 1.00 €", label: "YC 60031 C4 - 1.00 €" },
  { value: "YC 60032 C6 - 1.00 €", label: "YC 60032 C6 - 1.00 €" },
  { value: "YC 60036 C1 - 1.00 €", label: "YC 60036 C1 - 1.00 €" },
  { value: "YC 60036 C4 - 1.00 €", label: "YC 60036 C4 - 1.00 €" },
  { value: "YC 60036 C7 - 1.00 €", label: "YC 60036 C7 - 1.00 €" },
  { value: "YC 60037 C4 - 1.00 €", label: "YC 60037 C4 - 1.00 €" },
  { value: "YC 60051 C7 - 1.00 €", label: "YC 60051 C7 - 1.00 €" },
  { value: "YC 63006 C5 - 1.00 €", label: "YC 63006 C5 - 1.00 €" },
  { value: "YC 63006 C6 - 1.00 €", label: "YC 63006 C6 - 1.00 €" },
  { value: "YC 63038 C6 - 1.00 €", label: "YC 63038 C6 - 1.00 €" },
  { value: "YC 8087 C1 - 1.00 €", label: "YC 8087 C1 - 1.00 €" },
  { value: "YC 8087 C3 - 1.00 €", label: "YC 8087 C3 - 1.00 €" },
  { value: "YC 9609 C1 - 1.00 €", label: "YC 9609 C1 - 1.00 €" },
  { value: "YC 9609 C3 - 1.00 €", label: "YC 9609 C3 - 1.00 €" },
  { value: "YC 25025 C2 - 1.00 €", label: "YC 25025 C2 - 1.00 €" },
  { value: "GC 001 C2 - 1.00 €", label: "GC 001 C2 - 1.00 €" },
  { value: "GC 001 C3 - 1.00 €", label: "GC 001 C3 - 1.00 €" },
  { value: "GC 002 C2 - 1.00 €", label: "GC 002 C2 - 1.00 €" },
  { value: "GC 002 C3 - 1.00 €", label: "GC 002 C3 - 1.00 €" },
  { value: "GC 003 C2 - 1.00 €", label: "GC 003 C2 - 1.00 €" },
  { value: "GC 003 C3 - 1.00 €", label: "GC 003 C3 - 1.00 €" },
  { value: "GC 004 C1 - 1.00 €", label: "GC 004 C1 - 1.00 €" },
  { value: "GC 004 C2 - 1.00 €", label: "GC 004 C2 - 1.00 €" },
  { value: "GC 004 C3 - 1.00 €", label: "GC 004 C3 - 1.00 €" },
  { value: "GC 005 C3 - 1.00 €", label: "GC 005 C3 - 1.00 €" },
  { value: "GC 006 C1 - 1.00 €", label: "GC 006 C1 - 1.00 €" },
  { value: "GC 006 C3 - 1.00 €", label: "GC 006 C3 - 1.00 €" },
  { value: "GC 007 C3 - 1.00 €", label: "GC 007 C3 - 1.00 €" },
  { value: "62733 C2 - 1.00 €", label: "62733 C2 - 1.00 €" },
  { value: "62733 C3 - 1.00 €", label: "62733 C3 - 1.00 €" },
  { value: "YC 11057 C2 - 1.00 €", label: "YC 11057 C2 - 1.00 €" },
];
export const Monture34 = [
  { value: "", label: "" },
  { value: "Autre - 15.50 €", label: "Autre - 15.50 €" },
  { value: "62582 C2 - 15.50 €", label: "62582 C2 - 15.50 €" },
  { value: "62643 C2-5 - 15.50 €", label: "62643 C2-5 - 15.50 €" },
  { value: "62720 C2 - 15.50 €", label: "62720 C2 - 15.50 €" },
  { value: "62722 C3 - 15.50 €", label: "62722 C3 - 15.50 €" },
  { value: "62725 C3 - 15.50 €", label: "62725 C3 - 15.50 €" },
  { value: "62733 C1 - 15.50 €", label: "62733 C1 - 15.50 €" },
  { value: "62946 C2-4 - 15.50 €", label: "62946 C2-4 - 15.50 €" },
  { value: "B 6109 C2 - 15.50 €", label: "B 6109 C2 - 15.50 €" },
  { value: "BT 0012 C2 - 15.50 €", label: "BT 0012 C2 - 15.50 €" },
  { value: "BT 0012 C4 - 15.50 €", label: "BT 0012 C4 - 15.50 €" },
  { value: "BT 0012 C6 - 15.50 €", label: "BT 0012 C6 - 15.50 €" },
  { value: "BT 0012 C8 - 15.50 €", label: "BT 0012 C8 - 15.50 €" },
  { value: "BT 0014 C2 - 15.50 €", label: "BT 0014 C2 - 15.50 €" },
  { value: "BT 0014 C6 - 15.50 €", label: "BT 0014 C6 - 15.50 €" },
  { value: "BT 0014 C8 - 15.50 €", label: "BT 0014 C8 - 15.50 €" },
  { value: "BT 0025 C3B - 15.50 €", label: "BT 0025 C3B - 15.50 €" },
  { value: "CG 014 C1 - 15.50 €", label: "CG 014 C1 - 15.50 €" },
  { value: "CG 014 C2 - 15.50 €", label: "CG 014 C2 - 15.50 €" },
  { value: "CG 014 C3 - 15.50 €", label: "CG 014 C3 - 15.50 €" },
  { value: "CG 014 C4 - 15.50 €", label: "CG 014 C4 - 15.50 €" },
  { value: "CG 014 C5 - 15.50 €", label: "CG 014 C5 - 15.50 €" },
  { value: "CG 016 C2 - 15.50 €", label: "CG 016 C2 - 15.50 €" },
  { value: "CG 016 C3 - 15.50 €", label: "CG 016 C3 - 15.50 €" },
  { value: "CG 017 C2 - 15.50 €", label: "CG 017 C2 - 15.50 €" },
  { value: "CG 017 C3 - 15.50 €", label: "CG 017 C3 - 15.50 €" },
  { value: "CG 019 C1 - 15.50 €", label: "CG 019 C1 - 15.50 €" },
  { value: "CG 019 C2 - 15.50 €", label: "CG 019 C2 - 15.50 €" },
  { value: "CG 019 C3 - 15.50 €", label: "CG 019 C3 - 15.50 €" },
  { value: "CG 020 C2 - 15.50 €", label: "CG 020 C2 - 15.50 €" },
  { value: "CG 021 C2 - 15.50 €", label: "CG 021 C2 - 15.50 €" },
  { value: "CG 021 C3 - 15.50 €", label: "CG 021 C3 - 15.50 €" },
  { value: "CG 024 C1 - 15.50 €", label: "CG 024 C1 - 15.50 €" },
  { value: "CG 024 C3 - 15.50 €", label: "CG 024 C3 - 15.50 €" },
  { value: "IN-2207 N01 - 15.50 €", label: "IN-2207 N01 - 15.50 €" },
  { value: "IN-2207 N02 - 15.50 €", label: "IN-2207 N02 - 15.50 €" },
  { value: "IN-2208 N03 - 15.50 €", label: "IN-2208 N03 - 15.50 €" },
  { value: "IN-2209 C11 - 15.50 €", label: "IN-2209 C11 - 15.50 €" },
  { value: "IN-2210 N02 - 15.50 €", label: "IN-2210 N02 - 15.50 €" },
  { value: "IN-2210 N03 - 15.50 €", label: "IN-2210 N03 - 15.50 €" },
  { value: "IN-2212 N03 - 15.50 €", label: "IN-2212 N03 - 15.50 €" },
  { value: "IN-2214 N03 - 15.50 €", label: "IN-2214 N03 - 15.50 €" },
  { value: "IN-2216 N01 - 15.50 €", label: "IN-2216 N01 - 15.50 €" },
  { value: "IN-2216 N02 - 15.50 €", label: "IN-2216 N02 - 15.50 €" },
  { value: "IN-2219 N01 - 15.50 €", label: "IN-2219 N01 - 15.50 €" },
  { value: "IN-2219 N02 - 15.50 €", label: "IN-2219 N02 - 15.50 €" },
  { value: "IN-2220 N02 - 15.50 €", label: "IN-2220 N02 - 15.50 €" },
  { value: "SYM A 01 006 - 15.50 €", label: "SYM A 01 006 - 15.50 €" },
  { value: "SYM A 01 009 - 15.50 €", label: "SYM A 01 009 - 15.50 €" },
  { value: "SYM A 01 011 - 15.50 €", label: "SYM A 01 011 - 15.50 €" },
  { value: "SYM A 01 224 - 15.50 €", label: "SYM A 01 224 - 15.50 €" },
  { value: "SYM A 01 236 - 15.50 €", label: "SYM A 01 236 - 15.50 €" },
  { value: "SYM A 02 001 - 15.50 €", label: "SYM A 02 001 - 15.50 €" },
  { value: "SYM A 02 002 - 15.50 €", label: "SYM A 02 002 - 15.50 €" },
  { value: "SYM A 02 013 - 15.50 €", label: "SYM A 02 013 - 15.50 €" },
  { value: "SYM A 03 003 - 15.50 €", label: "SYM A 03 003 - 15.50 €" },
  { value: "SYM A 03 008 - 15.50 €", label: "SYM A 03 008 - 15.50 €" },
  { value: "SYM A 03 011 - 15.50 €", label: "SYM A 03 011 - 15.50 €" },
  { value: "SYM A 03 013 - 15.50 €", label: "SYM A 03 013 - 15.50 €" },
  { value: "SYM A 03 016 - 15.50 €", label: "SYM A 03 016 - 15.50 €" },
  { value: "SYM A 04 004 - 15.50 €", label: "SYM A 04 004 - 15.50 €" },
  { value: "SYM A 04 005 - 15.50 €", label: "SYM A 04 005 - 15.50 €" },
  { value: "SYM A 04 014 - 15.50 €", label: "SYM A 04 014 - 15.50 €" },
  { value: "SYM A 04 257 - 15.50 €", label: "SYM A 04 257 - 15.50 €" },
  { value: "SYM A 05 001 - 15.50 €", label: "SYM A 05 001 - 15.50 €" },
  { value: "SYM A 05 010 - 15.50 €", label: "SYM A 05 010 - 15.50 €" },
  { value: "SYM A 05 011 - 15.50 €", label: "SYM A 05 011 - 15.50 €" },
  { value: "SYM A 05 013 - 15.50 €", label: "SYM A 05 013 - 15.50 €" },
  { value: "SYM A 06 004 - 15.50 €", label: "SYM A 06 004 - 15.50 €" },
  { value: "SYM A 06 008 - 15.50 €", label: "SYM A 06 008 - 15.50 €" },
  { value: "SYM A 06 012 - 15.50 €", label: "SYM A 06 012 - 15.50 €" },
  { value: "SYM A 06 014 - 15.50 €", label: "SYM A 06 014 - 15.50 €" },
  { value: "SYM A 06 015 - 15.50 €", label: "SYM A 06 015 - 15.50 €" },
  { value: "SYM A 07 217 - 15.50 €", label: "SYM A 07 217 - 15.50 €" },
  { value: "SYM A 07 218 - 15.50 €", label: "SYM A 07 218 - 15.50 €" },
  { value: "SYM A 07 219 - 15.50 €", label: "SYM A 07 219 - 15.50 €" },
  { value: "SYM A 07 220 - 15.50 €", label: "SYM A 07 220 - 15.50 €" },
  { value: "SYM A 07 245 - 15.50 €", label: "SYM A 07 245 - 15.50 €" },
  { value: "SYM A 08 245 - 15.50 €", label: "SYM A 08 245 - 15.50 €" },
  { value: "SYM B 01 224 - 15.50 €", label: "SYM B 01 224 - 15.50 €" },
  { value: "SYM B 01 225 - 15.50 €", label: "SYM B 01 225 - 15.50 €" },
  { value: "SYM B 01 245 - 15.50 €", label: "SYM B 01 245 - 15.50 €" },
  { value: "SYM B 02 001 - 15.50 €", label: "SYM B 02 001 - 15.50 €" },
  { value: "SYM B 02 013 - 15.50 €", label: "SYM B 02 013 - 15.50 €" },
  { value: "SYM B 02 226 - 15.50 €", label: "SYM B 02 226 - 15.50 €" },
  { value: "SYM B 03 014 - 15.50 €", label: "SYM B 03 014 - 15.50 €" },
  { value: "SYM B 03 227 - 15.50 €", label: "SYM B 03 227 - 15.50 €" },
  { value: "SYM B 03 228 - 15.50 €", label: "SYM B 03 228 - 15.50 €" },
  { value: "SYM B 04 014 - 15.50 €", label: "SYM B 04 014 - 15.50 €" },
  { value: "SYM B 04 230 - 15.50 €", label: "SYM B 04 230 - 15.50 €" },
  { value: "SYM B 04 231 - 15.50 €", label: "SYM B 04 231 - 15.50 €" },
  { value: "SYM B 04 257 - 15.50 €", label: "SYM B 04 257 - 15.50 €" },
  { value: "SYM B 05 232 - 15.50 €", label: "SYM B 05 232 - 15.50 €" },
  { value: "SYM B 05 233 - 15.50 €", label: "SYM B 05 233 - 15.50 €" },
  { value: "SYM B 05 234 - 15.50 €", label: "SYM B 05 234 - 15.50 €" },
  { value: "SYM B 06 004 - 15.50 €", label: "SYM B 06 004 - 15.50 €" },
  { value: "SYM B 06 014 - 15.50 €", label: "SYM B 06 014 - 15.50 €" },
  { value: "SYM B 06 015 - 15.50 €", label: "SYM B 06 015 - 15.50 €" },
  { value: "SYM B 06 235 - 15.50 €", label: "SYM B 06 235 - 15.50 €" },
  { value: "SYM C 01 236 - 15.50 €", label: "SYM C 01 236 - 15.50 €" },
  { value: "SYM C 01 237 - 15.50 €", label: "SYM C 01 237 - 15.50 €" },
  { value: "SYM C 01 238 - 15.50 €", label: "SYM C 01 238 - 15.50 €" },
  { value: "SYM C 01 239 - 15.50 €", label: "SYM C 01 239 - 15.50 €" },
  { value: "SYM C 01 245 - 15.50 €", label: "SYM C 01 245 - 15.50 €" },
  { value: "SYM C 02 025 - 15.50 €", label: "SYM C 02 025 - 15.50 €" },
  { value: "SYM C 02 233 - 15.50 €", label: "SYM C 02 233 - 15.50 €" },
  { value: "SYM C 02 246 - 15.50 €", label: "SYM C 02 246 - 15.50 €" },
  { value: "SYM C 02 247 - 15.50 €", label: "SYM C 02 247 - 15.50 €" },
  { value: "SYM C 02 257 - 15.50 €", label: "SYM C 02 257 - 15.50 €" },
  { value: "SYM C 03 016 - 15.50 €", label: "SYM C 03 016 - 15.50 €" },
  { value: "SYM C 03 140 - 15.50 €", label: "SYM C 03 140 - 15.50 €" },
  { value: "SYM C 03 240 - 15.50 €", label: "SYM C 03 240 - 15.50 €" },
  { value: "SYM C 03 248 - 15.50 €", label: "SYM C 03 248 - 15.50 €" },
  { value: "SYM C 04 014 - 15.50 €", label: "SYM C 04 014 - 15.50 €" },
  { value: "SYM C 04 224 - 15.50 €", label: "SYM C 04 224 - 15.50 €" },
  { value: "SYM C 04 228 - 15.50 €", label: "SYM C 04 228 - 15.50 €" },
  { value: "SYM C 04 230 - 15.50 €", label: "SYM C 04 230 - 15.50 €" },
  { value: "SYM C 04 241 - 15.50 €", label: "SYM C 04 241 - 15.50 €" },
  { value: "SYM C 05 242 - 15.50 €", label: "SYM C 05 242 - 15.50 €" },
  { value: "SYM C 05 243 - 15.50 €", label: "SYM C 05 243 - 15.50 €" },
  { value: "SYM C 05 244 - 15.50 €", label: "SYM C 05 244 - 15.50 €" },
  { value: "SYM K 01 151 - 15.50 €", label: "SYM K 01 151 - 15.50 €" },
  { value: "SYM K 01 252 - 15.50 €", label: "SYM K 01 252 - 15.50 €" },
  { value: "SYM K 01 253 - 15.50 €", label: "SYM K 01 253 - 15.50 €" },
  { value: "SYM K 01 254 - 15.50 €", label: "SYM K 01 254 - 15.50 €" },
  { value: "SYM K 01 255 - 15.50 €", label: "SYM K 01 255 - 15.50 €" },
  { value: "SYM K 02 251 - 15.50 €", label: "SYM K 02 251 - 15.50 €" },
  { value: "SYM K 02 252 - 15.50 €", label: "SYM K 02 252 - 15.50 €" },
  { value: "SYM K 02 253 - 15.50 €", label: "SYM K 02 253 - 15.50 €" },
  { value: "SYM K 02 254 - 15.50 €", label: "SYM K 02 254 - 15.50 €" },
  { value: "SYM K 02 255 - 15.50 €", label: "SYM K 02 255 - 15.50 €" },
  { value: "SYM K 03 251 - 15.50 €", label: "SYM K 03 251 - 15.50 €" },
  { value: "SYM K 03 252 - 15.50 €", label: "SYM K 03 252 - 15.50 €" },
  { value: "SYM K 03 253 - 15.50 €", label: "SYM K 03 253 - 15.50 €" },
  { value: "SYM K 03 254 - 15.50 €", label: "SYM K 03 254 - 15.50 €" },
  { value: "SYM K 03 255 - 15.50 €", label: "SYM K 03 255 - 15.50 €" },
  { value: "SYM K 04 251 - 15.50 €", label: "SYM K 04 251 - 15.50 €" },
  { value: "SYM K 04 252 - 15.50 €", label: "SYM K 04 252 - 15.50 €" },
  { value: "SYM K 04 253 - 15.50 €", label: "SYM K 04 253 - 15.50 €" },
  { value: "SYM K 04 254 - 15.50 €", label: "SYM K 04 254 - 15.50 €" },
  { value: "SYM K 04 255 - 15.50 €", label: "SYM K 04 255 - 15.50 €" },
  { value: "YC 1091 C1 - 15.50 €", label: "YC 1091 C1 - 15.50 €" },
  { value: "YC 1091 C4 - 15.50 €", label: "YC 1091 C4 - 15.50 €" },
  { value: "YC 11022 C2 - 15.50 €", label: "YC 11022 C2 - 15.50 €" },
  { value: "YC 11022 C3 - 15.50 €", label: "YC 11022 C3 - 15.50 €" },
  { value: "YC 11023 C4 - 15.50 €", label: "YC 11023 C4 - 15.50 €" },
  { value: "YC 11024 C3 - 15.50 €", label: "YC 11024 C3 - 15.50 €" },
  { value: "YC 11025 C4 - 15.50 €", label: "YC 11025 C4 - 15.50 €" },
  { value: "YC 11028 C2 - 15.50 €", label: "YC 11028 C2 - 15.50 €" },
  { value: "YC 11029 C3 - 15.50 €", label: "YC 11029 C3 - 15.50 €" },
  { value: "YC 11029 C4 - 15.50 €", label: "YC 11029 C4 - 15.50 €" },
  { value: "YC 11030 C2 - 15.50 €", label: "YC 11030 C2 - 15.50 €" },
  { value: "YC 11030 C3 - 15.50 €", label: "YC 11030 C3 - 15.50 €" },
  { value: "YC 11030 C4 - 15.50 €", label: "YC 11030 C4 - 15.50 €" },
  { value: "YC 11031 C2 - 15.50 €", label: "YC 11031 C2 - 15.50 €" },
  { value: "YC 11032 C4 - 15.50 €", label: "YC 11032 C4 - 15.50 €" },
  { value: "YC 11037 C4 - 15.50 €", label: "YC 11037 C4 - 15.50 €" },
  { value: "YC 11040 C4 - 15.50 €", label: "YC 11040 C4 - 15.50 €" },
  { value: "YC 11052 C2 - 15.50 €", label: "YC 11052 C2 - 15.50 €" },
  { value: "YC 11063 C4 - 15.50 €", label: "YC 11063 C4 - 15.50 €" },
  { value: "YC 11064 C2 - 15.50 €", label: "YC 11064 C2 - 15.50 €" },
  { value: "YC 11068 C4 - 15.50 €", label: "YC 11068 C4 - 15.50 €" },
  { value: "YC 12003 C1 - 15.50 €", label: "YC 12003 C1 - 15.50 €" },
  { value: "YC 12003 C2 - 15.50 €", label: "YC 12003 C2 - 15.50 €" },
  { value: "YC 12003 C3 - 15.50 €", label: "YC 12003 C3 - 15.50 €" },
  { value: "YC 12003 C4 - 15.50 €", label: "YC 12003 C4 - 15.50 €" },
  { value: "YC 13001 C2 - 15.50 €", label: "YC 13001 C2 - 15.50 €" },
  { value: "YC 13002 C2 - 15.50 €", label: "YC 13002 C2 - 15.50 €" },
  { value: "YC 13002 C3 - 15.50 €", label: "YC 13002 C3 - 15.50 €" },
  { value: "YC 13003 C1 - 15.50 €", label: "YC 13003 C1 - 15.50 €" },
  { value: "YC 14044 C3 - 15.50 €", label: "YC 14044 C3 - 15.50 €" },
  { value: "YC 14045 C2 - 15.50 €", label: "YC 14045 C2 - 15.50 €" },
  { value: "YC 14047 C1 - 15.50 €", label: "YC 14047 C1 - 15.50 €" },
  { value: "YC 14047 C4 - 15.50 €", label: "YC 14047 C4 - 15.50 €" },
  { value: "YC 14047 C7 - 15.50 €", label: "YC 14047 C7 - 15.50 €" },
  { value: "YC 14048 C1 - 15.50 €", label: "YC 14048 C1 - 15.50 €" },
  { value: "YC 14048 C2 - 15.50 €", label: "YC 14048 C2 - 15.50 €" },
  { value: "YC 15035 C2 - 15.50 €", label: "YC 15035 C2 - 15.50 €" },
  { value: "YC 15085 C2 - 15.50 €", label: "YC 15085 C2 - 15.50 €" },
  { value: "YC 15085 C4 - 15.50 €", label: "YC 15085 C4 - 15.50 €" },
  { value: "YC 15086 C4 - 15.50 €", label: "YC 15086 C4 - 15.50 €" },
  { value: "YC 21001 C1 - 15.50 €", label: "YC 21001 C1 - 15.50 €" },
  { value: "YC 21001 C2 - 15.50 €", label: "YC 21001 C2 - 15.50 €" },
  { value: "YC 21001 C3 - 15.50 €", label: "YC 21001 C3 - 15.50 €" },
  { value: "YC 21001 C4 - 15.50 €", label: "YC 21001 C4 - 15.50 €" },
  { value: "YC 21002 C3 - 15.50 €", label: "YC 21002 C3 - 15.50 €" },
  { value: "YC 21007 C4 - 15.50 €", label: "YC 21007 C4 - 15.50 €" },
  { value: "YC 21009 C1 - 15.50 €", label: "YC 21009 C1 - 15.50 €" },
  { value: "YC 21012 C1 - 15.50 €", label: "YC 21012 C1 - 15.50 €" },
  { value: "YC 21012 C3 - 15.50 €", label: "YC 21012 C3 - 15.50 €" },
  { value: "YC 21012 C4 - 15.50 €", label: "YC 21012 C4 - 15.50 €" },
  { value: "YC 21013 C1 - 15.50 €", label: "YC 21013 C1 - 15.50 €" },
  { value: "YC 21013 C2 - 15.50 €", label: "YC 21013 C2 - 15.50 €" },
  { value: "YC 21013 C3 - 15.50 €", label: "YC 21013 C3 - 15.50 €" },
  { value: "YC 21013 C4 - 15.50 €", label: "YC 21013 C4 - 15.50 €" },
  { value: "YC 21014 C2 - 15.50 €", label: "YC 21014 C2 - 15.50 €" },
  { value: "YC 21014 C3 - 15.50 €", label: "YC 21014 C3 - 15.50 €" },
  { value: "YC 21015 C2 - 15.50 €", label: "YC 21015 C2 - 15.50 €" },
  { value: "YC 21026 C4 - 15.50 €", label: "YC 21026 C4 - 15.50 €" },
  { value: "YC 21031 C1 - 15.50 €", label: "YC 21031 C1 - 15.50 €" },
  { value: "YC 21031 C4 - 15.50 €", label: "YC 21031 C4 - 15.50 €" },
  { value: "YC 21034 C1 - 15.50 €", label: "YC 21034 C1 - 15.50 €" },
  { value: "YC 21034 C3 - 15.50 €", label: "YC 21034 C3 - 15.50 €" },
  { value: "YC 21034 C4 - 15.50 €", label: "YC 21034 C4 - 15.50 €" },
  { value: "YC 21036 C1 - 15.50 €", label: "YC 21036 C1 - 15.50 €" },
  { value: "YC 21038 C2 - 15.50 €", label: "YC 21038 C2 - 15.50 €" },
  { value: "YC 21039 C4 - 15.50 €", label: "YC 21039 C4 - 15.50 €" },
  { value: "YC 21061 C4 - 15.50 €", label: "YC 21061 C4 - 15.50 €" },
  { value: "YC 21077 C1 - 15.50 €", label: "YC 21077 C1 - 15.50 €" },
  { value: "YC 21086 C3 - 15.50 €", label: "YC 21086 C3 - 15.50 €" },
  { value: "YC 21093 C2 - 15.50 €", label: "YC 21093 C2 - 15.50 €" },
  { value: "YC 21105 C2 - 15.50 €", label: "YC 21105 C2 - 15.50 €" },
  { value: "YC 21105 C3 - 15.50 €", label: "YC 21105 C3 - 15.50 €" },
  { value: "YC 21122 C4 - 15.50 €", label: "YC 21122 C4 - 15.50 €" },
  { value: "YC 21131 C3 - 15.50 €", label: "YC 21131 C3 - 15.50 €" },
  { value: "YC 21144 C3 - 15.50 €", label: "YC 21144 C3 - 15.50 €" },
  { value: "YC 21144 C4 - 15.50 €", label: "YC 21144 C4 - 15.50 €" },
  { value: "YC 21145 C3 - 15.50 €", label: "YC 21145 C3 - 15.50 €" },
  { value: "YC 21145 C4 - 15.50 €", label: "YC 21145 C4 - 15.50 €" },
  { value: "YC 21146 C1 - 15.50 €", label: "YC 21146 C1 - 15.50 €" },
  { value: "YC 21147 C1 - 15.50 €", label: "YC 21147 C1 - 15.50 €" },
  { value: "YC 21148 C3 - 15.50 €", label: "YC 21148 C3 - 15.50 €" },
  { value: "YC 21148 C4 - 15.50 €", label: "YC 21148 C4 - 15.50 €" },
  { value: "YC 21149 C1 - 15.50 €", label: "YC 21149 C1 - 15.50 €" },
  { value: "YC 21151 C4 - 15.50 €", label: "YC 21151 C4 - 15.50 €" },
  { value: "YC 21155 C2 - 15.50 €", label: "YC 21155 C2 - 15.50 €" },
  { value: "YC 21155 C3 - 15.50 €", label: "YC 21155 C3 - 15.50 €" },
  { value: "YC 21156 C2 - 15.50 €", label: "YC 21156 C2 - 15.50 €" },
  { value: "YC 21158 C1 - 15.50 €", label: "YC 21158 C1 - 15.50 €" },
  { value: "YC 21158 C3 - 15.50 €", label: "YC 21158 C3 - 15.50 €" },
  { value: "YC 21158 C4 - 15.50 €", label: "YC 21158 C4 - 15.50 €" },
  { value: "YC 21159 C4 - 15.50 €", label: "YC 21159 C4 - 15.50 €" },
  { value: "YC 21161 C3 - 15.50 €", label: "YC 21161 C3 - 15.50 €" },
  { value: "YC 21163 C2 - 15.50 €", label: "YC 21163 C2 - 15.50 €" },
  { value: "YC 21163 C3 - 15.50 €", label: "YC 21163 C3 - 15.50 €" },
  { value: "YC 21166 C2 - 15.50 €", label: "YC 21166 C2 - 15.50 €" },
  { value: "YC 21184 C4 - 15.50 €", label: "YC 21184 C4 - 15.50 €" },
  { value: "YC 2171 C1 - 15.50 €", label: "YC 2171 C1 - 15.50 €" },
  { value: "YC 22002 C1 - 15.50 €", label: "YC 22002 C1 - 15.50 €" },
  { value: "YC 22002 C4 - 15.50 €", label: "YC 22002 C4 - 15.50 €" },
  { value: "YC 22031 C2 - 15.50 €", label: "YC 22031 C2 - 15.50 €" },
  { value: "YC 22036 C4 - 15.50 €", label: "YC 22036 C4 - 15.50 €" },
  { value: "YC 22041 C4 - 15.50 €", label: "YC 22041 C4 - 15.50 €" },
  { value: "YC 22043 C3 - 15.50 €", label: "YC 22043 C3 - 15.50 €" },
  { value: "YC 22050 C4 - 15.50 €", label: "YC 22050 C4 - 15.50 €" },
  { value: "YC 22057 C1 - 15.50 €", label: "YC 22057 C1 - 15.50 €" },
  { value: "YC 22057 C4 - 15.50 €", label: "YC 22057 C4 - 15.50 €" },
  { value: "YC 22062 C3 - 15.50 €", label: "YC 22062 C3 - 15.50 €" },
  { value: "YC 22081 C1 - 15.50 €", label: "YC 22081 C1 - 15.50 €" },
  { value: "YC 22082 C3 - 15.50 €", label: "YC 22082 C3 - 15.50 €" },
  { value: "YC 22082 C4 - 15.50 €", label: "YC 22082 C4 - 15.50 €" },
  { value: "YC 22095 C2 - 15.50 €", label: "YC 22095 C2 - 15.50 €" },
  { value: "YC 22095 C3 - 15.50 €", label: "YC 22095 C3 - 15.50 €" },
  { value: "YC 22101 C1 - 15.50 €", label: "YC 22101 C1 - 15.50 €" },
  { value: "YC 22101 C3 - 15.50 €", label: "YC 22101 C3 - 15.50 €" },
  { value: "YC 22102 C4 - 15.50 €", label: "YC 22102 C4 - 15.50 €" },
  { value: "YC 22122 C4 - 15.50 €", label: "YC 22122 C4 - 15.50 €" },
  { value: "YC 23043 C2 - 15.50 €", label: "YC 23043 C2 - 15.50 €" },
  { value: "YC 23043 C3 - 15.50 €", label: "YC 23043 C3 - 15.50 €" },
  { value: "YC 23052 C4 - 15.50 €", label: "YC 23052 C4 - 15.50 €" },
  { value: "YC 23055 C1 - 15.50 €", label: "YC 23055 C1 - 15.50 €" },
  { value: "YC 23055 C2 - 15.50 €", label: "YC 23055 C2 - 15.50 €" },
  { value: "YC 23057 C3 - 15.50 €", label: "YC 23057 C3 - 15.50 €" },
  { value: "YC 23057 C4 - 15.50 €", label: "YC 23057 C4 - 15.50 €" },
  { value: "YC 23061 C4 - 15.50 €", label: "YC 23061 C4 - 15.50 €" },
  { value: "YC 23087 C4 - 15.50 €", label: "YC 23087 C4 - 15.50 €" },
  { value: "YC 23112 C1 - 15.50 €", label: "YC 23112 C1 - 15.50 €" },
  { value: "YC 23112 C2 - 15.50 €", label: "YC 23112 C2 - 15.50 €" },
  { value: "YC 23121 C1 - 15.50 €", label: "YC 23121 C1 - 15.50 €" },
  { value: "YC 23121 C3 - 15.50 €", label: "YC 23121 C3 - 15.50 €" },
  { value: "YC 23125 C1 - 15.50 €", label: "YC 23125 C1 - 15.50 €" },
  { value: "YC 23125 C2 - 15.50 €", label: "YC 23125 C2 - 15.50 €" },
  { value: "YC 23125 C3 - 15.50 €", label: "YC 23125 C3 - 15.50 €" },
  { value: "YC 23139 C1 - 15.50 €", label: "YC 23139 C1 - 15.50 €" },
  { value: "YC 23139 C4 - 15.50 €", label: "YC 23139 C4 - 15.50 €" },
  { value: "YC 23142 C1 - 15.50 €", label: "YC 23142 C1 - 15.50 €" },
  { value: "YC 23142 C2 - 15.50 €", label: "YC 23142 C2 - 15.50 €" },
  { value: "YC 23142 C3 - 15.50 €", label: "YC 23142 C3 - 15.50 €" },
  { value: "YC 23153 C3 - 15.50 €", label: "YC 23153 C3 - 15.50 €" },
  { value: "YC 23168 C2 - 15.50 €", label: "YC 23168 C2 - 15.50 €" },
  { value: "YC 23168 C3 - 15.50 €", label: "YC 23168 C3 - 15.50 €" },
  { value: "YC 23168 C4 - 15.50 €", label: "YC 23168 C4 - 15.50 €" },
  { value: "YC 23170 C1 - 15.50 €", label: "YC 23170 C1 - 15.50 €" },
  { value: "YC 23170 C3 - 15.50 €", label: "YC 23170 C3 - 15.50 €" },
  { value: "YC 23171 C1 - 15.50 €", label: "YC 23171 C1 - 15.50 €" },
  { value: "YC 23171 C3 - 15.50 €", label: "YC 23171 C3 - 15.50 €" },
  { value: "YC 23171 C4 - 15.50 €", label: "YC 23171 C4 - 15.50 €" },
  { value: "YC 23174 C4 - 15.50 €", label: "YC 23174 C4 - 15.50 €" },
  { value: "YC 25008 C2 - 15.50 €", label: "YC 25008 C2 - 15.50 €" },
  { value: "YC 26007 C4 - 15.50 €", label: "YC 26007 C4 - 15.50 €" },
  { value: "YC 26008 C1 - 15.50 €", label: "YC 26008 C1 - 15.50 €" },
  { value: "YC 26008 C2 - 15.50 €", label: "YC 26008 C2 - 15.50 €" },
  { value: "YC 26008 C4 - 15.50 €", label: "YC 26008 C4 - 15.50 €" },
  { value: "YC 26010 C1 - 15.50 €", label: "YC 26010 C1 - 15.50 €" },
  { value: "YC 26010 C2 - 15.50 €", label: "YC 26010 C2 - 15.50 €" },
  { value: "YC 26012 C2 - 15.50 €", label: "YC 26012 C2 - 15.50 €" },
  { value: "YC 26019 C1 - 15.50 €", label: "YC 26019 C1 - 15.50 €" },
  { value: "YC 26027 C2 - 15.50 €", label: "YC 26027 C2 - 15.50 €" },
  { value: "YC 26028 C1 - 15.50 €", label: "YC 26028 C1 - 15.50 €" },
  { value: "YC 26028 C3 - 15.50 €", label: "YC 26028 C3 - 15.50 €" },
  { value: "YC 26032 C1 - 15.50 €", label: "YC 26032 C1 - 15.50 €" },
  { value: "YC 26042 C2 - 15.50 €", label: "YC 26042 C2 - 15.50 €" },
  { value: "YC 26042 C4 - 15.50 €", label: "YC 26042 C4 - 15.50 €" },
  { value: "YC 26048 C2 - 15.50 €", label: "YC 26048 C2 - 15.50 €" },
  { value: "YC 26048 C3 - 15.50 €", label: "YC 26048 C3 - 15.50 €" },
  { value: "YC 26048 C4 - 15.50 €", label: "YC 26048 C4 - 15.50 €" },
  { value: "YC 26049 C2 - 15.50 €", label: "YC 26049 C2 - 15.50 €" },
  { value: "YC 26049 C4 - 15.50 €", label: "YC 26049 C4 - 15.50 €" },
  { value: "YC 28004 C1 - 15.50 €", label: "YC 28004 C1 - 15.50 €" },
  { value: "YC 28018 C1 - 15.50 €", label: "YC 28018 C1 - 15.50 €" },
  { value: "YC 28022 C1 - 15.50 €", label: "YC 28022 C1 - 15.50 €" },
  { value: "YC 28027 C2 - 15.50 €", label: "YC 28027 C2 - 15.50 €" },
  { value: "YC 28032 C3 - 15.50 €", label: "YC 28032 C3 - 15.50 €" },
  { value: "YC 28034 C4 - 15.50 €", label: "YC 28034 C4 - 15.50 €" },
  { value: "YC 28039 C3 - 15.50 €", label: "YC 28039 C3 - 15.50 €" },
  { value: "YC 28052 C1 - 15.50 €", label: "YC 28052 C1 - 15.50 €" },
  { value: "YC 28052 C2 - 15.50 €", label: "YC 28052 C2 - 15.50 €" },
  { value: "YC 28055 C3 - 15.50 €", label: "YC 28055 C3 - 15.50 €" },
  { value: "YC 29001 C2 - 15.50 €", label: "YC 29001 C2 - 15.50 €" },
  { value: "YC 29001 C3 - 15.50 €", label: "YC 29001 C3 - 15.50 €" },
  { value: "YC 29002 C1 - 15.50 €", label: "YC 29002 C1 - 15.50 €" },
  { value: "YC 29003 C1 - 15.50 €", label: "YC 29003 C1 - 15.50 €" },
  { value: "YC 29003 C4 - 15.50 €", label: "YC 29003 C4 - 15.50 €" },
  { value: "YC 29005 C2 - 15.50 €", label: "YC 29005 C2 - 15.50 €" },
  { value: "YC 29006 C2 - 15.50 €", label: "YC 29006 C2 - 15.50 €" },
  { value: "YC 29008 C4 - 15.50 €", label: "YC 29008 C4 - 15.50 €" },
  { value: "YC 29009 C1 - 15.50 €", label: "YC 29009 C1 - 15.50 €" },
  { value: "YC 29009 C2 - 15.50 €", label: "YC 29009 C2 - 15.50 €" },
  { value: "YC 29009 C3 - 15.50 €", label: "YC 29009 C3 - 15.50 €" },
  { value: "YC 29009 C4 - 15.50 €", label: "YC 29009 C4 - 15.50 €" },
  { value: "YC 29011 C1 - 15.50 €", label: "YC 29011 C1 - 15.50 €" },
  { value: "YC 29011 C4 - 15.50 €", label: "YC 29011 C4 - 15.50 €" },
  { value: "YC 29013 C1 - 15.50 €", label: "YC 29013 C1 - 15.50 €" },
  { value: "YC 29013 C2 - 15.50 €", label: "YC 29013 C2 - 15.50 €" },
  { value: "YC 29024 C1 - 15.50 €", label: "YC 29024 C1 - 15.50 €" },
  { value: "YC 29025 C1 - 15.50 €", label: "YC 29025 C1 - 15.50 €" },
  { value: "YC 29031 C2 - 15.50 €", label: "YC 29031 C2 - 15.50 €" },
  { value: "YC 29031 C4 - 15.50 €", label: "YC 29031 C4 - 15.50 €" },
  { value: "YC 29032 C1 - 15.50 €", label: "YC 29032 C1 - 15.50 €" },
  { value: "YC 29032 C2 - 15.50 €", label: "YC 29032 C2 - 15.50 €" },
  { value: "YC 29032 C3 - 15.50 €", label: "YC 29032 C3 - 15.50 €" },
  { value: "YC 29032 C4 - 15.50 €", label: "YC 29032 C4 - 15.50 €" },
  { value: "YC 29033 C3 - 15.50 €", label: "YC 29033 C3 - 15.50 €" },
  { value: "YC 29034 C3 - 15.50 €", label: "YC 29034 C3 - 15.50 €" },
  { value: "YC 29035 C4 - 15.50 €", label: "YC 29035 C4 - 15.50 €" },
  { value: "YC 29047 C1 - 15.50 €", label: "YC 29047 C1 - 15.50 €" },
  { value: "YC 29047 C3 - 15.50 €", label: "YC 29047 C3 - 15.50 €" },
  { value: "YC 29048 C1 - 15.50 €", label: "YC 29048 C1 - 15.50 €" },
  { value: "YC 29063 C1 - 15.50 €", label: "YC 29063 C1 - 15.50 €" },
  { value: "YC 29063 C4 - 15.50 €", label: "YC 29063 C4 - 15.50 €" },
  { value: "YC 29065 C1 - 15.50 €", label: "YC 29065 C1 - 15.50 €" },
  { value: "YC 29065 C2 - 15.50 €", label: "YC 29065 C2 - 15.50 €" },
  { value: "YC 30015 C1 - 15.50 €", label: "YC 30015 C1 - 15.50 €" },
  { value: "YC 30015 C3 - 15.50 €", label: "YC 30015 C3 - 15.50 €" },
  { value: "YC 30017 C1 - 15.50 €", label: "YC 30017 C1 - 15.50 €" },
  { value: "YC 30017 C2 - 15.50 €", label: "YC 30017 C2 - 15.50 €" },
  { value: "YC 30041 C1 - 15.50 €", label: "YC 30041 C1 - 15.50 €" },
  { value: "YC 30041 C2 - 15.50 €", label: "YC 30041 C2 - 15.50 €" },
  { value: "YC 30049 C4 - 15.50 €", label: "YC 30049 C4 - 15.50 €" },
  { value: "YC 30057 C2 - 15.50 €", label: "YC 30057 C2 - 15.50 €" },
  { value: "YC 31064 C2 - 15.50 €", label: "YC 31064 C2 - 15.50 €" },
  { value: "YC 31064 C3 - 15.50 €", label: "YC 31064 C3 - 15.50 €" },
  { value: "YC 31064 C4 - 15.50 €", label: "YC 31064 C4 - 15.50 €" },
  { value: "YC 31069 C1 - 15.50 €", label: "YC 31069 C1 - 15.50 €" },
  { value: "YC 31072 C4 - 15.50 €", label: "YC 31072 C4 - 15.50 €" },
  { value: "YC 31078 C1 - 15.50 €", label: "YC 31078 C1 - 15.50 €" },
  { value: "YC 31085 C1 - 15.50 €", label: "YC 31085 C1 - 15.50 €" },
  { value: "YC 313 C1 - 15.50 €", label: "YC 313 C1 - 15.50 €" },
  { value: "YC 313 C4 - 15.50 €", label: "YC 313 C4 - 15.50 €" },
  { value: "YC 33059 C1 - 15.50 €", label: "YC 33059 C1 - 15.50 €" },
  { value: "YC 33063 C1 - 15.50 €", label: "YC 33063 C1 - 15.50 €" },
  { value: "YC 34009 C2 - 15.50 €", label: "YC 34009 C2 - 15.50 €" },
  { value: "YC 34009 C3 - 15.50 €", label: "YC 34009 C3 - 15.50 €" },
  { value: "YC 39003 C5 - 15.50 €", label: "YC 39003 C5 - 15.50 €" },
  { value: "YC 39027 C1 - 15.50 €", label: "YC 39027 C1 - 15.50 €" },
  { value: "YC 39028 C2 - 15.50 €", label: "YC 39028 C2 - 15.50 €" },
  { value: "YC 39063 C3 - 15.50 €", label: "YC 39063 C3 - 15.50 €" },
  { value: "YC 39072 C4 - 15.50 €", label: "YC 39072 C4 - 15.50 €" },
  { value: "YC 60019 C1 - 15.50 €", label: "YC 60019 C1 - 15.50 €" },
  { value: "YC 60019 C4 - 15.50 €", label: "YC 60019 C4 - 15.50 €" },
  { value: "YC 60023 C7 - 15.50 €", label: "YC 60023 C7 - 15.50 €" },
  { value: "YC 60023 C8 - 15.50 €", label: "YC 60023 C8 - 15.50 €" },
  { value: "YC 60026 C1 - 15.50 €", label: "YC 60026 C1 - 15.50 €" },
  { value: "YC 60026 C4 - 15.50 €", label: "YC 60026 C4 - 15.50 €" },
  { value: "YC 60031 C4 - 15.50 €", label: "YC 60031 C4 - 15.50 €" },
  { value: "YC 60032 C6 - 15.50 €", label: "YC 60032 C6 - 15.50 €" },
  { value: "YC 60036 C1 - 15.50 €", label: "YC 60036 C1 - 15.50 €" },
  { value: "YC 60036 C4 - 15.50 €", label: "YC 60036 C4 - 15.50 €" },
  { value: "YC 60036 C7 - 15.50 €", label: "YC 60036 C7 - 15.50 €" },
  { value: "YC 60037 C4 - 15.50 €", label: "YC 60037 C4 - 15.50 €" },
  { value: "YC 60051 C7 - 15.50 €", label: "YC 60051 C7 - 15.50 €" },
  { value: "YC 63006 C5 - 15.50 €", label: "YC 63006 C5 - 15.50 €" },
  { value: "YC 63006 C6 - 15.50 €", label: "YC 63006 C6 - 15.50 €" },
  { value: "YC 63038 C6 - 15.50 €", label: "YC 63038 C6 - 15.50 €" },
  { value: "YC 8087 C1 - 15.50 €", label: "YC 8087 C1 - 15.50 €" },
  { value: "YC 8087 C3 - 15.50 €", label: "YC 8087 C3 - 15.50 €" },
  { value: "YC 9609 C1 - 15.50 €", label: "YC 9609 C1 - 15.50 €" },
  { value: "YC 9609 C3 - 15.50 €", label: "YC 9609 C3 - 15.50 €" },
  { value: "YC 25025 C2 - 15.50 €", label: "YC 25025 C2 - 15.50 €" },
  { value: "GC 001 C2 - 15.50 €", label: "GC 001 C2 - 15.50 €" },
  { value: "GC 001 C3 - 15.50 €", label: "GC 001 C3 - 15.50 €" },
  { value: "GC 002 C2 - 15.50 €", label: "GC 002 C2 - 15.50 €" },
  { value: "GC 002 C3 - 15.50 €", label: "GC 002 C3 - 15.50 €" },
  { value: "GC 003 C2 - 15.50 €", label: "GC 003 C2 - 15.50 €" },
  { value: "GC 003 C3 - 15.50 €", label: "GC 003 C3 - 15.50 €" },
  { value: "GC 004 C1 - 15.50 €", label: "GC 004 C1 - 15.50 €" },
  { value: "GC 004 C2 - 15.50 €", label: "GC 004 C2 - 15.50 €" },
  { value: "GC 004 C3 - 15.50 €", label: "GC 004 C3 - 15.50 €" },
  { value: "GC 005 C3 - 15.50 €", label: "GC 005 C3 - 15.50 €" },
  { value: "GC 006 C1 - 15.50 €", label: "GC 006 C1 - 15.50 €" },
  { value: "GC 006 C3 - 15.50 €", label: "GC 006 C3 - 15.50 €" },
  { value: "GC 007 C3 - 15.50 €", label: "GC 007 C3 - 15.50 €" },
  { value: "62733 C2 - 15.50 €", label: "62733 C2 - 15.50 €" },
  { value: "62733 C3 - 15.50 €", label: "62733 C3 - 15.50 €" },
  { value: "YC 11057 C2 - 15.50 €", label: "YC 11057 C2 - 15.50 €" },
];
///////////////////////////////////////////

///////////////Type de Monture//////////////////////
export const frameTypeA1 = [
  { value: "", label: "" },
  { value: " Metal - 0.00 €", label: " Metal - 0.00 €" },
  { value: "Nylor - 0.00 €", label: "Nylor - 0.00 €" },
  { value: "Plastic - 0.00 €", label: "Plastic - 0.00 €" },
];
////////////////////////////////////////

export const Supplier = [
  { value: "", label: "" },
  { value: "Conant", label: "Conant" },
  { value: "VRX", label: "VRX" },
  { value: "Koptica", label: "Koptica" },
  { value: "Shamir", label: "Shamir" },
  { value: "Inconnu", label: "Inconnu" },
];
export const Nooption = [
  
];
