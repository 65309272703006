import React, { Component } from "react";
import ReactDOM from "react-dom";
import Optidetails from "./optidetails";
import swal from "sweetalert";
import { API_URL } from "../../../config";
import $ from "jquery";
import SymLoader from "../../SymLoader/SymLoader";
import SymLoader1 from "../../SymLoader/SymLoader1";
class Opticianreg extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      opcarr: [],
      posarr: [],
      count: 1,
      posEmail:'',
      isExternal:false,
      isLoading: false,
      isViewLoading:false,
      utype: localStorage.getItem("type"),
    };
  }
  getopinfo(email, mob) {
    $('#overlay2').css('display','block')
    this.setState({ isLoading: true });
    let logourl = API_URL + "/optician/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        // let arr = [];
        // for (let i = json.length - 1; i >= 0; i--) {
        //   arr.push(json[i]);
        // }
        this.setState({ opcarr: json });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  }
  componentDidMount() {
    this.setState({ opcarr: JSON.parse(localStorage.getItem("OPTICIAN")) });
    console.log(localStorage.getItem("OPTICIAN"));
    this.getopinfo("", "");
    this.getposinfo("", "");
    this.showpos('false');
  }
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const monthAbbreviation = months[monthIndex - 1];

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  getposinfo(email, mob) {
    let logourl = API_URL + "/pos/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ posarr: json });
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  }

  submit() {
    //this.validate()
    // let data = {
    //     fullname: document.getElementById("basic-icon-default-fullname").value=""
    //     email: document.getElementById("basic-icon-default-email").value,
    //     pass: document.getElementById("basic-icon-default-pass").value,
    //     RPPS: document.getElementById("basic-icon-default-post").value,
    //     docid: document.getElementById("basic-icon-default-salary").value

    // }
    let showpos = false;
    if (
      document.getElementById("ty").value == true ||
      (document.getElementById("posid").value == "" &&
        document.getElementById("posid").getAttribute("disabled") == false)
    ) {
      swal({
        title: "Oops!",
        text: window.$language.POS + " " + window.$language.cannotbeblank,
        icon: "warning",
      });

      showpos = true;
      return false;
    }

    /* Validations */

    var ret = false;
    $("#inputform >  .form-group >  .form-control").each(function () {
      if ($(this).val() == "") {
        swal({
          title: "OOPS!",
          text:
            $(this).parent().find("label").html() +
            window.$language.cannotbeblank,
          icon: "warning",
        });
        ret = true;
        return false;
      }
    });

    if (ret) return false;
    /* Validations */
    let wgval = true;
    if (
      document.getElementById("ty").value == "false" ||
      document.getElementById("ty").value == ""
    ) {
      wgval = false;
    }
    let data1 = {
      email: document.getElementById("email").value,
      password: "SymInfo2024",
      mobile: document.getElementById("PH").value,
      userType: "OPTICIAN",
      languageId: 1,
      name: document.getElementById("fname").value,
      lastName: document.getElementById("lname").value,
      dateOfBirth: document.getElementById("DOB").value,
      doYouWearGlasses: true,
      whenIsTheLastTimeYouWentToTheEyeDoctor: "",
      patientNameOfYourUsualEyeDoctor: "",
      patientRppsNumberOfYourUsualEyeDoctor: 0,
      phoneNumber: document.getElementById("PH").value,
      posid: document.getElementById("posid").value,
      typeOfVision: "",
      patientHappy: "",
      patientSymptoms: "",
      patientDiagnosedWith: "",
      patientHealthConditions: "",
      additionalComments: "",
      doctorRppsNumber: "",
      doctorIdOfRegistration: "",
      doctorAddressOfTheHealthCenter: "",
      isExternal: wgval,
      SubmitTrack:"Web"
    };
    // let arr = JSON.parse(localStorage.getItem("doc"))
    // arr.push(data)

    // localStorage.setItem("doc", JSON.stringify(arr))
    //////
    let logourl = API_URL + "/optician/add";
    let msg = "";
    if (this.state.count == 1) {
      logourl = API_URL + "/optician/add";
      msg = window.$language.OpticianDetailsAddedsuccessfully;
    } else {
      logourl = API_URL + "/user/edit";
      msg = window.$language.OpticianDetailseditedsuccessfully;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    const content = document.createElement("div");
    const input = document.createElement("img");
    input.src = "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
    input.width = "250";
    content.appendChild(input);

    swal({
      text: window.$language.PleaseWait,

      allowOutsideClick: false,
      buttons: false,
      content,
    });
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //this.setState({usrdet:json})
        if (json > 0) {
          swal({
            title: "Done!",
            text: msg,
            icon: "success",
          });
          document.getElementById("close").click();
          this.componentDidMount();
        }
        if (json == 0) {
          swal({
            title: "Oops!",
            text: window.$language.Thisemailalreadyexists,
            icon: "warning",
          });
          document.getElementById("close").click();
          this.componentDidMount();
        }
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Pleaseenteryouremailandpassword,
          icon: "warning",
        });

        console.log(e);
      });
    //////
  }
  edit(usr) {
    this.setState({ count: 2 });
    console.log('uD',usr)
    this.reset();
    document.getElementById("email").value = usr.email;
    //document.getElementById("PPP").value = usr.password
    this.setState({posEmail:usr.posEmail});
    this.setState({isExternal:usr.isExternal?true:false});
    document.getElementById("PH").value = usr.phoneNumber;
    document.getElementById("fname").value = usr.name;
    document.getElementById("lname").value = usr.lastName;
    if(usr.isExternal)
    this.showpos('true');
    var date = usr.dateOfBirth.split(" ");
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (var j = 0; j < months.length; j++) {
      if (date[1] == months[j]) {
        date[1] = months.indexOf(months[j]) + 1;
      }
    }
    if (date[1] < 10) {
      date[1] = "0" + date[1];
    }
    var formattedDate = date[2] + "-" + date[1] + "-" + date[0];

    console.log(formattedDate);
    document.getElementById("DOB").value = formattedDate;
    document.getElementById("PH").value = usr.phoneNumber;
    document.getElementById("ty").value = usr.isExternal;
    document.getElementById("posid").value = usr.posid;
  }

  addnew() {
    this.setState({
      count: 1,
    });
    this.reset();
  }
  reset() {
    document.getElementById("email").value = "";
    //document.getElementById("PPP").value = ""
    document.getElementById("PH").value = "";
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = "";
    document.getElementById("DOB").value = "";
    document.getElementById("PH").value = "";
    document.getElementById("ty").value = "";
    document.getElementById("posid").value = "";
  }
  delete(email) {
    swal({
      title: window.$language.Areyousure,
      text: window.$language.AreyousuretoDelete,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/user/delete";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            email: email,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: window.$language.DeletedSuccessfully,
                icon: "success",
              });
              this.componentDidMount();
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  Optidetails(usr) {
    console.log("hi");
    ReactDOM.render(<Optidetails usr={usr} />, document.getElementById("c11"));
  }
  showpos(val) {
    if (val == "true") {
      document.getElementById("sp").removeAttribute("hidden");
      document.getElementById("sp").setAttribute("disabled", false);
    } else {
      document.getElementById("sp").setAttribute("hidden", true);
      document.getElementById("sp").setAttribute("disabled", true);

      //document.getElementById("posid").setAttribute("value","")
    }
  }

  // showpos1(){
  //     let x = document.getElementById["posid"].value;
  //   if (x == "") {
  //     alert("Name must be filled out");
  //     return false;
  //   }
  //     }

  viewCall(usr, idx) {
    this.setState({ index: idx });
    this.setState({ isViewLoading: true });
    setTimeout(() => {
      this.setState({ isViewLoading: false });
      this.Optidetails(usr);
    }, 1000)
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <SymLoader1 /> : ""}
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section id="basic-datatable">
              <div class="row">
                <div class="col">
                  <div class="card px-2 pb-4">
                    <div class="card-header border-bottom mr-1">
                      <div class="head-label">
                        <h4 class="mb-0">{window.$language.ViewOpticians}</h4>
                      </div>
                      <div class="dt-action-buttons text-right">
                      {this.state.utype == "ADMIN" ? (
                        <div class="dt-buttons d-inline-flex">
                          {/*<div class="btn-group mr-1">
                                                        <button type="button" class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {window.$language.Export}
                                                        </button>
                                                        <div class="dropdown-menu ">
                                                        <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-print mr-50"></i>{window.$language.Print}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-csv mr-50"></i>{window.$language.CSV}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-excel mr-50"></i>{window.$language.Excel}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-pdf mr-50"></i>{window.$language.PDF}</a>
                                                        </div>
        </div>*/}
                          <button
                            onClick={() => this.addnew()}
                            class="btn btn-primary mr-1"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            type="button"
                            data-toggle="modal"
                            data-target="#modals-slide-in"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus mr-50 font-small-4"
                              >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                              </svg>
                              {window.$language.AddNewRecord}
                            </span>
                          </button>{" "}
                        </div>
                         ) : null}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mx-0 row">
                      <div class="col-sm-12 col-md-6">
                        {/*<div class="dataTables_length" id="DataTables_Table_0_length"><label>Show 
                                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select form-control"><option value="7">7</option>
                                        <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="75">75</option><option value="100">100</option></select></label>
                                        </div>*/}
                      </div>
                      <div class="col-sm-8 col-md-4">
                        <div
                          id="DataTables_Table_0_filter"
                          class="dataTables_filter pt-1"
                        >
                          <label class="w-100">
                            <input
                              type="search"
                              onChange={(data) =>
                                this.getopinfo(
                                  data.target.value,
                                  data.target.value
                                )
                              }
                              class="form-control rounded-lg shadow-sm"
                              placeholder="Rechercher une information"
                              aria-controls="DataTables_Table_0"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="datatables-basic table mt-1 shadow  table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>{window.$language.Name}</th>
                            <th>{window.$language.Email}</th>
                            <th>{window.$language.Phone}</th>
                            <th>{window.$language.OpticianType}</th>
                            <th>{window.$language.Created}</th>
                            <th>{window.$language.STATUS}</th>
                            <th>{window.$language.ACTIONS}</th>
                          </tr>
                        </thead>

                        <tbody table-hover-animation>
                          {this.state.opcarr.map((usr,idx) => (
                            <tr>
                              <td class="tablerowimage">
                                <img
                                  class="round img-thumbnail"
                                  src={
                                    usr.profileImagePath == undefined ||
                                    usr.profileImagePath == ""
                                      ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                      : API_URL + "/" + usr.profileImagePath
                                  }
                                  alt="avatar"
                                  height="40"
                                  width="40"
                                />
                              </td>
                              <td>{usr.name + " " + usr.lastName}</td>
                              <td>{usr.email}</td>
                              <td>{usr.phoneNumber}</td>
                              <td>
                                {usr.isExternal == false
                                  ? window.$language.SymOpticOptician
                                  : window.$language.ExternalOptician}
                              </td>
                              <td>{this.formatDate(usr.createdDate)}</td>
                              <td>
                                <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                  Active
                                </span>
                              </td>
                              <td>
                                <div
                                  class="btn-group"
                                  role="group"
                                  aria-label="Basic example"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-outline-secondary"
                                    // onClick={() => this.Optidetails(usr)}
                                    onClick={() => this.viewCall(usr, idx)}
                                    href="javascript:void(0);"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View Details"
                                    disabled={this.state.isViewLoading && (idx === this.state.index)}
                                  >
                                    {this.state.isViewLoading && (idx === this.state.index) ? '...' : <i className="far fa-eye fa-fw"></i>}
                                  </button>
                                  {this.state.utype == "ADMIN" ? (
                                     <>
                                  <button
                                    type="button"
                                    class="btn btn-outline-secondary"
                                    data-toggle="modal"
                                    data-target="#modals-slide-in"
                                    onClick={() => this.edit(usr)}
                                    href="javascript:void(0);"
                                    data-placement="top"
                                    title="Edit"
                                  >
                                    <i class="far fa-edit fa-fw"></i>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-secondary"
                                    onClick={() => this.delete(usr.email)}
                                    href="javascript:void(0);"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                  >
                                    <i class="far fa-trash-alt fa-fw"></i>
                                  </button>
                                  </>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/*<nav aria-label="Page navigation example">
                                        <ul class="pagination mt-3 justify-content-center">
                                        <li class="page-item prev-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                            <li class="page-item active"><a class="page-link" href="javascript:void(0);">1</a></li>
                                            <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                            <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                                            <li class="page-item next-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                        </ul>
                                            </nav>*/}
                  </div>
                </div>
              </div>
              <div class="modal modal-slide-in fade" id="modals-slide-in">
                <div class="modal-dialog sidebar-sm">
                  <form class="add-new-record modal-content pt-0">
                    <button
                      // type="button"
                      type="reset"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=>this.showpos('false')}
                    >
                      ×
                    </button>
                    <div class="modal-header mb-1">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {window.$language.AddOptician}
                      </h5>
                    </div>
                    <div class="modal-body flex-grow-1" id="inputform">
                      <div class="form-group">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.FirstName}*
                        </label>
                        <input
                          type="text"
                          class="form-control dt-full-name"
                          id="fname"
                          placeholder="John Doe"
                          aria-label="John Doe"
                        />
                      </div>
                      <div class="form-group">
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.LastName}*
                        </label>
                        <input
                          type="text"
                          class="form-control dt-full-name"
                          id="lname"
                          placeholder="John Doe"
                          aria-label="John Doe"
                        />
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="basic-icon-default-post">
                          {window.$language.DateofBirth}*
                        </label>
                        <input
                          id="DOB"
                          type="date"
                          class="form-control pickadate"
                          placeholder="YYYY-MM-DD"
                        />
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="basic-icon-default-post">
                          {window.$language.Phone}*
                        </label>
                        <input
                          type="number"
                          id="PH"
                          class="form-control dt-post"
                          placeholder="XXXXXXXXXXX"
                          aria-label="XXXXXXXXXXX"
                        />
                      </div>

                      <div class="form-group">
                        <label
                          class="form-label"
                          for="basic-icon-default-email"
                        >
                          {window.$language.Email}*
                        </label>
                        <input
                          type="text"
                          id="email"
                          class="form-control dt-email"
                          placeholder="john.doe@example.com"
                          aria-label="john.doe@example.com"
                        />
                      </div>
                      <div class="form-group">
                        <div className="form-group align-baseline flex-row">
                          <label
                            className="form-label required"
                            for="login-email"
                          >
                            {window.$language.OpticianType}*
                          </label>
                          <select
                            class="form-control required"
                            id="ty"
                            onChange={(data) => this.showpos(data.target.value)}
                          >
                            <option value="false">
                              {window.$language.SymOpticOptician}
                            </option>
                            <option value="true">
                              {window.$language.ExternalOptician}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div id="sp" className="my-1" disabled={true}>
                        <label
                          class="form-label"
                          for="basic-icon-default-fullname"
                        >
                          {window.$language.POS}:*
                        </label>
                        <select class="form-control dt-full-name" id="posid" onChange={(e)=>this.setState({posEmail:e.target.value})} value={this.state.posEmail}>
                          {this.state.posarr.map((usr) => (
                            <option value={usr.email}>{usr.email}</option>
                          ))}
                        </select>
                      </div>
                      {/* <div class="form-group ">
                                                <label class="form-label" for="basic-icon-default-email">{window.$language.Password}*</label>
                                                <input type="password" id="PPP" class="form-control dt-email" placeholder="" aria-label="john.doe@example.com" />

                                            </div> */}

                      <button
                        type="button"
                        class="btn btn-primary data-submit mr-1 btn-page-block-overlay waves-effect "
                        onClick={() => this.submit()}
                      >
                        {window.$language.Submit}
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary"
                        id="close"
                        data-dismiss="modal"
                        onClick={()=>this.showpos('false')}
                      >
                        {window.$language.Cancel}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
export default Opticianreg;
