import React, { Component } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { API_URL } from "../../../config";
import Posdetails from "./posdetails";
import $ from "jquery";
import SymLoader from "../../SymLoader/SymLoader";
import SymLoader1 from "../../SymLoader/SymLoader1";
class POSreg extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      posarr: [],
      count: 1,
      isLoading: false,
      isViewLoading:false,
      utype: localStorage.getItem("type"),
    };
  }
  componentDidMount() {
    this.setState({ posarr: JSON.parse(localStorage.getItem("POINTOFSALES")) });
    console.log(localStorage.getItem("POINTOFSALES"));
    this.getposinfo("", "");
  }
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const monthAbbreviation = months[monthIndex - 1];

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  submit() {
    // let data = {
    //     fullname: document.getElementById("basic-icon-default-fullname").value=""
    //     email: document.getElementById("basic-icon-default-email").value,
    //     pass: document.getElementById("basic-icon-default-pass").value,
    //     RPPS: document.getElementById("basic-icon-default-post").value,
    //     docid: document.getElementById("basic-icon-default-salary").value

    // }
    // let wgval=true
    // if(document.getElementById("wg").value=="false"){
    // wgval=false
    // }

    /* Validations */
    // var ret = false;
    // $("#inputform >  .form-group >  .form-control").each(function () {
    //   if ($(this).val() == "") {
    //     swal({
    //       title: "OOPS!",
    //       text:
    //         $(this).parent().find("label").html() +
    //         window.$language.cannotbeblank,
    //       icon: "warning",
    //     });
    //     ret = true;
    //     return false;
    //   }
    // });

    // if (ret) return false;
    /* Validations */
if(email.value == "" || email.value == null){
  swal({
    title: "OOPS!",
    text: "Email" + " " + window.$language.cannotbeblank,
    icon: "warning",
  });
  return false;
}; 
if(fname.value == "" || fname.value == null){
  swal({
    title: "OOPS!",
    text:window.$language.Name + " " + window.$language.cannotbeblank,
    icon: "warning",
  });
  return false;
};
    let data1 = {
      email: document.getElementById("email").value,
      password: "SymInfo2024",
      mobile: document.getElementById("PH").value == "" ? "0": document.getElementById("PH").value == null ? "0":document.getElementById("PH").value,
      userType: "OPTICIAN",
      languageId: 1,
      name: document.getElementById("fname").value,
      lastName: "",
      dateOfBirth: "",
      doYouWearGlasses: true,
      whenIsTheLastTimeYouWentToTheEyeDoctor: "",
      patientNameOfYourUsualEyeDoctor: "",
      patientRppsNumberOfYourUsualEyeDoctor: 0,
      phoneNumber: 0,
      typeOfVision: "",
      patientHappy: "",
      patientSymptoms: "",
      patientDiagnosedWith: "",
      patientHealthConditions: "",
      additionalComments: document.getElementById("Addcom").value == "" ? "-" : document.getElementById("Addcom").value,
      doctorRppsNumber: "",
      doctorIdOfRegistration: "",
      doctorAddressOfTheHealthCenter: "",
      SubmitTrack:"Web"
    };
    // let arr = JSON.parse(localStorage.getItem("doc"))
    // arr.push(data)

    // localStorage.setItem("doc", JSON.stringify(arr))
    //////
    let logourl = API_URL + "/pos/add";

    let msg = "";
    if (this.state.count == 1) {
      logourl = API_URL + "/pos/add";
      msg = window.$language.AddedSuccessfully;
    } else {
      logourl = API_URL + "/user/edit";
      msg = window.$language.Editedsuccessfully;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    const content = document.createElement("div");
    const input = document.createElement("img");
    input.src = "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
    input.width = "250";
    content.appendChild(input);

    swal({
      text: window.$language.PleaseWait,

      allowOutsideClick: false,
      buttons: false,
      content,
    });
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //this.setState({usrdet:json})
        if (json > 0) {
          swal({
            title: "Done!",
            text: msg,
            icon: "success",
          });
          document.getElementById("close").click();
          this.componentDidMount();
        }
        if (json == 0) {
          swal({
            title: "Done!",
            text: window.$language.Editedsuccessfully,
            icon: "success",
          });
          document.getElementById("close").click();
          this.componentDidMount();
        }
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Pleaseenteryouremailandpassword,
          icon: "warning",
        });

        console.log(e);
      });
    //////
  }
  delete(email) {
    swal({
      title: window.$language.Areyousure,
      text: window.$language.AreyousuretoDelete,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/user/delete";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            email: email,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: window.$language.DeletedSuccessfully,
                icon: "success",
              });
              this.componentDidMount();
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  getposinfo(email, mob) {
    $('#overlay2').css('display','block')
    this.setState({ isLoading: true });
    let logourl = API_URL + "/pos/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        // let arr = [];
        // for (let i = json.length - 1; i >= 0; i--) {
        //   arr.push(json[i]);
        // }
        this.setState({ posarr: json });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  }
  edit(usr) {
    this.setState({
      count: 2,
    });
    this.reset();
    document.getElementById("email").value = usr.email;
    document.getElementById("PPP").value = usr.password;
    document.getElementById("PH").value = usr.phoneNumber;
    document.getElementById("fname").value = usr.name;
    document.getElementById("PH").value = usr.phoneNumber;
    document.getElementById("Addcom").value = usr.additionalComments;
  }
  addnew() {
    this.setState({
      count: 1,
    });
    this.reset();
  }
  reset() {
    document.getElementById("email").value = "";
    document.getElementById("PPP").value = "";
    document.getElementById("PH").value = "";
    document.getElementById("fname").value = "";
    document.getElementById("PH").value = "";
    document.getElementById("Addcom").value = "";
  }
  Posdetails(usr) {
    console.log("hi");
    ReactDOM.render(<Posdetails usr={usr} />, document.getElementById("c11"));
  }

  viewCall(usr,idx){
    this.setState({index:idx});
    this.setState({isViewLoading:true});
    setTimeout(()=>{
      this.setState({isViewLoading:false});
      this.Posdetails(usr);
    },1000)
  }

  render() {
    return (
      <div class="content-wrapper">
        {this.state.isLoading ? <SymLoader1 /> : ""}
        <div class="content-header row"></div>
        <div class="content-body px-3">
          <section id="basic-datatable">
            <div class="row">
              <div class="col">
                <div class="card px-2 pb-4">
                  <div class="card-header border-bottom mr-1">
                    <div class="head-label">
                      <h4 class="mb-0">{window.$language.ViewPointOfSales}</h4>
                    </div>
                    <div class="dt-action-buttons text-right">
                      <div class="dt-buttons d-inline-flex">
                        {/*<div class="btn-group mr-1">
                                                        <button type="button" class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {window.$language.Export}
                                                        </button>
                                                        <div class="dropdown-menu ">
                                                        <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-print mr-50"></i>{window.$language.Print}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-csv mr-50"></i>{window.$language.CSV}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-excel mr-50"></i>{window.$language.Excel}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-file-pdf mr-50"></i>{window.$language.PDF}</a>
                                                        </div>
        </div>*/}
                        <button
                          onClick={() => this.reset()}
                          class="btn btn-primary mr-1"
                          tabindex="0"
                          aria-controls="DataTables_Table_0"
                          type="button"
                          data-toggle="modal"
                          data-target="#modals-slide-in"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-plus mr-50 font-small-4"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            {window.$language.AddNewRecord}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>

                  <hr class="my-0" />
                  <div class="d-flex justify-content-between align-items-center mx-0 row">
                    <div class="col-sm-12 col-md-6">
                      {/*<div class="dataTables_length" id="DataTables_Table_0_length"><label>Show 
                                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select form-control"><option value="7">7</option>
                                        <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="75">75</option><option value="100">100</option></select></label>
                                        </div>*/}
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <div
                        id="DataTables_Table_0_filter"
                        class="dataTables_filter pt-1"
                      >
                        <label class="w-100">
                          <input
                            onChange={(data) =>
                              this.getposinfo(
                                data.target.value,
                                data.target.value
                              )
                            }
                            type="search"
                            class="form-control rounded-lg shadow-sm"
                            placeholder="Rechercher une information"
                            aria-controls="DataTables_Table_0"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="datatables-basic table mt-1 shadow  table-hover">
                      <thead>
                        <tr>
                          {/* <th>id</th> */}
                          <th></th>
                          <th>{window.$language.Name}</th>
                          <th>{window.$language.Email}</th>
                          <th>{window.$language.Phone}</th>
                          <th>{window.$language.Created}</th>
                          <th>{window.$language.Status}</th>
                          <th>{window.$language.Actions}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.posarr.map((usr,idx) => (
                          <tr>
                            {/* <td>{usr.posid}</td> */}
                            <td class="tablerowimage">
                              <img
                                class="round img-thumbnail"
                                src={
                                  usr.profileImagePath == undefined ||
                                  usr.profileImagePath == ""
                                    ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                    : API_URL + "/" + usr.profileImagePath
                                }
                                alt="avatar"
                                height="40"
                                width="40"
                              />
                            </td>
                            <td>{usr.name + " " + usr.lastName}</td>
                            <td>{usr.email}</td>
                            <td>{usr.phoneNumber == "0"?"":usr.phoneNumber}</td>
                            <td>{this.formatDate(usr.createdDate)}</td>
                            <td>
                              <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                Active
                              </span>
                            </td>
                            <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  onClick={() => this.viewCall(usr,idx)}
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View Details"
                                  disabled={this.state.isViewLoading && (idx===this.state.index)}
                                >
                                  {this.state.isViewLoading && (idx===this.state.index)?'...': <i class="far fa-eye fa-fw"></i>}
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  data-toggle="modal"
                                  data-target="#modals-slide-in"
                                  onClick={() => this.edit(usr)}
                                  href="javascript:void(0);"
                                  data-placement="top"
                                  title="Edit"
                                >
                                  <i class="far fa-edit fa-fw"></i>
                                </button>
                                {this.state.utype == "ADMIN" ? (
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  onClick={() => this.delete(usr.email)}
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                >
                                  <i class="far fa-trash-alt fa-fw"></i>
                                </button> ) : null}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination mt-3 justify-content-center">
                                        <li class="page-item prev-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                            <li class="page-item active"><a class="page-link" href="javascript:void(0);">1</a></li>
                                            <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                            <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                                            <li class="page-item next-item"><a class="page-link" href="javascript:void(0);"></a></li>
                                        </ul>
                                            </nav>*/}
                </div>
              </div>
            </div>

            <div class="modal modal-slide-in fade" id="modals-slide-in">
              <div class="modal-dialog sidebar-sm">
                <form class="add-new-record modal-content pt-0">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    ×
                  </button>
                  <div class="modal-header mb-1">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {window.$language.NewRecord}
                    </h5>
                  </div>
                  <div class="modal-body flex-grow-1" id="inputform">
                    <div class="form-group">
                      <label
                        class="form-label"
                        for="basic-icon-default-fullname"
                      >
                        {window.$language.Name}
                      </label>
                      <input
                        type="text"
                        class="form-control dt-full-name"
                        id="fname"
                        placeholder="John Doe"
                        aria-label="John Doe"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="basic-icon-default-post">
                        {window.$language.Phone}
                      </label>
                      <input
                        type="text"
                        id="PH"
                        class="form-control dt-post"
                        placeholder="XXXXXXXXXXX"
                        aria-label="XXXXXXXXXXX"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="basic-icon-default-email">
                        {window.$language.Email}
                      </label>
                      <input
                        type="text"
                        id="email"
                        class="form-control dt-email"
                        placeholder="john.doe@example.com"
                        aria-label="john.doe@example.com"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="basic-icon-default-pass">
                        {window.$language.AdditionalComments}
                      </label>
                      <input
                        class="form-control"
                        id="Addcom"
                        rows="3"
                        placeholder="Textarea"
                      />
                    </div>
                    <div class=" d-none">
                      <label class="" for="basic-icon-default-email">
                        {window.$language.Password}
                      </label>
                      <input
                        type="password"
                        id="PPP"
                        class=" dt-email"
                        placeholder=""
                        aria-label="john.doe@example.com"
                      />
                    </div>

                    <button
                      type="button"
                      class="btn btn-primary data-submit mr-1 btn-page-block-overlay waves-effect "
                      onClick={() => this.submit()}
                    >
                      {window.$language.Submit}
                    </button>
                    <button
                      type="reset"
                      class="btn btn-outline-secondary"
                      id="close"
                      data-dismiss="modal"
                    >
                      {window.$language.Cancel}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default POSreg;
