import React, { Component } from "react";
import ReactDOM from "react-dom";
import Docreg from "./doctorreg";
import { API_URL } from "../../../config";
import $ from "jquery";
class docdetails extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      utype: localStorage.getItem("type"),
      usrdet: [],
    };

    console.log(this.props);
    //alert(this.props)
  }
  submit() {}
  docreg() {
    console.log("hi");
    ReactDOM.render(<Docreg />, document.getElementById("c11"));
  }
  uploadSign(event) {
    // alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", this.props.doc.email);
      formData.append("SignatureImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/signature/change";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.setState({ usrdet: json });
        this.componentDidMount();

        setTimeout(function () {}, 3000);
      });
    }
  }
  uploadRPPS(event) {
    //alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", this.props.doc.email);
      formData.append("SignatureImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/rpcscode/upload";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.componentDidMount();
        //this.getuserinfo()
        setTimeout(function () {}, 3000);
      });
    }
  }
  uploadDocID(event) {
    // alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", this.props.doc.email);
      formData.append("SignatureImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/doctorid/upload";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.setState({ usrdet: json });
        this.componentDidMount();

        setTimeout(function () {}, 3000);
      });
    }
  }

  componentDidMount() {
    //this.uploadSign()
  }

  render() {
    return (
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section class="invoice-preview-wrapper">
              <div class="row invoice-preview">
                <section class="app-user-edit col-9 pt-0">
                  <div class="card px-2 pb-4">
                    <div class="card-body">
                      <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          ></a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div class="media mb-2">
                              <img
                                src={
                                  this.props.doc.profileImagePath == ""
                                    ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                    : API_URL +
                                      "/" +
                                      this.props.doc.profileImagePath
                                }
                                alt="users avatar"
                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                height="90"
                                width="90"
                              />
                              <div class="media-body mt-50">
                                <h4>
                                  {this.props.doc.name +
                                    "  " +
                                    this.props.doc.lastName}
                                </h4>
                              </div>
                            </div>

                            <div class="media mb-2 pl-0">
                              <img
                                src={
                                  API_URL +
                                  "/" +
                                  this.props.doc.signatureImagePath
                                }
                                alt="users avatar"
                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                height="90"
                                width="90"
                              />
                              <div class="media-body mt-50">
                                <h4>
                                  <br />
                                </h4>
                                <div class="col-12 d-flex mt-1 px-0">
                                  <label
                                    class="btn btn-primary mr-75 mb-0"
                                    for="change-sign"
                                  >
                                    <span class="d-none d-sm-block">
                                      {window.$language.ChangeSignature}
                                    </span>
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="change-sign"
                                      hidden
                                      accept="image/png, image/jpeg, image/jpg"
                                      onChange={(event) => {
                                        this.uploadSign(event);
                                      }}
                                    />
                                    <span class="d-block d-sm-none">
                                      <i class="fas fa-plus-square"></i>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="media mb-2 pl-0">
                              <img
                                src={
                                  API_URL + "/" + this.props.doc.rpcsIdImagePath
                                }
                                alt="users avatar"
                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                height="90"
                                width="90"
                              />
                              <div class="media-body mt-50">
                                <h4>
                                  <br />
                                </h4>
                                <div class="col-12 d-flex mt-1 px-0">
                                  <label
                                    class="btn btn-primary mr-75 mb-0"
                                    for="change-sign1"
                                  >
                                    <span class="d-none d-sm-block">
                                      {window.$language.UploadRPPS}
                                    </span>
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="change-sign1"
                                      hidden
                                      accept="image/png, image/jpeg, image/jpg"
                                      onChange={(event) => {
                                        this.uploadRPPS(event);
                                      }}
                                    />
                                    <span class="d-block d-sm-none">
                                      <i class="fas fa-plus-square"></i>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div class="media mb-2 pl-0">
                              <img
                                src={
                                  API_URL +
                                  "/" +
                                  this.props.doc.doctorIdImagePath
                                }
                                alt="users avatar"
                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                height="90"
                                width="90"
                              />
                              <div class="media-body mt-50">
                                <h4>
                                  <br />
                                </h4>
                                <div class="col-12 d-flex mt-1 px-0">
                                  <label
                                    class="btn btn-primary mr-75 mb-0"
                                    for="change-sign2"
                                  >
                                    <span class="d-none d-sm-block">
                                      {window.$language.UploadDoctorID}
                                    </span>
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="change-sign2"
                                      hidden
                                      accept="image/png, image/jpeg, image/jpg"
                                      onChange={(event) => {
                                        this.uploadDocID(event);
                                      }}
                                    />
                                    <span class="d-block d-sm-none">
                                      <i class="fas fa-plus-square"></i>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <form class="form-validate">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="username">
                                    {window.$language.FirstName}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.doc.name}
                                    class="form-control"
                                    name="username"
                                    id="username"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">
                                    {window.$language.LastName}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.props.doc.lastName}
                                    class="form-control"
                                    name="name"
                                    id="name"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.Email}
                                  </label>
                                  <input
                                    type="email"
                                    defaultValue={this.props.doc.email}
                                    class="form-control"
                                    name="email"
                                    id="email"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="username">
                                    {window.$language.RPPS}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={
                                      this.props.doc.doctorRppsNumber
                                    }
                                    class="form-control"
                                    name="username"
                                    id="username"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">
                                    {window.$language.DoctorId}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={
                                      this.props.doc.doctorIdOfRegistration
                                    }
                                    class="form-control"
                                    name="name"
                                    id="name"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                            </div>
                          </form>
                          <form class="form-validate">
                            <div class="row mt-1">
                              <div class="col-12">
                                <h4 class="mb-1">
                                  <i
                                    data-feather="user"
                                    class="font-medium-4 mr-25"
                                  ></i>
                                  <span class="align-middle">
                                    Personal Information
                                  </span>
                                </h4>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.UserType}
                                  </label>
                                  <select
                                    id="languages"
                                    defaultValue={this.props.doc.userType}
                                    readOnly
                                    class="form-control"
                                  >
                                    <option value="EYEDOCTOR">Doctor</option>
                                    <option value="Russian">Doctor</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="fp-default">
                                    {window.$language.DateofBirth}
                                  </label>
                                  <input
                                    id="fp-default"
                                    defaultValue={this.props.doc.dateOfBirth}
                                    type="text"
                                    class="form-control birthdate-picker"
                                    name="dob"
                                    placeholder="01 Jan 2000"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="mobile">
                                    {window.$language.Phone}
                                  </label>
                                  <input
                                    id="mobile"
                                    defaultValue={this.props.doc.mobile}
                                    type="text"
                                    class="form-control"
                                    name="mobile"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.Languages}
                                  </label>
                                  <select
                                    id="languages"
                                    class="form-control"
                                    readOnly
                                  >
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="French" selected>
                                      French
                                    </option>
                                    <option value="Russian">Russian</option>
                                    <option value="German">German</option>
                                    <option value="Arabic">Arabic</option>
                                    <option value="Sanskrit">Sanskrit</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                {/* <div class="form-group">
                                                    <label class="d-block mb-1">Gender</label>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="male" name="gender" class="custom-control-input" />
                                                        <label class="custom-control-label" for="male">Male</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="female" name="gender" class="custom-control-input" checked />
                                                        <label class="custom-control-label" for="female">Female</label>
                                                    </div>
                                                </div> */}
                              </div>

                              <div class="col-12">
                                <h4 class="mb-1 mt-2">
                                  <i
                                    data-feather="map-pin"
                                    class="font-medium-4 mr-25"
                                  ></i>
                                  <span class="align-middle">
                                    {window.$language.Address}
                                  </span>
                                </h4>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="address-1">
                                    {
                                      window.$language
                                        .DoctorAddressofhealthcenter
                                    }
                                  </label>
                                  <input
                                    id="address-1"
                                    value={
                                      this.props.doc
                                        .doctorAddressOfTheHealthCenter
                                    }
                                    type="text"
                                    class="form-control"
                                    name="address"
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="address-1">
                                    {window.$language.City}
                                  </label>
                                  <input
                                    id="address-1"
                                    value={this.props.doc.city}
                                    type="text"
                                    class="form-control"
                                    name="address"
                                    readOnly
                                  />
                                </div>
                              </div>

                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label>Designation</label>
                                  <input
                                    defaultValue={this.props.doc.drDesignation}
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    name="zip"
                                    readOnly
                                  />
                                </div>
                              </div>
                              {/* <div class="col-lg-4 col-md-6">
                                                <div class="form-group">
                                                    <label for="city">City</label>
                                                    <input id="city" defaultValue={this.props.doc.fname} type="text" class="form-control" value="New York" name="city" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="form-group">
                                                    <label for="state">State</label>
                                                    <input id="state" defaultValue={this.props.doc.fname} type="text" class="form-control" name="state" placeholder="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                                <div class="form-group">
                                                    <label for="country">Country</label>
                                                    <input id="country" defaultValue={this.props.doc.fname} type="text" class="form-control" name="country" placeholder="" />
                                                </div>
                                            </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                  <div class="card mb-0">
                    <div class="card-body">
                      <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={this.docreg}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default docdetails;
