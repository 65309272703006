import React from "react";
import $ from "jquery";
// import '../css/dataTable.css';
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
// import dt from 'datatables.net';
$.DataTable = require("datatables.net");
require('datatables.net-rowgroup-dt' );
require( 'datatables.net-buttons' );

// $.fixedColumns = require('datatables.net-fixedcolumns');

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.dt = null;
  }
  componentDidMount() {
    this.$el = $(this.el);
    this.dataTable = this.$el.DataTable({
      data: this.props.data,
      columns: this.props.columns,
      ...this.props.options
    });
    let table = this.dataTable;
let props = this.props;
    this.$el.on('dblclick', 'tr', function () {
      var data = table.row( this ).data();
      (props.options.function.dblclick)(data);
      } );
  }

  componentWillUnmount() {
    this.dataTable.destroy(true);
  }

  search = value => {
    this.dataTable.search(value).draw();
  };

  render() {
    return <table ref={el => (this.el = el)} />;
  }
}
