import React from "react";
import "./SymLoder1.css";
export default function SymLoader() {
  return (
  <div id="overlay2">
   <div  className="loader-card">
      <h5 className="loader-text">{window.$language.PleaseWait}</h5>
    <img
    className="loder-img"
      src="../../../app-assets/images/pages/1_CsJ05WEGfunYMLGfsT2sXA.gif"
      alt="Login V2"
    />
     </div>
  </div>
   
  );
}
