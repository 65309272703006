import React, { Component } from "react";
import ReactDOM from "react-dom";
import Settings from "../dashboardComponent/Settings";
import Profile from "../User/userprofile/UserProfile";
import swal from "sweetalert";
import { API_URL } from "../../config";
import Dash from "../dashboardComponent/dashboard2";
import Lang from "../Settings/LangugeMaster";
import Testnmas from "../Settings/TestNameMaster";
import Eyeresult from "../EyetestComponent/Eyetestresult";
import $ from "jquery";
import "./header.css";

class Dashboard2 extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      usrdet: [],
      colapsed: 1,
      NotifyCount: 0,
      NotificationInfo: [],
      utype: localStorage.getItem("type"),
      isExternal: localStorage.getItem("isExternal"),
    };
    //this.getuserinfo=this.getuserinfo.bind(this)
  }
  getuserinfo() {
    console.log("Dhukeche bhai");
    let logourl = API_URL + "/users/profile";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
        //     referrer: "http://localhost:3000", // or "" to send no Referer header,
        //     // or an url from the current origin
        //     referrerPolicy: "no-referrer-when-downgrade", // no-referrer, origin, same-origin...
        //     mode: "cors", // same-origin, no-cors
      },
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        localStorage.setItem("opmail", json.email);
        this.setState({ usrdet: json });
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: "Merci de vous connecter à votre compte avec votre adresse e-mail valide et votre mot de passe.",
          icon: "warning",
        });

        console.log(e);
      });
  }
  componentDidMount() {
    let url1 = window.location.href;
    if (url1 == "https://app.symvisionlab.com") {
      window.location.href = "https://app.symcarelab.com/";
    }
    if (localStorage.length < 3) {
      window.location.href = "/login";
    }
    this.getuserinfo();
    this.getNotification();
    this.getNotificationInfo();
  }
  prof() {
    console.log("hi");
    ReactDOM.render(<Profile />, document.getElementById("c11"));
  }
  setting() {
    console.log("hi");
    ReactDOM.render(<Settings />, document.getElementById("c11"));
  }
  dash() {
    console.log("hi");
    ReactDOM.render(<Dash />, document.getElementById("c11"));
  }
  Languge() {
    console.log("hi");
    ReactDOM.render(<Lang />, document.getElementById("c11"));
  }
  Testnmas() {
    console.log("hi");
    ReactDOM.render(<Testnmas />, document.getElementById("c11"));
  }
  changeLanguge(l) {
    //alert(l)
    localStorage.setItem("sel-language", l);
    window.location.href = window.location.href;
  }
  logout() {
    localStorage.clear();
    const d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    let cvalue = 0;
    let cname = "loggedin";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    window.location.href = "/login";
  }
  getLanguageName(la) {
    switch (la) {
      case "en":
        return "English";
      case "fr":
        return "Français";
      case "de":
        return "German";
    }
  }
  menuu() {
    console.log("menu te dhukechi");
    if (this.state.colapsed == 1) {
      document.getElementById("menubarkor").removeAttribute("class");
      document
        .getElementById("menubarkor")
        .setAttribute(
          "class",
          "main-menu menu-fixed menu-light menu-accordion menu-shadow"
        );
      document.getElementById("root").removeAttribute("class");

      document
        .getElementById("root")
        .setAttribute(
          "class",
          "vertical-layout navbar-floating footer-static pace-done vertical-menu-modern menu-collapsed"
        );

      this.setState({ colapsed: 2 });
    } else {
      document.getElementById("menubarkor").removeAttribute("class");
      document
        .getElementById("menubarkor")
        .setAttribute(
          "class",
          "main-menu menu-fixed menu-light menu-accordion menu-shadow expanded"
        );
      document.getElementById("root").removeAttribute("class");

      document
        .getElementById("root")
        .setAttribute(
          "class",
          "vertical-layout navbar-floating footer-static pace-done vertical-menu-modern menu-expanded"
        );

      this.setState({ colapsed: 1 });
    }
  }
  getNotification() {
    fetch(API_URL + "/eyetests/optician/notifications", {
      method: "GET",
      headers: {
        Accept: "text/plain",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.text())
      .then((count) => {
        this.setState({ NotifyCount: count });
      })
      .catch((err) => console.log("error", err));
  }
  getNotificationInfo() {
    fetch(API_URL + "/eyetests/optician/notificationslist", {
      method: "POST",
      headers: {
        Accept: "text/plain",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((output) => {
        this.setState({ NotificationInfo: output });
      })
      .catch((err) => console.log("error", err));
  }

  switchToEyeTestresult2(value) {
    ReactDOM.render(
      <Eyeresult
        state={""}
        heading={"View All Eye Tests"}
        isPrescrip={value}
      />,
      document.getElementById("c11")
    );
    this.setState({ NotifyCount: 0 });
  }
  switchToEyeTestresult(value) {
    console.log("hi eyetest");
    ReactDOM.render(
      <Eyeresult
        status={""}
        heading={"View All Eye Tests"}
        isEyeTest={value}
      />,
      document.getElementById("c11")
    );
    this.setState({ NotifyCount: 0 });
  }
  notificationDropdown() {
    // document.getElementsByClassName("Comment-toggle").style.display = "block";
    this.setState({ NotifyCount: 0 });
    // console.log("call ho raha")
  }

  render() {
    return (
      <nav
        class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center"
        data-nav="brand-center"
      >
        <div class="navbar-header d-xl-block d-none">
          <ul class="nav navbar-nav">
            <img
              className="img-fluid2"
              src="../../../app-assets/images/logo/logo.png"
              alt="Logo"
            />
            <li class="nav-item"></li>
          </ul>
        </div>
        <div class="navbar-container d-flex content">
          <ul
            class="nav navbar-nav align-items-center ml-auto"
            onChange={(event) => this.changeLanguge(event)}
          >
            {(this.state.utype === "OPTICIAN" ||
              this.state.utype === "BACKOFFICE") && (
              <div
                style={{ marginTop: "11px" }}
                className=" d-flex align-items-center mr-1 position-relative"
              >
                <h3
                  type="button"
                  aria-expanded="false"
                  data-toggle="dropdown"
                  onClick={() => this.notificationDropdown()}
                >
                  <i className="bi bi-bell-fill text-primary"></i>
                </h3>
                <div className="notify">{this.state.NotifyCount}</div>
                {this.state.NotificationInfo.length > 0 ? (
                  <div className="btn-group">
                    <div
                      className="dropdown-menu dropdown-menu-right bg-white"
                      style={{
                        maxHeight: "270px",
                        overflowY: "auto",
                        width: "340px",
                        margin: "27px 0px 0px 0px",
                      }}
                    >
                      {this.state.NotificationInfo.map((item, idx) => {
                        return (
                          <a key={idx} className="dropdown-item">
                            <div
                              onClick={() => {
                                Number(item.prescriptionToOptician)
                                  ? this.switchToEyeTestresult2(item.testid)
                                  : this.switchToEyeTestresult(item.testid);
                              }}
                              className="border px-3 pt-1 border-primary rounded"
                              style={{ width: "300px", overflow: "hidden" }}
                            >
                              <h6 className="dropDownTitle">
                                {Number(item.prescriptionToOptician)
                                  ? "Ordonnance partagée"
                                  : "Nouveau commentaire"}
                              </h6>
                              <h6 className="text-primary fs-sm">
                                {(() => {
                                  const dateString =
                                    item.opticiancommentdate.split("T")[0];
                                  const [year, month, day] =
                                    dateString.split("-");
                                  return `${day}/${month}/${year}`;
                                })()}
                              </h6>
                              <h5 className="optcomment">
                                <i>{item.opticiancomment}</i>
                              </h5>
                              {/* <h5 className="text-primary fs-sm">{item.opticiancommentdate}</h5> */}
                              <h5>Test de : {item.patientName}</h5>
                              {/* <h5>{item.contactLense}</h5> */}
                            </div>
                          </a>
                        ); 
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            <li class="nav-item dropdown dropdown-language">
              <a
                class="nav-link dropdown-toggle"
                id="dropdown-flag"
                href="javascript:void(0);"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  class={
                    "flag-icon flag-icon-" +
                    (window.$SelectedLanguage == "en"
                      ? "gb"
                      : window.$SelectedLanguage)
                  }
                ></i>
                <span class="selected-language">
                  {this.getLanguageName(window.$SelectedLanguage)}
                </span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown-flag"
              >
                <a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  data-language="en"
                  onClick={() => this.changeLanguge("en")}
                >
                  <i class="flag-icon flag-icon-us"></i>{" "}
                  {/* {window.$language.English} */}Anglais
                </a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  data-language="fr"
                  onClick={() => this.changeLanguge("fr")}
                >
                  <i class="flag-icon flag-icon-fr"></i> Français
                </a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0);"
                  data-language="de"
                  onClick={() => this.changeLanguge("de")}
                >
                  <i class="flag-icon flag-icon-de"></i>{" "}
                  {/* {window.$language.German} */}Allemand
                </a>
              </div>
            </li>

            {/* <li class="nav-item dropdown dropdown-notification mr-25">
              <a
                class="nav-link"
                href="javascript:void(0);"
                data-toggle="dropdown"
              >
                <i class="ficon" data-feather="bell"></i>
                <span class="badge badge-pill badge-danger badge-up">0</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                <li class="dropdown-menu-header">
                  <div class="dropdown-header d-flex">
                    <h4 class="notification-title mb-0 mr-auto">
                    { window.$language.Notifications}
                    </h4>
                    <div class="badge badge-pill badge-light-primary">0</div>
                  </div>
                </li>
                <li class="dropdown-menu-footer">
                  <a
                    class="btn btn-primary btn-block"
                    href="javascript:void(0)"
                  >
                    { window.$language.Readallnotifications}
                  </a>
                </li>
              </ul>
            </li> */}
            <li class="nav-item dropdown dropdown-user">
              <a
                class="nav-link dropdown-toggle dropdown-user-link"
                id="dropdown-user"
                href="javascript:void(0);"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="user-nav d-sm-flex d-none">
                  <span class="user-name font-weight-bolder">
                    {this.state.usrdet.name + " " + this.state.usrdet.lastName}
                  </span>
                  <span class="user-status">{this.state.usrdet.userType}</span>
                </div>
                <span class="avatar">
                  <img
                    class="round img-thumbnail"
                    src={
                      this.state.usrdet.profileImagePath == ""
                        ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                        : API_URL + "/" + this.state.usrdet.profileImagePath
                    }
                    alt="avatar"
                    height="40"
                    width="40"
                  />
                  <span class="avatar-status-online"></span>
                </span>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown-user"
              >
                <a class="dropdown-item" onClick={this.dash}>
                  <i class="fas fa-home fa-fw fa-fs text-secondary mr-50"></i>{" "}
                  {window.$language.Dashboards}
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" onClick={() => this.prof()}>
                  <i class="fas fa-user fa-fw fa-fs text-secondary mr-50"></i>{" "}
                  {window.$language.Profile}
                </a>
                {this.state.utype == "ADMIN" ? (
                  <>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" onClick={this.Languge}>
                      <i class="fas fa-language mr-50"></i>{" "}
                      {window.$language.LanguageMaster}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" onClick={this.Testnmas}>
                      <i class="far fa-plus-square mr-50"></i>{" "}
                      {window.$language.TestNameMaster}
                    </a>
                  </>
                ) : null}
                {/* <div class="dropdown-divider"></div>
                <a class="dropdown-item instagram" href="../../../home/assets/Terms.pdf"  >
                <i class="far fa-file-pdf mr-50"></i> Termes et <br/> Conditions
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item instagram" href="../../../home/assets/PrivacyPolicy.pdf" >
                <i class="far fa-file-pdf mr-50"></i> Politique de <br/> confidentialité
                </a> */}
                 {this.state.isExternal == true ? (
                  <>
                 <div class="dropdown-divider"></div>
                <a class="dropdown-item"  href="https://share-eu1.hsforms.com/1AvcfStnBTvqQObQBaADnSAf13gu" target="_blank">
                  <i class="far fa-file-pdf mr-50"></i>{" "}
                  Demande de <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;procuration
                </a>
                </>
                ) : null}
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" onClick={() => this.logout()}>
                  <i class="fas fa-sign-out-alt mr-50"></i>{" "}
                  {window.$language.Logout}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
export default Dashboard2;
