
//export const API_URL = "http://localhost:5001";
//  export const API_URL = "http://localhost:5001";
 //export const API_URL = "http://localhost:5001";
//export const API_URL = "http://localhost:5001";
//export const  API_URL="http://localhost:52633"
//MobileAPP
//export const API_URL = "https://symoptic.westeurope.cloudapp.azure.com:5001";
//CRTest
//export const API_URL = "http://symoptic.westeurope.cloudapp.azure.com:5002";
//CRUat
//export const API_URL = "http://symoptic.westeurope.cloudapp.azure.com:5004";
//OpthAI
//export const API_URL = "http://symoptic.westeurope.cloudapp.azure.com:5003";
//Live
export const API_URL = "https://app.symvisionlab.com/api";
//CRTest
//export const OpAPI_URL = "http://symoptic.westeurope.cloudapp.azure.com:5002/Opthaitest";
//Live_Ophtai
export const opht_url ="/OpthAIliveAPIv2/OphtAI/imageAnalysisStatus/"