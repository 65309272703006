import React, { useState } from "react";
import ReactDOM from "react-dom";
import shortid from "shortid";
import swal from "sweetalert";
import { API_URL } from "../../config";
import "./BulkImageUpload.css";

export default function BulkImageUpload() {
    const [selectedfile, SetSelectedFile] = useState([]);
    const [Files, SetFiles] = useState([]);
    const allowedTypes = [
        //'image/webp',
        //'image/svg+xml',
        "image/png",
        "image/jpeg",
        "image/gif",
        //'image/apng',
        //'image/avif'
    ];

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const filesizeInMB = (bytes, decimals = 2) => {
        if (bytes === 0) return 0.0;
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const i = 2;
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    };

    const InputChange = (e) => {
        // --For Multiple File Input
        let images = [];
        let validFlags = ValidateFiles(e.target.files);
        console.log(validFlags);
        //console.log(validFlags);
        for (let i = 0; i < e.target.files.length; i++) {
            images.push(e.target.files[i]);
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            id: shortid.generate(),
                            filename: e.target.files[i].name,
                            filetype: e.target.files[i].type,
                            fileimage: reader.result,
                            datetime:
                                e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
                            filesize: filesizes(e.target.files[i].size),
                            isValid: validFlags
                                .filter((f) => f.imageFileName === e.target.files[i].name)
                                .map((m) => m.isValidFileName)[0],
                            testId: validFlags
                                .filter((f) => f.imageFileName === e.target.files[i].name)
                                .map((m) => m.eyeTestId)[0],
                            status: "Selected",
                        },
                    ];
                });
            };
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    };

    const DeleteSelectFile = (id) => {
        swal({
            title: "Veuillez confirmer",
            text: window.$language.TestAIAnalysisUploadActionDeleteConfirm,
            icon: "warning",
            buttons: ["Annuler", "D'accord"],
        }).then((willDelete) => {
            console.log(willDelete);
            if (willDelete) {
                const result = selectedfile.filter((data) => data.id !== id);
                SetSelectedFile(result);
            }
        });

        // if(window.confirm(window.$language.TestAIAnalysisUploadActionDeleteConfirm)){
        //     const result = selectedfile.filter((data) => data.id !== id);
        //     SetSelectedFile(result);
        // }else{
        //     // alert('No');
        // }
    };

    const FileUploadSubmit = async (e) => {
        e.preventDefault();

        // form reset on submit
        e.target.reset();
        if (selectedfile.length > 0) {
            const uploadUrl = API_URL + "/eyetests/image/upload1?CountId=0";
            let pendingFiles = [];
            for (let index = 0; index < selectedfile.length; index++) {
                if (!selectedfile[index].isValid) {
                    pendingFiles.push(selectedfile[index]);
                    continue;
                }else if (selectedfile[index].filesize =="0 Bytes"){
                    pendingFiles.push(selectedfile[index]);
                    continue;
                }
                const file = DataURIToBlob(selectedfile[index].fileimage);
                const formData = new FormData();
                formData.append("EyeTestImage", file, selectedfile[index].filename);
                formData.append("EyeTestId", selectedfile[index].testId);
                console.log(formData);
                const request = new XMLHttpRequest();
                request.open("POST", uploadUrl, false); // `false` makes the request synchronous
                //request.setRequestHeader('Content-Type', 'multipart/form-data');
                request.setRequestHeader(
                    "Authorization",
                    localStorage.getItem("token")
                );
                request.send(formData);
                if (request.status !== 200) {
                    // handle an error here
                    selectedfile[index].status =
                        window.$language.TestAIAnalysisUploadIndicatorFailedToUpload;
                    console.log("error");
                    pendingFiles.push(selectedfile[index]);
                    continue;
                } else {
                    const uploadResponse = request.responseText;
                    //console.log(uploadResponse);
                    if (uploadResponse !== "true") {
                        selectedfile[index].status =
                            window.$language.TestAIAnalysisUploadIndicatorFailedToUpload;
                        pendingFiles.push(selectedfile[index]);
                        continue;
                    } else {
                        selectedfile[index].status =
                            window.$language.TestAIAnalysisUploadIndicatorUploaded;
                    }
                    SetFiles((preValue) => {
                        return [...preValue, selectedfile[index]];
                    });
                }
            }
            SetSelectedFile(pendingFiles);
        } else {
            //alert(window.$language.TestAIAnalysisUploadIndicatorNoFilesChosen)
            swal({
                title: "OOPS!",
                text: window.$language.TestAIAnalysisUploadIndicatorNoFilesChosen,
                icon: "warning",
            });
        }
    };

    const DeleteFile = async (id) => {
        swal({
            title: "Veuillez confirmer",
            text: window.$language.TestAIAnalysisUploadActionDeleteConfirm,
            icon: "warning",
            buttons: ["Annuler", "D'accord"],
        }).then((willDelete) => {
            console.log(willDelete);
            if (willDelete) {
                const result = Files.filter((data) => data.id !== id);
                SetFiles(result);
            }
        });

        // if(window.confirm(window.$language.TestAIAnalysisUploadActionDeleteConfirm)){
        //     const result = Files.filter((data)=>data.id !== id);
        //     SetFiles(result);
        // }else{
        //     // alert('No');
        // }
    };

    const ValidateFiles = (files) => {
        //console.log(files);
        const validateUrl = API_URL + "/eyetests/validateImages";
        const fileNames = Object.values(files).map((item) =>
            JSON.parse('{"imagefilename":"' + item.name + '"}')
        );
        //console.log(fileNames);
        const request = new XMLHttpRequest();
        request.open("POST", validateUrl, false); // `false` makes the request synchronous
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Authorization", localStorage.getItem("token"));
        request.send(JSON.stringify(fileNames));
        if (request.status !== 200) {
            // handle an error here
            return null;
        }
        // console.log(request.responseText);
        const validateResponse = JSON.parse(request.responseText);
        console.log(validateResponse);
        // console.log(files);
        for (let i = 0; i < validateResponse.length; i++) {
            if (!allowedTypes.includes(files[i].type)) {
                validateResponse[i].isValidFileName = false;
            }
            if (filesizeInMB(files[i].size) > 10.0) {
                validateResponse[i].isValidFileName = false;
            }
        }
        console.log(validateResponse);
        return validateResponse;
    };

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    return (
        <>
            <div className="fileupload-view">
                <div className="row justify-content-center m-0">
                    <div className="col-md-6">
                        <div className="card mt-5">
                            <div className="card-body">
                                <div className="kb-data-box">
                                    <div className="kb-modal-data-title">
                                        <div className="kb-data-title">
                                            <h6>{window.$language.TestAIAnalysisUploadHeader}</h6>
                                            <p>{window.$language.TestAIAnalysisUploadGuidance}</p>
                                        </div>
                                    </div>
                                    <form onSubmit={FileUploadSubmit}>
                                        <div className="kb-file-upload">
                                            <div className="file-upload-box">
                                                <input
                                                    type="file"
                                                    id="fileupload"
                                                    className="file-upload-input"
                                                    onChange={InputChange}
                                                    multiple
                                                />
                                                <span>
                                                    {
                                                        window.$language
                                                            .TestAIAnalysisUploadDragAndDropGuidance
                                                    }
                                                    <span className="file-link">
                                                        {
                                                            window.$language
                                                                .TestAIAnalysisUploadChooseFilesGuidance
                                                        }
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="kb-attach-box mb-3">
                                            {selectedfile.map((data, index) => {
                                                console.log(data);
                                                const {
                                                    id,
                                                    filename,
                                                    filetype,
                                                    fileimage,
                                                    datetime,
                                                    filesize,
                                                    isValid,
                                                    testId,
                                                    status,
                                                } = data;
                                                const isZeroKB = parseFloat(filesize) === 0;
                                                return (
                                                    <div className="file-atc-box" key={id}>
                                                        {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                                                            <div className="file-image">
                                                                {" "}
                                                                <img src={fileimage} alt="" />
                                                            </div>
                                                        ) : (
                                                            <div className="file-image">
                                                                <i className="far fa-file-alt"></i>
                                                            </div>
                                                        )}
                                                        <div className="file-detail">
                                                            <h6>
                                                                {filename}&nbsp;&nbsp;
                                                                <font color="darkgray">({filesize})</font>
                                                                &nbsp;&nbsp;
                                                            </h6>
                                                            {/* <p></p> */}
                                                            {/* <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p> */}
                                                            <div className="file-actions">
                                                                <button
                                                                    type="button"
                                                                    className="file-action-btn"
                                                                    onClick={() => DeleteSelectFile(id)}
                                                                >
                                                                    {
                                                                        window.$language
                                                                            .TestAIAnalysisUploadActionDelete
                                                                    }
                                                                </button>
                                                                {isZeroKB ? (
                                                                    <font color="red">
                                                                        &#x2715;&nbsp;&nbsp;
                                                                        Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.
                                                                    </font>
                                                                ) : isValid ? (
                                                                    <font color="green">
                                                                        &#x2713;&nbsp;&nbsp;
                                                                        {
                                                                            window.$language
                                                                                .TestAIAnalysisUploadIndicatorValidimage
                                                                        }
                                                                    </font>
                                                                ) : (
                                                                    <font color="red">
                                                                        &#x2715;&nbsp;&nbsp;
                                                                        {
                                                                            window.$language
                                                                                .TestAIAnalysisUploadIndicatorInvalidFile
                                                                        }
                                                                    </font>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="kb-buttons-box">
                                            <button
                                                type="submit"
                                                className="btn btn-primary2 form-submit"
                                            >
                                                {window.$language.TestAIAnalysisUploadActionUpload}
                                            </button>
                                        </div>
                                    </form>
                                    {Files.length > 0 ? (
                                        <div className="kb-attach-box">
                                            <hr />
                                            {Files.map((data, index) => {
                                                const {
                                                    id,
                                                    filename,
                                                    filetype,
                                                    fileimage,
                                                    datetime,
                                                    filesize,
                                                    isValid,
                                                    testId,
                                                    status,
                                                } = data;
                                                return (
                                                    <div className="file-atc-box" key={index}>
                                                        {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                                                            <div className="file-image">
                                                                {" "}
                                                                <img src={fileimage} alt="" />
                                                            </div>
                                                        ) : (
                                                            <div className="file-image">
                                                                <i className="far fa-file-alt"></i>
                                                            </div>
                                                        )}
                                                        <div className="file-detail">
                                                            <h6>
                                                                {filename}&nbsp;&nbsp;
                                                                <font color="darkgray">({filesize})</font>
                                                            </h6>
                                                            {/* <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p> */}
                                                            <div className="file-actions">
                                                                {/* <button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button> */}
                                                                <a
                                                                    href={fileimage}
                                                                    className="file-action-btn"
                                                                    download={filename}
                                                                >
                                                                    {
                                                                        window.$language
                                                                            .TestAIAnalysisUploadActionDownload
                                                                    }
                                                                </a>
                                                                <font color="green">
                                                                    &#x2713;&nbsp;&nbsp;{status}
                                                                </font>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
