import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import DataTable from "../datatables";
import { API_URL } from "../../config";
import swal from "sweetalert";

class TestNameMaster extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      testdet: [],
      count: 1,
    };
  }
  delete(email) {
    swal({
      title: "Are you sure?",
      text: "Are you sure to Delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/testnames/delete";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            testCode: email,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: "Deleted Successfully",
                icon: "success",
              });
              this.componentDidMount();
            } else {
              swal({
                title: "OOPS!",
                text: "Something went wong",
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: "Something went wong",
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  submit() {
    /* Validations */
    var ret = false;
    $("#inputform")
      .find(".form-group, .form-row")
      .find(".form-control")
      .each(function () {
        if ($(this).val() == "") {
          swal({
            title: "OOPS!",
            text: $(this).parent().find("label").html() + " can not be blank!",
            icon: "warning",
          });
          ret = true;
          return false;
        }
      });

    if (ret) return false;
    /* Validations */

    let data1 = {
      testName: document.getElementById("tname").value,
      testCode: document.getElementById("tcode").value,
    };
    /////////////////
    let logourl = API_URL + "/Products/add";
    let msg = "";
    if (this.state.count == 1) {
      logourl = API_URL + "/testnames/add";
      msg = "Test Added successfully";
    } else {
      logourl = API_URL + "/testnames/edit";
      msg = "Test edited successfully";
    }
    /////////////////////
    // let logourl = API_URL + "/Products/add";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //this.setState({usrdet:json})
        // alert(json)
        swal({
          title: "Done!",
          text: msg,
          icon: "success",
        });
        document.getElementById("close").click();
        this.componentDidMount();
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: "Something went wrong!",
          icon: "warning",
        });

        console.log(e);
      });
    //////
  }
  componentDidMount() {
    var token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    let logourl = API_URL + "/testnames";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.setState({ testdet: json });
        /* ReactDOM.render(
              <DataTable
                data={
                  json
                }
    
                columns={[
                  { title: "Test Name", data: "testName" },
                  { title: "Test Code", data: "testCode" }
    
                ]}
                options={{
                  dom: "lrtip", // https://datatables.net/reference/option/dom
                  "paging": false,
                  "ordering": false,
                  scrollX: true,
                  scrollY: "100%",
                  scrollCollapse: false,
                  buttons: [
                    'copy', 'excel', 'pdf'
                  ],
                  function: {
                    
                  },
                  // scrollY: '500',
                  autoWidth: false,
                  // search: false,
                  info: false
                  // fixedColumns: true
                }}
              />,
              document.getElementById("hh")
            );*/
      });
  }

  addnew() {
    this.setState({
      count: 1,
    });
    this.reset();
    //document.getElementById("tcode").removeAttribute("readonly")
  }
  edit(usr) {
    this.setState({
      count: 2,
    });
    this.reset();
    document.getElementById("tname").value = usr.testName;
    document.getElementById("tcode").value = usr.testCode;

    //document.getElementById("tcode").setAttribute("disabled", "readonly")
  }
  reset() {
    document.getElementById("tname").value = "";
    document.getElementById("tcode").value = "";
  }
  render() {
    return (
      <>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section id="basic-datatable">
              <div class="row">
                <div class="col">
                  <div class="card px-2 pb-4">
                    <div class="card-header border-bottom mr-1">
                      <div class="head-label">
                        <h4 class="mb-0">{window.$language.TestName}</h4>
                      </div>
                      <div class="dt-action-buttons text-right">
                        <div class="dt-buttons d-inline-flex">
                          <button
                            class="btn btn-primary mr-1"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            type="button"
                            data-toggle="modal"
                            data-target="#modals-slide-in"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus mr-50 font-small-4"
                              >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                              </svg>
                              {window.$language.AddNewTest}
                            </span>
                          </button>{" "}
                        </div>
                      </div>
                    </div>

                    <hr class="my-0" />
                    <div class="d-flex justify-content-between align-items-center mx-0 row"></div>

                    <table class="datatables-basic table mt-1 shadow  table-hover">
                      <thead>
                        <tr>
                          <th>{window.$language.TestName}</th>
                          <th>{window.$language.TESTCODE}</th>

                          <th>{window.$language.Actions}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.testdet.map((usr) => (
                          <tr>
                            <td>{usr.testName}</td>
                            <td>{usr.testCode}</td>

                            <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  data-toggle="modal"
                                  data-target="#modals-slide-in"
                                  href="javascript:void(0);"
                                  onClick={() => this.edit(usr)}
                                  data-placement="top"
                                  title="Edit"
                                >
                                  <i class="far fa-edit fa-fw"></i>
                                </button>
                                <button
                                  type="button"
                                  onClick={() => this.delete(usr.testCode)}
                                  class="btn btn-outline-secondary"
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                >
                                  <i class="far fa-trash-alt fa-fw"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="modal modal-slide-in fade" id="modals-slide-in">
                <div class="modal-dialog sidebar-sm w-25">
                  <form class="add-new-record modal-content pt-0">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </button>
                    <div class="modal-header mb-1">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {window.$language.NewRecord}
                      </h5>
                    </div>
                    <div class="modal-body flex-grow-1">
                      <div class="form-row mb-1">
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.TestName}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="tname"
                            aria-label=""
                          />
                        </div>
                        <div class="col-lg-12">
                          <label
                            class="form-label"
                            for="basic-icon-default-fullname"
                          >
                            {window.$language.TESTCODE}
                          </label>
                          <input
                            type="text"
                            class="form-control dt-full-name"
                            id="tcode"
                            aria-label=""
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary data-submit mr-1"
                        onClick={() => this.submit()}
                      >
                        {window.$language.Submit}
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary"
                        id="close"
                        data-dismiss="modal"
                      >
                        {window.$language.Cancel}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}
export default TestNameMaster;
