import React, { Component } from "react";
import ReactDOM from "react-dom";
import Order from "./Orderprocessing";
import swal from "sweetalert";
import { API_URL } from "../../config";
//import {API_URL} from '../../config'
import "./Orderpage.css";
import ReactHtmlParser from "react-html-parser";

function extractPrice(value) {
  const match = value.match(/(\d+(\.\d{1,2})?) €/);
  return match ? parseFloat(match[1]) : 0;
}
class Orderdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      // orderdett: [],
      isExternal: localStorage.getItem("isExternal"),
      pLogo: "",
      utype: localStorage.getItem("type"),
    };
    this.Order = this.Order.bind(this);

    console.log(this.props, "propsvalue");
  }
  componentDidMount() 
  {
    for (let i = 0; i < this.props.det1.length; i++) 
    {
      // if (this.props.det1[i].orderType != null) 
      // {
      //   let ordt = this.props.det1[i].orderType.split(",");
      //   this.checkUncheck("orderType", ordt);
      // }
      // if (this.props.det1[i].orderIndex != null) {
      //   let ordin = this.props.det1[i].orderIndex.split(",");
      //   this.checkUncheck("orderIndex", ordin);
      // }
      // if (this.props.det1[i].orderTint != null) {
      //   let ordti = this.props.det1[i].orderTint.split(",");
      //   this.checkUncheck("orderTint", ordti);
      // }
      // if (this.props.det1[i].lensShape != null) {
      //   let lensh = this.props.det1[i].lensShape.split(",");
      //   this.checkUncheck("lensShape", lensh);
      // }

      $("#" + this.props.det1[i].orderType.replace(" ", "") +i ).prop("checked", true);
      $("#" + this.props.det1[i].orderTint.replace(" ", "")+ i).prop("checked", true);
      if(this.props.det1[i].orderIndex!=null)
      {
        $("#" + this.props.det1[i].orderIndex.substring(2,4) + "_" + i).prop("checked", true);
      
      }

      if(this.props.det1[i].orderTint=="Anti Lumiere Bleue")
      {
        $("#AntiLumiereBleue" + i).prop("checked", true);
      }
       $('#orderstat').val(this.props.det1[i].status)
    }
    this.totalPrice()
  }
  totalPrice() {
    debugger;
    // Use optional chaining to safely access the price properties
    const price1 = this.props.det1?.[0]?.price ? extractPrice(this.props.det1[0].price) : 0;
    const price2 = this.props.det1?.[1]?.price ? extractPrice(this.props.det1[1].price) : 0;
    const price3 = this.props.det1?.[2]?.price ? extractPrice(this.props.det1[2].price) : 0;
    const price4 = this.props.det1?.[3]?.price ? extractPrice(this.props.det1[3].price) : 0;
    const totalPrice = price1 + price2 + price3 + price4;
    console.log(totalPrice);
    
    return `${totalPrice.toFixed(2)} €`; // Optionally, return the calculated total price
  }
  checkUncheck(elementName, allValues) {
    let arrValues = document.getElementsByName(elementName);
    for (let i = 0; i < arrValues.length; i++) {
      arrValues[i].checked = allValues.indexOf(arrValues[i].value) > -1;
    }
  }

  submit() {}
//  Orderdetails(patientsemail)
//  {
//   debugger
//     var token = localStorage.getItem("token");
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: token,
//       },
//       body: JSON.stringify({
//         search:patientsemail,
//         pageNumber: 1,
//         pageSize: 100,
//       }),
//     };
//     let logourl = API_URL + "/Orders/search";
//     fetch(logourl, requestOptions)
//       .then((res) => res.json())
//       .then((json) => 
//       {
//        this.setState({
//         orderdett: json.orderModelList 
//         });
//         this.Order();
//       })
//       .catch((e) => {
//         swal({
//           title: "OOPS!",
//           text: window.$language.Somethingwentwrong,
//           icon: "warning",
//         });
//         console.log(e);
//       });
//   };
  Order() {
    console.log("hi");
    //this.props.vv= 2
    // ReactDOM.render(<Order det={this.props.det} />, document.getElementById('c11'));
    ReactDOM.render(
      <Order val={this.props.val} vv={this.props.vv} det={this.props.det} />,
      document.getElementById("c11")
    );
  }
  // Order2() {
  //   ReactDOM.render(
  //     <Order/>,
  //     document.getElementById("c11")
  //   );
  // }
  notify() {
    // let leng = document.getElementsByName("deletecheck").length;
    //alert(leng)
    let arr1 = [];
    // for (let i = 0; i < leng; i++) {
    //     if (document.getElementsByName("deletecheck")[i].checked) {
    //         arr1.push(document.getElementsByName("deletecheck")[i].value)
    //     }
    // }
    arr1.push(this.props.det1.length > 0 ? this.props.det1[0].infoOrderCombineId : "");
    console.log(arr1);
    let msg =
    document.getElementById("orderstat").value == "Annulé"
      ? "Annulée"
      : document.getElementById("orderstat").value == "Pending"
      ? window.$language.Pending
      : document.getElementById("orderstat").value == "Commandé"
      ? "Commandée"
      : document.getElementById("orderstat").value == "ToBeOrdered"
      ? "À commander"
      : "SAV";
    swal({
      title: "Souhaitez-vous confirmer cette modification ?",
      text:
        ' En cliquant sur OK, le statut de la commande sera changé en "' +
        msg +
        '" ?',
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) 
      {
        let logourl = API_URL + "/Orders/ChangeStatus";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            ordernos: arr1,
            status: document.getElementById("orderstat").value,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => 
          {
            if (json == true) 
            {  swal({
                title: "Done!",
                text: window.$language.OrderStatusChangedSuccessfully,
                icon: "success",
              });
              document.getElementById("close112209").click();
              this.Order();
            } else 
            {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => 
          {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  submitOpticbox(orderid, ordernumber, status,combineId) 
  {
    if (document.getElementById("opticbox").value=="") 
    {
      swal({
        title: "OOPS!",
        text: "Veuillez sélectionner l'identifiant de la boîte optique",
        icon: "warning",
      });
      return;
    }
    else
    {
      swal({
        title: window.$language.Areyousure,
        text: "Merci de vous assurer que le numéro de dossier de optic-box est correctement saisi. Il doit contenir 8 chiffres (pas de lettres). Une fois envoyé, l'identifiant ne peut plus être modifié.",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        //dangerMode: true,
      }).then((willDelete) => 
      {
        if (willDelete) 
        {
          let logourl = API_URL + "/Orders/addopticbox";
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.getItem("token"),
            },
            body: JSON.stringify([
              {
                OrderId: orderid,
                OrderNumber: ordernumber,
                OpticboxId: document.getElementById("opticbox").value,
                Status: "ToBeOrdered",
                combineId:combineId
              },
            ]),
          };
          fetch(logourl, requestOptions)
            .then((res) => res.text())
            .then((text) => {
              if (text == "success") {
                swal({
                  title: "Done!",
                  text: "Submited",
                  icon: "success",
                });
                $(".hiddenbu").hide()
                $(".disabledin").prop("disabled",true)
              } else {
                swal({
                  title: "OOPS!",
                  text: window.$language.Somethingwentwrong,
                  icon: "warning",
                });
              }
              //this.setState({opcarr:json})
            })
            .catch((e) => {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
  
              console.log(e);
            });
        }
      });
    } 
   
    
  }
  render() {
    const totalPrice = this.totalPrice();
    return (
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section class="invoice-preview-wrapper">
          <div class="row invoice-preview">   
            <div class="col-9">
              {this.props.det1.map((l,count) => (
                <section class="app-user-edit pt-0">
                  <div class="card px-2 pb-4">
                    <div class="card-body">
                      <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          ></a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <h4>Paire-{l.infoOrderPair}</h4>
                            <form class="form-validate">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                  Date de commande
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={l.orderCreated}
                                    readOnly
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="username">Opticien</label>
                                  <input
                                    type="text"
                                    value={l.opticianName}
                                    readOnly
                                    class="form-control"
                                    name="username"
                                    id="username"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">PDV</label>
                                  <input
                                    type="text"
                                    value={l.posName}
                                    readOnly
                                    class="form-control"
                                    name="name"
                                    id="name"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">Patient</label>
                                  <input
                                    type="text"
                                    value={l.patientName}
                                    readOnly
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">Email patient</label>
                                  <input
                                    type="text"
                                    value={l.patientEmail}
                                    readOnly
                                    class="form-control"s
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                  Statut
                                  </label>
                                  <input
                                    type="text"
                                    name="deletecheck"
                                    value={
                                      l.status == "Annulé" ||
                                      l.status == "Cancelled"
                                        ? "Annulée"
                                        : l.status == "Pending" ||
                                          l.status == "En attente"
                                        ? window.$language.Pending
                                        : l.status == "Ordered" ||
                                          l.status == "Commandé"
                                        ? "Commandée"
                                        : l.status == "SAV"
                                        ? "SAV"
                                        : "À commander"
                                    }
                                    readOnly
                                    class="form-control"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                  Número de commande
                                  </label>
                                  <input
                                    type="text"
                                    value={l.orderNumber}
                                    readOnly
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                              <div class="table-responsive mt-2">
                                <table class="table">
                                  <thead>
                                    {/* {this.props.det.isContactLensType ==
                                    false ? ( */}
                                    <tr>
                                      <th class="border border-primary">
                                        {window.$language.TYPEOFTEST}
                                      </th>
                                      <th
                                        class="border border-primary align-middle"
                                        colSpan="5"
                                      >
                                        {window.$language.RIGHTEYE}
                                      </th>
                                      <th
                                        class="border border-primary align-middle"
                                        colSpan="5"
                                      >
                                        {window.$language.LEFTEYE}
                                      </th>
                                    </tr>
                                    {/* //) : null} */}
                                    {/* {this.props.det.isContactLensType ==
                                    true ? (
                                      <tr>
                                        <th class="border border-primary">
                                          {window.$language.TYPEOFTEST}
                                        </th>
                                        <th
                                          class="border border-primary align-middle"
                                          colSpan="4"
                                        >
                                          {window.$language.RIGHTEYE}
                                        </th>
                                        <th
                                          class="border border-primary align-middle"
                                          colSpan="4"
                                        >
                                          {window.$language.LEFTEYE}
                                        </th>
                                      </tr>
                                    ) : null} */}
                                    <tr>
                                      <th class="px-1 text_align"></th>
                                      <th class="px-0 text_align">SPH</th>
                                      <th class="px-0 text_align">CYL</th>
                                      <th class="px-0 text_align">AXE</th>
                                      <th class="px-0 text_align">ADD</th>
                                      <th class="px-0 text_align">PD</th>

                                      <th class="px-0 d-none ">PHIGH</th>

                                      <th class="px-0 text_align">SPH</th>
                                      <th class="px-0 text_align">CYL</th>
                                      <th class="px-0 text_align">AXE</th>
                                      <th class="px-0 text_align">ADD</th>
                                      <th class="px-0 text_align">PD</th>

                                      <th class="px-0 d-none ">PHIGH</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.det.status == "Approved" ? (
                                      <>
                                      <tr>
                                        <td class="py-1 px-1">
                                          Prescription finale
                                        </td>
                                        <td class="py-1 px-1 text_align ">
                                          {l.orderRightSPH}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderRightCYL}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderRightAXIS}
                                        </td>
                                        <td class="py-1 px-1 text_align ">
                                          {l.orderRightADD}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderRightPD}
                                        </td>
                                        <td class="py-1 px-1 d-none ">
                                          {l.orderRightPDHIGH}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderLeftSPH}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderLeftCYL}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderLeftAXIS}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderLeftADD}
                                        </td>
                                        <td class="py-1 px-1 text_align">
                                          {l.orderLeftPD}
                                        </td>
                                        <td class="py-1 px-1 d-none">
                                          {l.orderLeftPDHIGH}
                                        </td>
                                      </tr>
                                       <tr>
                                       <td class="py-1 px-1">
                                       Commande
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderRightSPH}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderRightCYL}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderRightAXIS}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderRightADD}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderRightPD}
                                       </td>
                                       <td class="py-1 px-1 d-none">
                                         {l.orderRightPDHIGH}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderLeftSPH}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderLeftCYL}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderLeftAXIS}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderLeftADD}
                                       </td>
                                       <td class="py-1 px-1 text_align">
                                         {l.orderLeftPD}
                                       </td>
                                       <td class="py-1 px-1 d-none">
                                         {l.orderLeftPDHIGH}
                                       </td>
                                     </tr>
                                     </>
                                    ) : (
                                      <>
                                        {" "}
                                        <tr>
                                          <td class="py-1 px-1">
                                            Reco Opticien
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l.recoOpticianRightSPH
                                            }
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l.recoOpticianRightCYL
                                            }
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianRightAXIS
                                            }
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianRightADD
                                            }
                                          </td>

                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianRightPD
                                            }
                                          </td>

                                          <td class="py-1 px-1 d-none">
                                            {
                                              l
                                                .recoOpticianRightPDHIGH
                                            }
                                          </td>

                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianLeftSPH
                                            }
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianLeftCYL
                                            }
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianLeftAXIS
                                            }
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {
                                              l
                                                .recoOpticianLeftADD
                                            }
                                          </td>

                                          <td class="py-1 px-1 text_align">
                                            {l.recoOpticianLeftPD}
                                          </td>

                                          <td class="py-1 px-1 d-none">
                                            {
                                              l
                                                .recoOpticianLeftPDHIGH
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="py-1 px-1">
                                            {window.$language.Orders}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderRightSPH}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderRightCYL}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderRightAXIS}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderRightADD}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderRightPD}
                                          </td>
                                          <td class="py-1 px-1 d-none">
                                            {l.orderRightPDHIGH}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderLeftSPH}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderLeftCYL}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderLeftAXIS}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderLeftADD}
                                          </td>
                                          <td class="py-1 px-1 text_align">
                                            {l.orderLeftPD}
                                          </td>
                                          <td class="py-1 px-1 d-none">
                                            {l.orderLeftPDHIGH}
                                          </td>
                                        </tr>{" "}
                                      </>
                                    )}
                                    <tr>
                                      <td class="p-1">Hauteur pup</td>
                                      <th colSpan="5">
                                        <input
                                          id="Heier"
                                          class="form-control flatpickr-basic"
                                          value={l.rightHeightEye}
                                          readOnly
                                        />
                                      </th>
                                      <th colSpan="5">
                                        <input
                                          id="Heiel"
                                          class="form-control flatpickr-basic"
                                          value={l.leftHeightEye}
                                          readOnly
                                        />
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <hr class="mb-2" />
                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                              <div class="form-row col-12">
                              <div class="col-lg-4">
                                  <label>Type de Panier</label>
                                  <input
                                    type="text"
                                    value={l.lensShape}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                              <div class="col-lg-4">
                                  <label>Tiers Payant</label>
                                  <input
                                    type="text"
                                    value={l.tiersPayant}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label>Type of payment</label>
                                  <input
                                    type="text"
                                    value={l.typeofpayment}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                              <div class="form-row col-12">
                              <div class="col-lg-4">
                                  <label>Type de vision:</label>
                                  <input
                                    type="text"
                                    value={l.typeDeVision}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                              <div class="col-lg-4">
                                  <label>Type de Verres:</label>
                                  <input
                                    type="text"
                                    value={l.orderType}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label>{window.$language.Index} :</label>
                                  <input
                                    type="text"
                                    value={l.orderIndex}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div> 
                              </div>
                              <div class="form-row col-12">
                                <div class="col-lg-4">
                                  <label>Couleur</label>
                                  <input
                                    type="text"
                                    value={l.frameColor}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label>Option de verre</label>
                                  <input
                                    type="text"
                                    value={l.lensTypeDetails}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label>Traitement</label>
                                  <input
                                    type="text"
                                    value={l.treatment}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                </div>
                                <div class="form-row col-12">
                                <div class="col-lg-4">
                                  <label>Type de monture</label>
                                  <input
                                    type="text"
                                    value={l.frameType}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label>Monture</label>
                                  <input
                                    type="text"
                                    value={l.frameReference}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label>Fournisseur (à remplir par le BO)</label>
                                  <input
                                    type="text"
                                    value={l.supplier}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                </div>
                              <div class="form-row col-lg-12">
                                <div class="col-lg-6">
                                  <label>Prix</label>
                                  <input
                                    type="text"
                                    value={l.price}
                                    readOnly
                                    class="form-control flatpickr-basic"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="email">
                                      {window.$language.Commentsontheorder}
                                    </label>
                                    <textarea
                                      type="text"
                                      value={l.comments}
                                      readOnly
                                      class="form-control"
                                      name="email"
                                      id="email"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="form-row col-lg-12">
                                <div class="col-lg-6 d-none">
                                  <div class="form-group">
                                    <label for="email">
                                      {window.$language.OtherComments}
                                    </label>
                                    <textarea
                                      type="text"
                                      value={l.otherComments}
                                      readOnly
                                      class="form-control"
                                      name="email"
                                      id="email"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>))}
              </div>

                <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                  <div class="card mb-0">
                    <div class="card-body">
                    <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={this.Order}
                        // onClick={this.Order}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                    </a>

                          <a
                            class="btn btn-outline-secondary btn-block mb-75"
                            id="assign"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#modals-slide-in-1"
                          >
                            {/* <i class="fas fa-angle-double-left mr-50"></i> */}
                            <span>{window.$language.ChangeStatus}</span>
                          </a>
                      
                      {/* <a class="btn btn-outline-danger btn-block mb-75" target="_blank" onClick={()=>this.notify1()} >
                                    <i class="fas fa-angle-double-left mr-50"></i>
                                    <span>{ window.$language.CancelOrder}</span>
                                    </a>   */}
            {this.state.utype == "BACKOFFICE" ||
              this.state.utype == "ADMIN" ||
              (this.state.utype == "OPTICIAN" &&
                this.state.isExternal !== null &&
                this.state.isExternal == "false") ? (
                     <>
                       <label for="opticbox">Número de dossier optic-box</label>
                      {this.props.det1[0].infoSubmittedJSON == "1" ? (
                        <input
                          type="text"
                          class="form-control flatpickr-basic"
                          value={this.props.det1[0].infoOpticBoxId}
                          readOnly
                        />
                      ) : (
                        <input
                          type="text"
                          class="form-control flatpickr-basic disabledin"
                          id="opticbox"
                          placeholder="ID"
                        />
                      )}

                      {this.props.det1[0].infoSubmittedJSON !== "1" ? (
                        <a
                          class="btn btn-outline-primary btn-block mb-75 mt-1 hiddenbu"
                          target="_blank"
                          onClick={() =>
                            this.submitOpticbox(
                              this.props.det1[0].orderId,
                              this.props.det1[0].orderNumber,
                              this.props.det1[0].status,
                              this.props.det1[0].infoOrderCombineId
                            )
                          }
                        >
                          <span>Envoyer</span>
                        </a>
                      ) : null}
                      {this.props.det1[0].infoSubmittedJSON == "1" ? (
                      <a
                        class="p-6"
                        target="_blank"
                        href={
                          this.props.det1[0].infoOpticBoxPath == null ||
                          this.props.det1[0].infoOpticBoxPath == ""
                            ? null
                            : API_URL + this.props.det1[0].infoOpticBoxPath   
                        }
                      >
                        Order Check
                      </a> ) : null} 
                      </>) : null}

                  <div>
                  <label>Prix total de toutes les paires</label>
                  <input
                    type="text"
                    class="form-control flatpickr-basic"
                    value= {totalPrice}
                    placeholder=""
                    readOnly
                  />
                </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div
                        class="modal modal-slide-in fade"
                        id="modals-slide-in-1"
                      >
                        <div class="modal-dialog sidebar-sm">
                          <form class="add-new-record modal-content pt-0">
                            <button
                              type="button"
                              class="close"
                              id="www4"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              ×
                            </button>
                            <div class="modal-header mb-1">
                              <h5 class="modal-title" id="exampleModalLabel">
                                {window.$language.ChangeStatus}
                              </h5>
                            </div>
                            <div class="modal-body flex-grow-1">
                              <div>
                                <form class="dt_adv_search" method="POST">
                                  <div class="col-lg-12">
                                    <div class="form-group">
                                      <label for="languages">
                                        {window.$language.ChangeStatus}
                                      </label>
                                      <select
                                        id="orderstat"
                                        class="form-control"
                                      >
                                        <option value="Pending">
                                          {window.$language.Pending}
                                        </option>
                                        <option value="SAV">SAV</option>
                                        <option value="ToBeOrdered">
                                          À commander
                                        </option>
                                     
                                        <option value="Commandé">
                                        Commandée
                                        </option>
                                        <option value="Annulé">Annulée</option>
                                      </select>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <button
                                type="button"
                                class="btn btn-primary data-submit mr-1 m-1"
                                onClick={() => this.notify()}
                              >
                                {window.$language.ChangeStatus}
                              </button>
                              <button
                                type="reset"
                                class="btn btn-outline-secondary m-1"
                                id="close112209"
                                data-dismiss="modal"
                              >
                                {window.$language.Cancel}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default Orderdetails;
