import React, { Component } from "react";
import ReactDOM from "react-dom";
import Dash from "../../dashboardComponent/dashboard2";
import { API_URL } from "../../../config";
import swal from "sweetalert";
import Head from "../../header/header";
import $ from "jquery";
class Userprofile extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      utype: localStorage.getItem("type"),
      usrdet: [],
      email: "",
      password: "",
      mobile: "",
      userType: "",
      languageId: 0,
      name: "",
      lastName: "",
      dateOfBirth: "",
      doYouWearGlasses: true,
      whenIsTheLastTimeYouWentToTheEyeDoctor: "",
      patientNameOfYourUsualEyeDoctor: "",
      patientRppsNumberOfYourUsualEyeDoctor: 0,
      phoneNumber: 0,
      typeOfVision: "",
      patientHappy: "",
      patientSymptoms: "",
      patientDiagnosedWith: "",
      patientHealthConditions: "",
      additionalComments: "",
      doctorRppsNumber: "",
      doctorIdOfRegistration: "",
      doctorAddressOfTheHealthCenter: "",
      isExternal: false,
      input: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Merci d’entrer votre mot de passe.";
    }
    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Merci de confirmer votre mot de passe.";
    }
    if (typeof input["password"] !== "undefined") {
      if (input["password"].length < 8) {
        isValid = false;
        errors["password"] =
          "Le mot de passe doit contenir au minimum 8 caractères, 1 majuscule, 1 minuscule, 1 symbole (@ / ? …)";
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*!()_+{}\[\]:;<>,.?~\\-])/.test(
          input["password"]
        )
      ) {
        isValid = false;
        errors["password"] =
          "Le mot de passe doit contenir au moins une minuscule, une majuscule, un caractère spécial et un chiffre.";
      }
    }
    if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirm_password"] !== "undefined"
    ) {
      if (input["password"] !== input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Les mots de passe ne correspondent pas.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  Dash() {
    //console.log("hi")
    ReactDOM.render(<Dash />, document.getElementById("c11"));
  }

  uploadPic(event) {
    // alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", "");
      formData.append("ProfileImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/image/change";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.componentDidMount();
        this.getuserinfo();
        setTimeout(function () {}, 3000);
        setTimeout(function () {
          let ob1 = new Head();
          ob1.getuserinfo();
          console.log(ob1);
        }, 5000);
      });
    }
  }

  changePassword() {
    if (this.validate()) {
      console.log(this.state);

      let input = {};
      input["password"] = "";
      input["confirm_password"] = "";
      this.setState({ input: input });
    } else {
      return;
    }
    if (
      document.getElementById("newp").value !=
      document.getElementById("cnewp").value
    ) {
      swal({
        title: "OOPS!",
        text: "Please confirm password",
        icon: "warning",
      });
      return false;
    }

    let logourl = API_URL + "/profile/password/change";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        OldPassword: document.getElementById("oldp").value,
        NewPassword: document.getElementById("newp").value,
        ConfirmNewPassword: document.getElementById("cnewp").value,
      }),
    };

    fetch(logourl, requestOptions)
      .then((res) => res.text())
      .then((_text) => {
        if (_text == "success") {
          swal({
            title: "Done!",
            text: window.$language.DeletedSuccessfully,
            icon: "success",
          });
        } else {
          swal({
            title: "OOPS!",
            text: _text,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  }
  getuserinfo() {
    let logourl = API_URL + "/users/profile";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
        //     referrer: "http://localhost:3000", // or "" to send no Referer header,
        //     // or an url from the current origin
        //     referrerPolicy: "no-referrer-when-downgrade", // no-referrer, origin, same-origin...
        //     mode: "cors", // same-origin, no-cors
      },
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({ usrdet: json });
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Pleaseenteryouremailandpassword,
          icon: "warning",
        });

        console.log(e);
      });
  }

  uploadSign(event) {
    // alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", "");
      formData.append("SignatureImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/signature/change";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.componentDidMount();
        this.getuserinfo();
        setTimeout(function () {}, 3000);
      });
    }
  }
  uploadRPPS(event) {
    //alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", "");
      formData.append("SignatureImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/rpcscode/upload";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.componentDidMount();
        this.getuserinfo();
        setTimeout(function () {}, 3000);
      });
    }
  }
  uploadDocID(event) {
    // alert(event.target.files.length);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("UserEmail", "");
      formData.append("SignatureImage", event.target.files[0]);
      let logourl = API_URL + "/users/profile/doctorid/upload";
      const requestOptions = {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
        },
        body: formData,
      };
      fetch(logourl, requestOptions).then((json) => {
        this.componentDidMount();
        this.getuserinfo();
        setTimeout(function () {}, 3000);
      });
    }
  }

  componentDidMount() {
    this.getuserinfo();
  }
  submit() {
    console.log(this.state.usrdet);
    // let data = {
    //     fullname: document.getElementById("basic-icon-default-fullname").value=""
    //     email: document.getElementById("basic-icon-default-email").value,
    //     pass: document.getElementById("basic-icon-default-pass").value,
    //     RPPS: document.getElementById("basic-icon-default-post").value,
    //     docid: document.getElementById("basic-icon-default-salary").value
    //isExternal: extop
    // }

    // if(document.getElementById("extop").value=="true"){

    // }
    /* Validations */
    var ret = false;
    $("#inputform >  .form-group >  .form-control").each(function () {
      if ($(this).val() == "") {
        swal({
          title: "OOPS!",
          text:
            $(this).parent().find("label").html() +
            window.$language.cannotbeblank,
          icon: "warning",
        });
        ret = true;
        return false;
      }
    });

    if (ret) return false;
    /* Validations */

    ////////

    ////////

    // let arr = JSON.parse(localStorage.getItem("doc"))
    // arr.push(data)

    // localStorage.setItem("doc", JSON.stringify(arr))
    //////
    let logourl = API_URL + "/profile/edit";
    let msg = "Profile edited";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(this.state.usrdet),
    };

    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //this.setState({usrdet:json})
        if (json > 0) {
          swal({
            title: "Done!",
            text: msg,
            icon: "success",
          });
          //  document.getElementById("close").click()
          this.componentDidMount();
        }
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Pleaseenteryouremailandpassword,
          icon: "warning",
        });

        console.log(e);
      });
    //////
  }
  render() {
    return (
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-1">
            <section class="invoice-preview-wrapper">
              <div class="row invoice-preview">
                <section class="app-user-edit col-9 pt-0">
                  <div class="card px-2 pb-4">
                    <div class="card-body">
                      <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link d-flex align-items-center"
                            id="information-tab"
                            data-toggle="tab"
                            href="#information"
                            aria-controls="information"
                            role="tab"
                            aria-selected="false"
                          ></a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="account"
                          aria-labelledby="account-tab"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div class="media mb-2 pr-3">
                              <img
                                src={
                                  API_URL +
                                  "/" +
                                  this.state.usrdet.profileImagePath
                                }
                                alt="users avatar"
                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                height="90"
                                width="90"
                              />
                              <div class="media-body mt-50">
                                <h4>
                                  {this.state.usrdet.name +
                                    "  " +
                                    this.state.usrdet.lastName}
                                </h4>
                                <div class="col-12 d-flex mt-1 px-0">
                                  <label
                                    class="btn btn-primary mr-75 mb-0"
                                    for="change-picture"
                                  >
                                    <span class="d-none d-sm-block">
                                      {window.$language.Change}
                                    </span>
                                    <input
                                      class="form-control"
                                      type="file"
                                      id="change-picture"
                                      hidden
                                      accept="image/png, image/jpeg, image/jpg"
                                      onChange={(event) => {
                                        this.uploadPic(event);
                                      }}
                                    />
                                    <span class="d-block d-sm-none">
                                      <i class="fas fa-plus-square"></i>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {this.state.utype == "EYEDOCTOR" ? (
                              <div class="media mb-2 pl-5">
                                <img
                                  src={
                                    API_URL +
                                    "/" +
                                    this.state.usrdet.signatureImagePath
                                  }
                                  alt="users avatar"
                                  class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                  height="90"
                                  width="90"
                                />
                                <div class="media-body mt-50">
                                  <h4>
                                    <br />
                                  </h4>
                                  <div class="col-12 d-flex mt-1 px-0">
                                    <label
                                      class="btn btn-primary mr-75 mb-0"
                                      for="change-sign"
                                    >
                                      <span class="d-none d-sm-block">
                                        {window.$language.ChangeSignature}
                                      </span>
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="change-sign"
                                        hidden
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(event) => {
                                          this.uploadSign(event);
                                        }}
                                      />
                                      <span class="d-block d-sm-none">
                                        <i class="fas fa-plus-square"></i>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {this.state.utype == "EYEDOCTOR" ? (
                            <div class="row">
                              <div class="media mb-2 pl-3">
                                <img
                                  src={
                                    API_URL +
                                    "/" +
                                    this.state.usrdet.rpcsIdImagePath
                                  }
                                  alt="users avatar"
                                  class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                  height="90"
                                  width="90"
                                />
                                <div class="media-body mt-50">
                                  <h4>
                                    <br />
                                  </h4>
                                  <div class="col-12 d-flex mt-1 px-0">
                                    <label
                                      class="btn btn-primary mr-75 mb-0"
                                      for="change-sign1"
                                    >
                                      <span class="d-none d-sm-block">
                                        {window.$language.UploadRPPS}
                                      </span>
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="change-sign1"
                                        hidden
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(event) => {
                                          this.uploadRPPS(event);
                                        }}
                                      />
                                      <span class="d-block d-sm-none">
                                        <i class="fas fa-plus-square"></i>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="media mb-2 pl-3">
                                <img
                                  src={
                                    API_URL +
                                    "/" +
                                    this.state.usrdet.doctorIdImagePath
                                  }
                                  alt="users avatar"
                                  class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                                  height="90"
                                  width="90"
                                />
                                <div class="media-body mt-50">
                                  <h4>
                                    <br />
                                  </h4>
                                  <div class="col-12 d-flex mt-1 px-0">
                                    <label
                                      class="btn btn-primary mr-75 mb-0"
                                      for="change-sign2"
                                    >
                                      <span class="d-none d-sm-block">
                                        {window.$language.UploadDoctorID}
                                      </span>
                                      <input
                                        class="form-control"
                                        type="file"
                                        id="change-sign2"
                                        hidden
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(event) => {
                                          this.uploadDocID(event);
                                        }}
                                      />
                                      <span class="d-block d-sm-none">
                                        <i class="fas fa-plus-square"></i>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <form class="form-validate">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="username">
                                    {window.$language.FirstName}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.state.usrdet.name}
                                    class="form-control"
                                    onChange={(data) =>
                                      (this.state.usrdet.name =
                                        data.target.value)
                                    }
                                    name="username"
                                    id="username"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="name">
                                    {window.$language.LastName}
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={this.state.usrdet.lastName}
                                    onChange={(data) =>
                                      (this.state.usrdet.lastName =
                                        data.target.value)
                                    }
                                    class="form-control"
                                    name="name"
                                    id="name"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="email">
                                    {window.$language.Email}
                                  </label>
                                  <input
                                    type="email"
                                    readOnly
                                    defaultValue={this.state.usrdet.email}
                                    onChange={(data) =>
                                      (this.state.usrdet.email =
                                        data.target.value)
                                    }
                                    class="form-control"
                                    name="email"
                                    id="email"
                                  />
                                </div>
                              </div>

                              {this.state.utype == "PATIENT" ? (
                                <>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="status">
                                        {window.$language.Doyouwearglasses}?
                                      </label>
                                      <select
                                        defaultValue={
                                          this.state.usrdet.doYouWearGlasses
                                        }
                                        onChange={(data) =>
                                          (this.state.usrdet.name =
                                            data.target.value == "Yes"
                                              ? true
                                              : false)
                                        }
                                        class="form-control"
                                      >
                                        <option>{window.$language.Yes}</option>
                                        <option>{window.$language.No}</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div class="col-md-4 d-none">
                                    <div className="form-group align-baseline flex-row">
                                      <label
                                        className="form-label"
                                        for="login-email"
                                      >
                                        {" "}
                                        {window.$language.TypeofVision}
                                      </label>
                                      <select
                                        defaultValue={
                                          this.state.usrdet.typeOfVision
                                        }
                                        onChange={(data) =>
                                          (this.state.usrdet.typeOfVision =
                                            data.target.value)
                                        }
                                        class="form-control"
                                      >
                                        <option>
                                          {window.$language.FarVision}
                                        </option>
                                        <option>
                                          {window.$language.CloseVision}
                                        </option>
                                        <option>
                                          {window.$language.ProgressiveVision}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-md-4 d-none">
                                    <div className="form-group align-baseline flex-row">
                                      <label
                                        className="form-label"
                                        for="login-email"
                                      >
                                        {window.$language.Symptoms}
                                      </label>
                                      <select
                                        defaultValue={
                                          this.state.usrdet.patientSymptoms
                                        }
                                        onChange={(data) =>
                                          (this.state.usrdet.patientSymptoms =
                                            data.target.value)
                                        }
                                        class="form-control"
                                      >
                                        <option>
                                          {window.$language.FrequentFlashes}
                                        </option>
                                        <option>
                                          {window.$language.FrequentFloaters}
                                        </option>
                                        <option>
                                          {window.$language.SevereHeadhaches}
                                        </option>
                                        <option>
                                          {window.$language.DoubleVision}
                                        </option>
                                        <option>
                                          {window.$language.LossofVision}
                                        </option>
                                        <option>
                                          {window.$language.Dizziness}
                                        </option>
                                        <option>
                                          {window.$language.NoneoftheAbove}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {this.state.utype == "EYEDOCTOR" ? (
                                <>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="username">
                                        {window.$language.RPPS}
                                      </label>
                                      <input
                                        defaultValue={
                                          this.state.usrdet.doctorRppsNumber
                                        }
                                        onChange={(data) =>
                                          (this.state.usrdet.doctorRppsNumber =
                                            data.target.value)
                                        }
                                        type="text"
                                        class="form-control"
                                        name="username"
                                        id="username"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label for="name">
                                        {window.$language.DoctorId}
                                      </label>
                                      <input
                                        defaultValue={
                                          this.state.usrdet
                                            .doctorIdOfRegistration
                                        }
                                        onChange={(data) =>
                                          (this.state.usrdet.doctorIdOfRegistration =
                                            data.target.value)
                                        }
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        id="name"
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              <div class="col-12">
                                <div class="table-responsive border rounded mt-1"></div>
                              </div>
                            </div>
                          </form>
                          <form class="form-validate">
                            <div class="row mt-1">
                              <div class="col-12">
                                <h4 class="mb-1">
                                  <i
                                    data-feather="user"
                                    class="font-medium-4 mr-25"
                                  ></i>
                                  <span class="align-middle">
                                    {window.$language.PersonalInformation}
                                  </span>
                                </h4>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.UserType}
                                  </label>
                                  <select
                                    id="languages"
                                    class="form-control"
                                    onChange={(data) =>
                                      (this.state.usrdet.userType =
                                        data.target.value)
                                    }
                                  >
                                    <option>
                                      {this.state.usrdet.userType}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="fp-default">
                                    {window.$language.DateofBirth}
                                  </label>
                                  <input
                                    defaultValue={this.state.usrdet.dateOfBirth}
                                    onChange={(data) =>
                                      (this.state.usrdet.dateOfBirth =
                                        data.target.value)
                                    }
                                    id="fp-default"
                                    type="text"
                                    class="form-control birthdate-picker"
                                    name="dob"
                                    placeholder="01 Jan 2000"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="mobile">
                                    {window.$language.Phone}
                                  </label>
                                  <input
                                    defaultValue={this.state.usrdet.mobile}
                                    onChange={(data) =>
                                      (this.state.usrdet.mobile =
                                        data.target.value)
                                    }
                                    id="mobile"
                                    type="text"
                                    class="form-control"
                                    name="phone"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="languages">
                                    {window.$language.Languages}
                                  </label>
                                  <select id="languages" class="form-control">
                                    <option value="1">English</option>

                                    <option value="2">French</option>

                                    <option value="3">German</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                {/* <div class="form-group">
                                                                    <label class="d-block mb-1">{window.$language.Gender}</label>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" id="male" name="gender" class="custom-control-input" />
                                                                        <label class="custom-control-label" for="male">{window.$language.Male}</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" id="female" name="gender" class="custom-control-input" checked />
                                                                        <label class="custom-control-label" for="female">{window.$language.Female}</label>
                                                                    </div>
                                                                </div> */}
                              </div>

                              <div class="col-12">
                                <h4 class="mb-1 mt-2">
                                  <i
                                    data-feather="map-pin"
                                    class="font-medium-4 mr-25"
                                  ></i>
                                  <span class="align-middle">
                                    {window.$language.Address}
                                  </span>
                                </h4>
                              </div>
                              <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                  <label for="address-1">
                                    {window.$language.Address}
                                  </label>
                                  <input
                                    id="address-1"
                                    defaultValue={
                                      this.state.usrdet
                                        .doctorAddressOfTheHealthCenter
                                    }
                                    onChange={(data) =>
                                      (this.state.usrdet.doctorAddressOfTheHealthCenter =
                                        data.target.value)
                                    }
                                    type="text"
                                    class="form-control"
                                    name="address"
                                  />
                                </div>
                              </div>

                              {/*    <div class="col-lg-4 col-md-6">
                                                                 <div class="form-group">
                                                                    <label for="postcode">{window.$language.Postcode}</label>
                                                                    <input id="postcode" type="text" class="form-control" placeholder="" name="zip" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="form-group">
                                                                    <label for="city">{window.$language.City}</label>
                                                                    <input id="city" type="text" class="form-control"  name="city" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="form-group">
                                                                    <label for="state">{window.$language.State}</label>
                                                                    <input id="state" type="text" class="form-control" name="state" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-6">
                                                                <div class="form-group">
                                                                    <label for="country">{window.$language.Country}</label>
                                                                    <input id="country" type="text" class="form-control" name="country" placeholder="" />
                                                                </div>
                                                            </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                  <div class="card mb-0">
                    <div class="card-body">
                      <a
                        type="submit"
                        class="btn btn-outline-primary btn-block mb-75"
                        data-toggle="modal"
                        data-target="#modals-slide-in"
                      >
                        <span>{window.$language.Changepassword}</span>
                      </a>
                      {/* {this.state.usrdet.isExternal == false ? ( */}
                      <a
                        onClick={() => this.submit()}
                        class="btn btn-primary btn-block mb-75"
                        target="_blank"
                      >
                        <span>{window.$language.SaveChanges}</span>
                      </a>
                      {/* ) : null} */}

                      <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={this.Dash}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                      </a>
                      {this.state.utype == "PATIENT" ? (
                      <a
                        class="btn btn-outline-danger btn-block mb-75"
                        target="_blank"
                       href="https://share-eu1.hsforms.com/1tAyEmwIpRs6Q0U7VNM4ZUQf13gu"
                      >
                      
                        <span>Suppression de mon compte</span>
                      </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal modal-slide-in fade" id="modals-slide-in">
                <div class="modal-dialog sidebar-sm">
                  <form class="add-new-record modal-content pt-0">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ×
                    </button>
                    <div class="modal-header mb-1">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {window.$language.Changepassword}
                      </h5>
                    </div>
                    <div class="modal-body flex-grow-1">
                      <div class="form-group">
                        <label
                          class="form-label"
                          for="basic-icon-default-email"
                        >
                          {window.$language.OldPassword}
                        </label>
                        <input
                          type="password"
                          id="oldp"
                          class="form-control dt-email"
                          placeholder=""
                        />
                      </div>

                      <div class="form-group">
                        <label
                          class="form-label"
                          for="basic-icon-default-email"
                        >
                          {window.$language.NewPassword}
                        </label>
                        <input
                          type="password"
                          id="newp"
                          name="password"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Please add at least 8 charachter minLowercase:1 minUppercase:1, minNumbers:1, minSymbols:1."
                          value={this.state.input.password}
                          onChange={this.handleChange}
                          class="form-control dt-email"
                          placeholder=""
                        />
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      </div>

                      <div class="form-group">
                        <label
                          class="form-label"
                          for="basic-icon-default-email"
                        >
                          {window.$language.RetypenewPassword}
                        </label>
                        <input
                          type="password"
                          id="cnewp"
                          name="confirm_password"
                          value={this.state.input.confirm_password}
                          onChange={this.handleChange}
                          class="form-control dt-email"
                          placeholder=""
                        />
                        <div className="text-danger">
                          {this.state.errors.confirm_password}
                        </div>
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary data-submit mr-1 btn-page-block-overlay waves-effect "
                        onClick={() => this.changePassword()}
                      >
                        {window.$language.Submit}
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary"
                        id="close"
                        data-dismiss="modal"
                      >
                        {window.$language.Cancel}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default Userprofile;
