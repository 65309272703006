import React, { Component } from "react";
import ReactDOM from "react-dom";
import { API_URL } from "../../config";
import swal from "sweetalert";
import $ from "jquery";
import "./login.css";
import { getUrlVars } from "../../common";

class SetPwd extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      input: {},
      errors: {},
      showpwd: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }
  togglePwd() {
    this.setState({ showpwd: !this.state.showpwd });
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Merci d’entrer votre mot de passe.";
    }
    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Merci de confirmer votre mot de passe.";
    }
    if (typeof input["password"] !== "undefined") {
      if (input["password"].length < 8) {
        isValid = false;
        errors["password"] =
          "Le mot de passe doit contenir au minimum 8 caractères, 1 majuscule, 1 minuscule, 1 symbole (@ / ? …)";
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*!()_+{}\[\]:;<>,.?~\\-])/.test(
          input["password"]
        )
      ) {
        isValid = false;
        errors["password"] =
          "Le mot de passe doit contenir au moins une minuscule, une majuscule, un caractère spécial et un chiffre.";
      }
    }
    if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirm_password"] !== "undefined"
    ) {
      if (input["password"] !== input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Les mots de passe ne correspondent pas.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  Passwordurlexpiration() {
    let data1 = {
      encryptedPassword: getUrlVars()[0],
    };
    /////////////////
    let logourl = API_URL + "/profile/password/urlexpiration";

    /////////////////////
    // let logourl = API_URL + "/Products/add";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.text())
      .then((json) => {
        if (json == "1") {
          $(".passwordlink").find("h4").html("");
          $(".passwordlink").find("form").html("");
          $(".backscreen").html("");
          $(".messageappend").append(
            "<h4 class='card-title mb-1'>Le lien que vous avez suivi a expiré.</h4>"
          );
          $(".backscreen").append(
            "<a href='/forgotpassword'> <i data-feather='chevron-left'></i> Retour à l'écran d'oubli du mot de passe ? </a>"
          );
        } else if (json == "2") {
          $(".passwordlink").find("h4").html("");
          $(".passwordlink").find("form").html("");
          $(".messageappend").append(
            "<h4 class='card-title mb-1'>Le code de cryptage n'existe pas. </h4>"
          );
        }
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: json,
          icon: "warning",
        });
        console.log(e);
      });
  }
  componentDidMount() {
    if (localStorage.getItem("meow") != "ok") {
      $("#overlay").show();
    }
    this.Passwordurlexpiration();
  }
  meow() {
    localStorage.setItem("meow", "ok");
    $("#overlay").hide();
  }
  submit() {
    if (this.validate()) {
      console.log(this.state);
      let input = {};
      input["password"] = "";
      input["confirm_password"] = "";
      this.setState({ input: input });
    } else {
      return;
    }

    const content = document.createElement("div");
    const input = document.createElement("img");
    input.src = "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
    input.width = "250";
    content.appendChild(input);

    swal({
      text: "Please wait",

      allowOutsideClick: false,
      buttons: false,
      content,
    });
    const params = new URLSearchParams(window.location.search);
    let email1 = getUrlVars()[0];
    let data1 = {
      emailId: email1==undefined || email1=="" ? params.get("e") : email1,
      newPassword: document.getElementById("cnewp").value,
    };
    let logourl = API_URL + "/profile/password/reset";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.text())
      .then((json) => {
        if (json == "success") {
          swal({
            title: "Done!",
            text: window.$language.passwordsetsuccessfully,
            icon: "success",
          });
          this.componentDidMount();
          localStorage.clear();
          window.location.href = "/login";
        } else {
          swal({
            title: "OOPS!",
            text: json,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div>
        <div className="app-content content  ">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper">
            <div className="content-body">
              <div className="auth-wrapper auth-v2">
                <div className="auth-inner row m-0">
                  <a className="brand-logo" href="javascript:void(0);"></a>

                  <div className="d-none d-lg-flex col-lg-8 align-items-center p-0 border-right bg-white">
                    <div className="w-100 d-lg-flex align-items-center justify-content-center px-0">
                      <img
                        src="../../../app-assets/images/pages/login-v2.png"
                        alt="Login V2"
                      />
                    </div>
                  </div>

                  <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5 bg-light">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto passwordlink">
                      <img
                        className="img-fluid3"
                        src="../../../app-assets/images/logo/logo.png"
                        alt="Login V2"
                      />

                      <p className="card-text mb-2"></p>

                      <h4 class="card-title mb-1 messageappend">
                        {window.$language.SetYourNewPassword} 🔒
                      </h4>
                      <h4 class="card-title mb-1">
                        {window.$language.Welcome}{" "}
                      </h4>

                      <form class="auth-forgot-password-form mt-2">
                        <div class="form-group">
                          <label
                            class="form-label"
                            for="basic-icon-default-email"
                          >
                            {window.$language.NewPassword}
                          </label>
                          <div className="input-group input-group-merge form-password-toggle">
                            <input
                              type={this.state.showpwd ? "text" : "password"}
                              id="newp"
                              name="password"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Le mot de passe doit contenir au minimum 8 caractères, 1 majuscule, 1 minuscule, 1 symbole (@ / ? …)"
                              value={this.state.input.password}
                              onChange={this.handleChange}
                              class="form-control dt-email"
                              placeholder=""
                              required
                            />
                            <div className="input-group-append">
                              <span className="input-group-text view-password cursor-pointer">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon-tabler icon-tabler-eye"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  onClick={() => this.togglePwd()}
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <circle cx="12" cy="12" r="2"></circle>
                                  <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div className="text-danger">
                            {this.state.errors.password}
                          </div>
                        </div>

                        <div class="form-group">
                          <label
                            class="form-label"
                            for="basic-icon-default-email"
                          >
                            {window.$language.RetypenewPassword}
                          </label>
                          <div className="input-group input-group-merge form-password-toggle">
                            <input
                              type={this.state.showpwd ? "text" : "password"}
                              id="cnewp"
                              name="confirm_password"
                              value={this.state.input.confirm_password}
                              onChange={this.handleChange}
                              class="form-control dt-email"
                              placeholder=""
                              required
                            />
                            <div className="input-group-append">
                              <span className="input-group-text view-password cursor-pointer">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon-tabler icon-tabler-eye"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  onClick={() => this.togglePwd()}
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <circle cx="12" cy="12" r="2"></circle>
                                  <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div className="text-danger">
                            {this.state.errors.confirm_password}
                          </div>
                        </div>

                        <button
                          type="button"
                          onClick={() => this.submit()}
                          class="btn btn-primary btn-block"
                          tabindex="2"
                        >
                          {window.$language.SetPassword}
                        </button>
                      </form>

                      <p class="text-center mt-2 backscreen">
                        <a href="/login">
                          {" "}
                          <i data-feather="chevron-left"></i>{" "}
                          {window.$language.Backtologin}?{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="overlay" class="cookie-consent">
          {" "}
          <span>
            Nous utilisons des cookies pour vous garantir la meilleure
            navigation possible et pour réaliser des statistiques de visite afin
            d'améliorer votre expérience. A noter que nous n'utilisons de
            cookies ni pour du ciblage marketing ni pour proposer des offres ou
            des services personnalisés et que par ailleurs, aucune donnée n'est
            commuiquée à des tiers, même anonymisée.&nbsp;
            <a
              href="../../../home/assets/PrivacyPolicy.pdf"
              class="text-decoration-none instagram"
            >
              <u>
                Pour plus d'informations, consultez notre politique de
                confidentialité.
              </u>
            </a>{" "}
          </span>
          <div class="mt-2 d-flex align-items-center justify-content-center g-2">
            {" "}
            <button class="allow-button mr-1" onClick={() => this.meow()}>
              Accepter et Fermer
            </button>{" "}
          </div>
        </div>
      </div>
    );
  }
}
export default SetPwd;
