import React, { Component } from 'react';
import ReactDOM from 'react-dom';


class Eyetest extends Component{

    constructor(){
        super();
        this.state={

            emailID:"",
           pwd:"",
           loginIP:"",
           loginBrowser:"",
                      deviceType:"",
           userLocation:"",
           logIsp:"",
           ii: 0,
           actions: [],
           pLogo: ""
        }
  
    }
    componentDidMount(){
        this.setState({eyearr: JSON.parse(localStorage.getItem("eye"))})
        console.log( localStorage.getItem("eye"))
      }
   submit(){
       let data={
        rwhichEye:"right",	
        rsph:document.getElementById("rsh").value,	
        rcyl:document.getElementById("rcy").value,	
        raxe:document.getElementById("rax").value,	
        radd:document.getElementById("rad").value,	
        rpd:document.getElementById("rpd").value,	
        rphigh:document.getElementById("rph").value,
        lwhichEye:"left",	
        lsph:document.getElementById("lsh").value,	
        lcyl:document.getElementById("lcy").value,	
        laxe:document.getElementById("lax").value,	
        ladd:document.getElementById("lad").value,	
        lpd:document.getElementById("lpd").value,	
        lphigh:document.getElementById("lph").value
       }
      // alert(data)
       let arr=JSON.parse(localStorage.getItem("eye"))
       arr.push(data)
 
       localStorage.setItem("eye",JSON.stringify(arr))
       document.getElementById("close").click()
       window.location.href = "/eyeresult"
       this.componentDidMount()
   }

    render(){
        
       return (

        <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                </div>
            <div class="content-body px-2 ">
                {/*Advanced Searchadvanced-search-datatable*/}
                <section id="">
                    <div class="row">
                        <div class="col ">
                            <div class="card px-2 pb-4">
                                <div class="card-header border-bottom d-flex">
                                    <h4 class="card-title">Add Eye Test</h4>
                                    <div class="flex-fill px-2 d-flex align-items-start" >
                                    <label class="btn btn-primary mr-75 mb-0" for="change-picture">
                                                    <span class="d-none d-sm-block">Upload CSV</span>
                                                    <input class="form-control" type="file" id="change-picture" hidden accept="image/png, image/jpeg, image/jpg" />
                                                    <span class="d-block d-sm-none">
                                                        <i class="mr-0" data-feather="edit"></i>
                                                    </span>
                                                </label>
                                                
                                                <button type="button" class="btn btn-primary" onClick={()=>this.submit()}> Submit</button>
                                                <button class="btn btn-primary d-none d-sm-block mx-1" id="close">Reset</button>
                                                </div>
                                              </div>
                                              
                                {/*Search Form */}
                                <div class="card-body mt-2">
                                    <form class="dt_adv_search" method="POST">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-row mb-1">
                                                    <div class="col-lg-4">
                                                        <label>Name:</label>
                                                        <input type="text" class="form-control dt-input dt-full-name" data-column="1" placeholder="Alaric Beslier" data-column-index="0" />
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Email:</label>
                                                        <input type="text" class="form-control dt-input" data-column="2" placeholder="demo@example.com" data-column-index="1" />
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Doctor Name:</label>
                                                        <input type="text" class="form-control dt-input" data-column="3" placeholder="" data-column-index="2" />
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-lg-4">
                                                        <label>City:</label>
                                                        <input type="text" class="form-control dt-input" data-column="4" placeholder="" data-column-index="3" />
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Date:</label>
                                                        <div class="form-group mb-0">
                                                            <input type="text" class="form-control dt-date flatpickr-range dt-input" data-column="5" placeholder="Entry Date" data-column-index="4" name="dt_date" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <label>Phone</label>
                                                        <input type="text" class="form-control dt-input" data-column="6" placeholder="" data-column-index="5" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr class="my-0" />
                                <div class="card-datatable">
                                    <table class="table w-100">
                                        <thead>
                                            <tr>
                                                <th><input type="checkbox"/></th>
                                               
                                                <th>SPH</th>
                                                <th>CYL</th>
                                                <th>AXE</th>
                                                <th>ADD</th>
                                                <th>PD</th>
                                                <th>PHIGH</th>
                                            </tr>
                                            <tr>
                                              
                                                <td>Right</td>
                                                <td ><input id='rsh' class="form-control form-control-sm"/></td>
                                                <td ><input id='rcy' class="form-control form-control-sm"/></td>
                                                <td ><input id='rax' class="form-control form-control-sm"/></td>
                                                <td><input id='rad' class="form-control form-control-sm"/></td>
                                                <td><input id='rpd' class="form-control form-control-sm"/></td>
                                                <td><input id='rph' class="form-control form-control-sm"/></td>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th><input type="checkbox"/></th>
                                                
                                                <th>SPH</th>
                                                <th>CYL</th>
                                                <th>AXE</th>
                                                <th>ADD</th>
                                                <th>PD</th>
                                                <th>PHIGH</th>
                                            </tr>
                                            <tr>
                                                
                                                <td>Left</td>
                                                <td ><input id='lsh' class="form-control form-control-sm" /></td>
                                                <td ><input id='lcy'class="form-control form-control-sm" /></td>
                                                <td ><input id='lax' class="form-control form-control-sm"/></td>
                                                <td><input id='lad'class="form-control form-control-sm"/></td>
                                                <td><input id='lpd'class="form-control form-control-sm"/></td>
                                                <td><input id='lph'class="form-control form-control-sm"/></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Advanced Search*/}

            </div>
        </div>
    </div>



      );


    }
}
export default Eyetest